import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import GenericModalView from "./Modal.style"
import { selectModal, hideModal } from "../../redux/modal.duck"
import { selectSelectedCountryName } from "../../redux/countriesData.duck"
import useMobile from "../../hooks/useMobie"

const useModal = () => {
  const { show: open, title, description, data, type } = useSelector(selectModal)
  const selectedCountryName = useSelector(selectSelectedCountryName)
  const { isMobile } = useMobile()
  const [fullscreen, setFullscreen] = useState(isMobile)

  useEffect(() => {
    setFullscreen(isMobile)
  }, [isMobile])

  const dispatch = useDispatch()
  const actions = {
    closeModal: () => {
      dispatch(hideModal())
    },
  }

  return { open, title, description, data, type, selectedCountryName, fullscreen, actions }
}

const GenericModalProvider = ({ children }) => {
  const modalProps = useModal()
  return <GenericModalView {...modalProps}>{children}</GenericModalView>
}

export default GenericModalProvider
