import { IconButton, Snackbar, Typography, makeStyles, SnackbarContent } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import React, { memo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { acceptCookies, selectCookiesAccepted, selectIsMobile } from "../redux/userInterface.duck"
import Icon from "./Icon"
import CookieIcon from "../assets/cookie-icon.svg"

const useStyles = makeStyles(theme => ({
  SnackbarRoot: {
    width: "100vw",
    borderRadius: "none",
  },
  OriginBottomCenter: {
    left: 0,
    right: 0,
    bottom: 0,
    transform: "none",
  },
  SnackbarContentRoot: {
    background: "#404040",
    color: "#FFFFFF",
    borderRadius: 0,
    flexGrow: 1,
  },
  SnackbarContentMessage: {
    width: "100%",
    padding: "5px 20px",
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },
  cookieIcon: {
    fill: "#FFFFFF",
    marginRight: 20,
    width: 20,
    height: 20,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  bodyText: {
    fontSize: 14,
    marginRight: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      marginRight: 0,
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
}))

const CookieConsent = () => {
  const classes = useStyles()
  const cookiesAccepted = useSelector(selectCookiesAccepted)
  const dispatch = useDispatch()
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    dispatch(acceptCookies())
  }

  const isMobile = useSelector(selectIsMobile)

  return (
    <Snackbar
      classes={{
        root: classes.SnackbarRoot,
        anchorOriginBottomCenter: classes.OriginBottomCenter,
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={!cookiesAccepted && !isMobile}
      onClose={handleClose}
    >
      <SnackbarContent
        classes={{ root: classes.SnackbarContentRoot, message: classes.SnackbarContentMessage }}
        message={
          <div className={classes.header}>
            <Icon src={CookieIcon} className={classes.cookieIcon} size={20} />
            <Typography className={classes.bodyText}>
              RavenPack uses our own and third-party cookies to improve your navigation experience,
              analyze users experiences and offer you content of interest. By using our site, you
              consent to the use of cookies unless you have disabled them. Read more about our{" "}
              <a href="https://www.ravenpack.com/cookie-policy/" className={classes.link}>
                Cookies Policy
              </a>
              .
            </Typography>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              style={{ marginLeft: "auto" }}
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
        }
      />
    </Snackbar>
  )
}

export default memo(CookieConsent)
