import { useState } from "react"
import apiClient from "../APIClient"
import useInterval from "./useInterval"
import { byTimestamp } from "common/utils/sorts"

const useStories = url => {
  const [loading, setLoading] = useState(true)
  const [stories, setStories] = useState([])

  const getStories = async () => {
    const newsStories = await apiClient.get(url)
    setLoading(false)
    setStories(newsStories.results.sort(byTimestamp()))
  }

  useInterval(() => getStories(), 5 * 60 * 1000, true)

  return [stories, loading]
}

export default useStories
