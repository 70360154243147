import { createReducer, createAsyncThunk, createAction } from "@reduxjs/toolkit"
import ApiClient from "../APIClient"
import loadingStates from "common/state/loadingStates"

const initialState = {
  global: {
    hourlyData: {
      data: {
        media: undefined,
        infodemic: undefined,
        sent: undefined,
        panic: undefined,
        hype: undefined,
        fake: undefined,
      },
      error: null,
      lastUpdated: undefined,
      loading: loadingStates.INIT,
    },
    dailyData: {
      panic: {
        data: undefined,
        lastUpdated: undefined,
        error: null,
        loading: loadingStates.INIT,
      },
      media: {
        data: undefined,
        lastUpdated: undefined,
        error: null,
        loading: loadingStates.INIT,
      },
      sent: {
        data: undefined,
        lastUpdated: undefined,
        error: null,
        loading: loadingStates.INIT,
      },
      infodemic: {
        data: undefined,
        lastUpdated: undefined,
        error: null,
        loading: loadingStates.INIT,
      },
      hype: {
        data: undefined,
        lastUpdated: undefined,
        error: null,
        loading: loadingStates.INIT,
      },
      fake: {
        data: undefined,
        lastUpdated: undefined,
        error: null,
        loading: loadingStates.INIT,
      },
    },
  },
  country: {
    hourlyData: {
      data: {
        media: undefined,
        infodemic: undefined,
        sent: undefined,
        panic: undefined,
        hype: undefined,
        fake: undefined,
      },
      error: null,
      lastUpdated: undefined,
      loading: loadingStates.INIT,
    },
    dailyData: {
      panic: {
        data: undefined,
        lastUpdated: undefined,
        error: null,
        loading: loadingStates.INIT,
      },
      media: {
        data: undefined,
        lastUpdated: undefined,
        error: null,
        loading: loadingStates.INIT,
      },
      sent: {
        data: undefined,
        lastUpdated: undefined,
        error: null,
        loading: loadingStates.INIT,
      },
      infodemic: {
        data: undefined,
        lastUpdated: undefined,
        error: null,
        loading: loadingStates.INIT,
      },
      hype: {
        data: undefined,
        lastUpdated: undefined,
        error: null,
        loading: loadingStates.INIT,
      },
      fake: {
        data: undefined,
        lastUpdated: undefined,
        error: null,
        loading: loadingStates.INIT,
      },
    },
  },
}

export const getHourlyData = createAsyncThunk("getHourlyData", async () =>
  ApiClient.get(`/hourly.json`),
)

export const getDailyData = createAsyncThunk("getDailyData", async type =>
  ApiClient.get(`/${type}.json`),
)

export const getHourlyCountryData = createAsyncThunk("GET_HOURLY_COUNTRY_DATA", async arg =>
  ApiClient.get(`/country/${arg}/hourly.json`),
)

export const getDailyCountryData = createAsyncThunk(
  "GET_DAILY_COUNTRY_DATA",
  async ({ country, type }) => ApiClient.get(`/country/${country}/${type}.json`),
)

export const clearCountryIndexes = createAction("CLEAR_COUNTRY_INDEXES")

const indexNames = ["media", "infodemic", "sent", "panic", "hype", "fake"]

const reducer = createReducer(initialState, {
  [getHourlyData.pending]: state => {
    state.global.hourlyData.loading =
      state.global.hourlyData.loading === loadingStates.INIT
        ? loadingStates.INIT
        : loadingStates.PENDING
  },
  [getHourlyData.fulfilled]: (state, action) => {
    indexNames.forEach(i => {
      state.global.hourlyData.data[i] = action.payload.results.map(d => ({
        ts: d.ts,
        value: parseFloat(d[i]),
      }))
      state.global.hourlyData.lastUpdated = action.payload.ts
      state.global.hourlyData.loading = loadingStates.IDLE
    })
  },
  [getHourlyData.rejected]: (state, action) => {
    state.country.hourlyData.loading = loadingStates.IDLE
    state.country.hourlyData.error = action.error
  },
  [getHourlyCountryData.pending]: state => {
    state.country.hourlyData.loading =
      state.country.hourlyData.loading === loadingStates.INIT
        ? loadingStates.INIT
        : loadingStates.PENDING
  },
  [getHourlyCountryData.fulfilled]: (state, action) => {
    indexNames.forEach(i => {
      state.country.hourlyData.data[i] = action.payload.results.map(d => ({
        ts: d.ts,
        value: parseFloat(d[i]),
      }))
      state.country.hourlyData.lastUpdated = action.payload.ts
      state.country.hourlyData.loading = loadingStates.IDLE
    })
  },
  [getHourlyCountryData.rejected]: (state, action) => {
    state.country.hourlyData.loading = loadingStates.IDLE
    state.country.hourlyData.error = action.error
  },
  [getDailyData.pending]: (state, action) => {
    state.global.dailyData[action.meta.arg].loading =
      state.global.dailyData[action.meta.arg].loading === loadingStates.INIT
        ? loadingStates.INIT
        : loadingStates.PENDING
  },
  [getDailyData.fulfilled]: (state, action) => {
    const { arg: type } = action.meta
    state.global.dailyData[type].data = action.payload.results
      .map(v => ({ ...v, value: parseFloat(v[type]) }))
      .reverse()
    state.global.dailyData[type].lastUpdated = action.payload.ts
    state.global.dailyData[type].loading = loadingStates.IDLE
  },
  [getDailyData.rejected]: (state, action) => {
    const { arg: type } = action.meta
    state.global.dailyData[type].loading = loadingStates.IDLE
    state.global.dailyData[type].error = action.error
  },
  [getDailyCountryData.pending]: (state, action) => {
    state.country.dailyData[action.meta.arg.type].loading =
      state.country.dailyData[action.meta.arg.type].loading === loadingStates.INIT
        ? loadingStates.INIT
        : loadingStates.PENDING
  },
  [getDailyCountryData.fulfilled]: (state, action) => {
    const { type } = action.meta.arg
    state.country.dailyData[type].data = action.payload.results
      .map(v => ({ ...v, value: parseFloat(v[type]) }))
      .reverse()
    state.country.dailyData[type].lastUpdated = action.payload.ts
    state.country.dailyData[type].loading = loadingStates.IDLE
  },
  [getDailyCountryData.rejected]: (state, action) => {
    const { type } = action.meta.arg
    state.country.dailyData[type].loading = loadingStates.IDLE
    state.country.dailyData[type].error = action.error
  },
  [clearCountryIndexes]: state => {
    state.country = initialState.country
  },
})

const selectSelectedCountry = state => state.ui.selectedCountry
export const selectIndexData = state => state.indexes

export const selectLastUpdated = state => state.indexes.global.hourlyData.lastUpdated
export const selectIsLoading = state => {
  const selectedCountry = selectSelectedCountry(state)
  return selectedCountry === undefined
    ? state.indexes.global.hourlyData.loading === loadingStates.INIT
    : state.indexes.country.hourlyData.loading === loadingStates.INIT
}
export const selectHourlyError = state => {
  return state.indexes.global.hourlyData.error
}

const selectHourlyData = type => state => {
  const selectedCountry = selectSelectedCountry(state)
  const { data } = state.indexes[selectedCountry === undefined ? "global" : "country"].hourlyData
  return data[type]
}
export const selectDocsData = selectHourlyData("media")
export const selectInfodemicData = selectHourlyData("infodemic")
export const selectSentData = selectHourlyData("sent")
export const selectPanicData = selectHourlyData("panic")
export const selectHypeData = selectHourlyData("hype")
export const selectFakeData = selectHourlyData("fake")

const createAllDataSelector = key => state => {
  const i = selectSelectedCountry(state) === undefined ? "global" : "country"
  const hourlyData = state.indexes[i].hourlyData.data[key]
  const latestValue = hourlyData[hourlyData.length - 1]
  const dailyData = state.indexes[i].dailyData[key].data
  return [
    ...dailyData.slice(0, dailyData.length - 1),
    { ...dailyData[dailyData.length - 1], value: latestValue.value },
  ]
}

export const selectAllPanicData = createAllDataSelector("panic")
export const selectAllMediaData = createAllDataSelector("media")
export const selectAllInfodemicData = createAllDataSelector("infodemic")
export const selectAllSentData = createAllDataSelector("sent")
export const selectAllHypeData = createAllDataSelector("hype")
export const selectAllFakeData = createAllDataSelector("fake")

const selectDailyLastUpdate = type => state => {
  const i = selectSelectedCountry(state) === undefined ? "global" : "country"
  return state.indexes[i].dailyData[type].lastUpdated
}
export const selectAllPanicDataLastUpdate = selectDailyLastUpdate("panic")
export const selectAllMediaDataLastUpdate = selectDailyLastUpdate("media")
export const selectAllInfodemicDataLastUpdate = selectDailyLastUpdate("infodemic")
export const selectAllSentDataLastUpdate = selectDailyLastUpdate("sent")
export const selectAllHypeDataLastUpdate = selectDailyLastUpdate("hype")
export const selectAllFakeDataLastUpdate = selectDailyLastUpdate("fake")

const selectDailyIsLoading = type => state => {
  const i = selectSelectedCountry(state) === undefined ? "global" : "country"
  return state.indexes[i].dailyData[type].loading === loadingStates.INIT
}
export const selectIsLoadingPanic = selectDailyIsLoading("panic")
export const selectIsLoadingMedia = selectDailyIsLoading("media")
export const selectIsLoadingInfodemic = selectDailyIsLoading("infodemic")
export const selectIsLoadingSent = selectDailyIsLoading("sent")
export const selectIsLoadingHype = selectDailyIsLoading("hype")
export const selectIsLoadingFake = selectDailyIsLoading("fake")

export default reducer
