import React from "react"
import { Typography } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment-timezone"
import LineChart from "./LC"
import numericDisplay from "../../../utils/displayPercentage"
import IndexSelector from "./IndexSelector"
import IndexChip from "./IndexChip"
import ScaleSelector from "./ScaleSelector"
import {
  selectIsLoadingCountryData,
  selectSelectedCountryName,
} from "../../../redux/countriesData.duck"
import {
  clearComparisonData,
  fetchComparisonData,
  selectComparisonIsLoading,
  selectComparisonLastUpdated,
  selectSecondaryData,
} from "../../../redux/comparison.duck"
import {
  selectComparisonCountry,
  selectComparisonCountryName,
  selectIsMobile,
} from "../../../redux/userInterface.duck"
import HorizonSelector from "common/components/HorizonSelector"
import {
  selectChartChange,
  selectChartData,
  selectChartLastUpdated,
  selectChartLoading,
  selectLatestChartValue,
  setSecondaryIndex,
  updateData,
} from "../../../redux/modal.duck"
import { Skeleton } from "@material-ui/lab"
import { TopicNewsFeed, DefaultNewsFeed } from "../ModalNewsFeed"
import useQueryParams from "common/hooks/useQueryParams"
import { makeStyles } from "@material-ui/core/styles"

const getRecentUpdate = (primaryIndex, secondaryIndex) => {
  const maxTimestamp = secondaryIndex
    ? moment.max([moment.utc(primaryIndex), moment.utc(secondaryIndex)])
    : moment.utc(primaryIndex)
  const userTimezone = moment.tz.guess()
  return maxTimestamp.tz(userTimezone).format("MMM Do, HH:mm:ss z")
}

const indexes = [
  { title: "Indexes", type: "title" },
  { title: "Panic Index", value: "panic" },
  { title: "Media Hype Index", value: "hype" },
  { title: "Fake News Index", value: "fake" },
  { title: "Worldwide Sentiment Index", value: "sent" },
  { title: "Infodemic Index", value: "infodemic" },
  { title: "Media Coverage Index", value: "media" },
  { title: "Cases", type: "title" },
  { title: "Total Cases", value: "total_cases" },
  { title: "Total Deaths", value: "total_deaths" },
  { title: "Total Recovered", value: "total_recovered" },
]

const IndexNames = {
  panic: "Panic Index",
  hype: "Media Hype Index",
  fake: "Fake News Index",
  sent: "Sentiment Index",
  infodemic: "Infodemic Index",
  media: "Media Coverage Index",
  total_cases: "Total Cases",
  total_active: "Total Active Cases",
  total_deaths: "Total Deaths",
  total_recovered: "Total Recovered",
}

const indexGroups = {
  panic: "low",
  fake: "low",
  hype: "high",
  infodemic: "high",
  media: "high",
  sent: "sent",
  total_cases: "cases",
  total_deaths: "cases",
  total_recivered: "cases",
  total_active: "cases",
}

const useClasses = makeStyles(theme => ({
  lastUpdateContainer: {
    marginLeft: "auto",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      margin: "0 20px",
    },
  },
  lastUpdate: {
    color: "#C0C0C0",
    fontSize: 14,
    alignSelf: "flex-end",
  },
  skeleton: { marginTop: "auto", marginLeft: 10 },
}))

export const LastUpdated = ({ loading, value }) => {
  const classes = useClasses()

  const renderValue = () => {
    if (loading) {
      return <Skeleton width={150} height={20} className={classes.skeleton} />
    }
    return (
      <Typography className={classes.lastUpdate} style={{ marginLeft: 10 }}>
        {value}
      </Typography>
    )
  }

  return (
    <span className={classes.lastUpdateContainer}>
      <Typography className={classes.lastUpdate}>Last Updated:</Typography>
      {renderValue()}
    </span>
  )
}

const LineChartModal = ({ primaryIndex, secondaryIndex, description, horizon, classes, scale }) => {
  const dispatch = useDispatch()
  const changePercentage = !["sent"].includes(primaryIndex)
  const { queryParams, setQueryParams, removeQueryParams } = useQueryParams()

  const primaryDataLoading = useSelector(selectChartLoading)
  const comparisonLoading = useSelector(selectComparisonIsLoading)
  const countryDataLoading = useSelector(selectIsLoadingCountryData)
  const isLoading = secondaryIndex && comparisonLoading
  const isMobile = useSelector(selectIsMobile)

  const { value } = useSelector(selectLatestChartValue)
  const change = useSelector(selectChartChange)

  const primaryData = useSelector(selectChartData)
  const secondaryData = useSelector(selectSecondaryData)
  const primaryCountryName = useSelector(selectSelectedCountryName)
  const comparisonCountryName = useSelector(selectComparisonCountryName)

  const comparisonCountry = useSelector(selectComparisonCountry)

  const primaryLastUpdated = useSelector(selectChartLastUpdated)
  const secondaryLastUpdated = useSelector(selectComparisonLastUpdated)

  const isCasesIndex = ["total_cases", "total_deaths", "total_recovered"].includes(primaryIndex)
  const isSecondaryCaseIndex = [
    "total_cases",
    "total_deaths",
    "total_recovered",
    "total_active",
  ].includes(secondaryIndex)

  const lastUpdate = primaryDataLoading
    ? undefined
    : getRecentUpdate(primaryLastUpdated, secondaryLastUpdated)

  const indexTopicMap = {
    panic: "panic",
    fake: "fake-news",
  }

  const renderNewsFeed = () => {
    switch (primaryIndex) {
      case "total_cases":
      case "total_deaths":
      case "total_recovered":
        return <TopicNewsFeed topic="confirmed-cases" />
      case "fake":
      case "panic":
        return <TopicNewsFeed topic={indexTopicMap[primaryIndex]} />
      default:
        return <DefaultNewsFeed />
    }
  }

  React.useEffect(() => {
    return () => dispatch(clearComparisonData)
  }, [dispatch])

  const hasSingleAxis = indexGroups[primaryIndex] === indexGroups[secondaryIndex]
  const chartSeries = {
    main: primaryData || [],
    secondary: secondaryIndex && secondaryData.length ? secondaryData : undefined,
  }

  const data = chartSeries.main.map((point, index) => {
    return {
      main: point.value,
      ts: point.ts,
      ...(!isLoading && chartSeries.secondary && { secondary: chartSeries.secondary[index].value }),
    }
  })

  let dataPoints
  switch (horizon) {
    case "1D":
      dataPoints = 24
      break
    case "1W":
      dataPoints = 7 * 24
      break
    case "1M":
      dataPoints = 30
      break
    case "3M":
      dataPoints = 3 * 30
      break
    case "YTD":
      dataPoints = moment().dayOfYear()
      break
    case "ALL":
      dataPoints = data.length
      break
    default:
      dataPoints = data.length
      break
  }

  let chartData = data.slice(data.length - dataPoints)
  const smallestValue = 0.1

  function clamp(value, index, limit = 0.1) {
    if (value === undefined || value > limit || index === "sent") {
      return value
    }
    return limit
  }

  if (scale === "log") {
    chartData = chartData.map(i => ({
      ...i,
      main: clamp(i.main, primaryIndex, smallestValue),
      secondary: clamp(i.secpmdary, secondaryIndex, smallestValue),
    }))
  }

  const handleHorizonChange = v => {
    if (
      !(
        (["1D", "1W"].includes(horizon) && ["1D", "1W"].includes(v)) ||
        (["1M", "3M", "YTD", "ALL"].includes(horizon) && ["1M", "3M", "YTD", "ALL"].includes(v))
      )
    ) {
      dispatch(clearComparisonData)
      dispatch(
        fetchComparisonData({
          country: comparisonCountry,
          index: secondaryIndex,
          horizon: v,
          force: true,
        }),
      )
    }

    dispatch(updateData({ horizon: v }))
    setQueryParams({ ...queryParams, h: v })
  }

  const isHorizonItemDisabled = h => {
    return (
      ["1D", "1W"].includes(h) &&
      ((secondaryIndex !== undefined && secondaryIndex.startsWith("total")) ||
        primaryIndex.startsWith("total"))
    )
  }

  const handleRemove = () => {
    removeQueryParams(["cc", "ci"])
    dispatch(clearComparisonData())
    dispatch(setSecondaryIndex(undefined))
  }

  return (
    <div className={classes.main}>
      <div
        style={{
          maxWidth: 800,
          width: isMobile ? "100%" : "60%",
          zIndex: 10,
          padding: isMobile ? 0 : "0 0 20px 20px",
        }}
      >
        <div className={classes.header}>
          <div className={classes.valueContainer}>
            {primaryDataLoading ? (
              <Skeleton height={45} />
            ) : (
              <Typography className={classes.currentValue}>
                {numericDisplay(value, false, false, isCasesIndex ? 0 : 2)}
              </Typography>
            )}
            <Typography className={classes.valueLabel}>Latest Value</Typography>
          </div>
          <div className={classes.valueContainer}>
            {primaryDataLoading ? (
              <Skeleton height={45} />
            ) : (
              <Typography className={classes.change}>
                {numericDisplay(change, changePercentage, true, 2)}
              </Typography>
            )}
            <Typography className={classes.valueLabel} style={{ color: "#EDB820" }}>
              Daily Change
            </Typography>
          </div>
          {!isMobile && <LastUpdated loading={primaryDataLoading} value={lastUpdate} />}
        </div>
        <div className={classes.lineChartContainer}>
          <div className={classes.controls}>
            <HorizonSelector
              horizons={["1D", "1W", "1M", "3M", "YTD", "ALL"]}
              comparisonCountry={comparisonCountry}
              selected={horizon}
              primaryIndex={primaryIndex}
              secondaryIndex={secondaryIndex}
              onChange={handleHorizonChange}
              disabled={isHorizonItemDisabled}
              classes={classes}
            />
            <span className={classes.options}>
              <ScaleSelector
                value={scale}
                primaryIndex={primaryIndex}
                secondaryIndex={secondaryIndex}
              />
              <IndexSelector options={indexes} primaryIndex={primaryIndex} horizon={horizon} />
            </span>
          </div>
          {secondaryIndex !== undefined && (
            <div
              style={{
                display: "flex",
                justifyContent: hasSingleAxis ? "flex-start" : "space-between",
              }}
            >
              <IndexChip
                title={IndexNames[primaryIndex]}
                country={primaryCountryName}
                variant="secondary"
                loading={countryDataLoading}
              />
              <IndexChip
                title={IndexNames[secondaryIndex]}
                country={comparisonCountryName}
                variant="altLine"
                style={{ marginLeft: hasSingleAxis ? 10 : 0 }}
                onRemove={handleRemove}
                loading={countryDataLoading}
              />
            </div>
          )}
          {primaryDataLoading ? (
            <div style={{ height: 335 }} />
          ) : (
            <LineChart
              data={chartData}
              horizon={horizon}
              aspect={isMobile ? 1 : 2.5}
              hasSecondary={secondaryIndex !== undefined}
              primaryScale={primaryIndex === "sent" ? "linear" : scale}
              secondaryScale={secondaryIndex === "sent" ? "linear" : scale}
              singleAxis={hasSingleAxis}
              seriesTitles={{
                primary: `${secondaryIndex ? `${primaryCountryName}: ` : ""}${
                  IndexNames[primaryIndex]
                }`,
                comparison: `${comparisonCountryName}: ${IndexNames[secondaryIndex]}`,
              }}
              decimalPlaces={{
                primary: isCasesIndex ? 0 : 2,
                secondary: isSecondaryCaseIndex ? 0 : 2,
              }}
              classes={classes}
            />
          )}
        </div>
        <Typography className={classes.description}>{description}</Typography>
        {isMobile && <LastUpdated loading={primaryDataLoading} value={lastUpdate} />}
      </div>
      {renderNewsFeed()}
    </div>
  )
}

export default LineChartModal
