import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Skeleton } from "@material-ui/lab"

const useStyles = makeStyles({
  container: {
    padding: "10px 5px 10px 5px",
    borderBottom: "solid 1px #555",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
  },
})

const StoryCardSkeleton = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Skeleton style={{ width: 100 }} animation="wave" />
      <Skeleton style={{ width: "100%" }} animation="wave" />
      <Skeleton style={{ width: "100%" }} animation="wave" />
    </div>
  )
}

export default StoryCardSkeleton
