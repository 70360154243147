import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import indexReducer from "./indexes.duck"
import storyReducer from "./stories.duck"
import relatedTopicsReducer from "./relatedTopics.duck"
import countriesDataReducer from "./countriesData.duck"
import sectorsDataReducer from "./sectorsData.duck"
import throttleMiddleware from "./middleware/throttleMiddleware"
import localStorageMiddleware from "./middleware/localStorageMiddleware"
import modalReducer from "./modal.duck"
import uiReducer from "./userInterface.duck"
import comparisonReducer from "./comparison.duck"
import relatedHistoricalReducer from "./related.duck"
import relatedHistoricalComparisonReducer from "./relatedComparison.duck"

const middleware = [...getDefaultMiddleware(), throttleMiddleware, localStorageMiddleware]

const reducer = {
  indexes: indexReducer,
  stories: storyReducer,
  comparison: comparisonReducer,
  relatedHistorical: relatedHistoricalReducer,
  relatedTopics: relatedTopicsReducer,
  relatedTopicsComparison: relatedHistoricalComparisonReducer,
  countriesData: countriesDataReducer,
  sectorsData: sectorsDataReducer,
  modal: modalReducer,
  ui: uiReducer,
}

const store = configureStore({
  reducer,
  middleware,
})
export default store
