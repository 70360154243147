import "./polyfills"

import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import CssBaseline from "@material-ui/core/CssBaseline"
import { ThemeProvider } from "@material-ui/core/styles"
import * as Sentry from "@sentry/browser"
import TagManager from "react-gtm-module"
import App from "./App"
import theme from "./theme"
import store from "./redux/store"
import config from "./config"
import GenericModalProvider from "./components/Modal"
import GlobalThemeProvider from "common/style/GlobalThemeProvider"

if (config.tagManager.enabled) {
  TagManager.initialize({
    gtmId: config.tagManager.id,
  })
}

if (config.sentry.enabled) {
  Sentry.init({
    dsn: config.sentry.dsn,
    environment: config.environment,
    ignoreErrors: ["Non-Error exception captured", "Non-Error promise rejection captured"],
  })
}

ReactDOM.render(
  <Provider store={store}>
    <GlobalThemeProvider dark>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GenericModalProvider>
          <App />
        </GenericModalProvider>
      </ThemeProvider>
    </GlobalThemeProvider>
  </Provider>,
  document.querySelector("#root"),
)
