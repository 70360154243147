import axios from "axios"
import axiosRetry, { exponentialDelay } from "axios-retry"
import * as Sentry from "@sentry/browser"
import { EXPECTED_DATA_VERSION } from "./constants"
import config from "./config"

const BASE_DATA_URL = config.baseUrls.data

const baseClient = axios.create({ baseURL: BASE_DATA_URL })

baseClient.interceptors.request.use(
  r => {
    const request = { ...r }
    if (request.url !== "/status.json" && !request.url.startsWith(`/${EXPECTED_DATA_VERSION}`)) {
      request.url = `/${EXPECTED_DATA_VERSION}/${
        request.url.startsWith("/") ? request.url.slice(1) : request.url
      }`
    }
    return request
  },
  err => Promise.reject(err),
)

baseClient.interceptors.response.use(
  r => r.data,
  err => {
    Sentry.captureException(err)
    return Promise.reject(err)
  },
)

axiosRetry(baseClient, {
  retries: 3,
  retryDelay: exponentialDelay,
})

export default baseClient
