const throttled = {}

export default store => next => action => {
  const time = action.payload && action.payload.meta && action.payload.meta.throttle

  if (!time) return next(action)
  if (action.type in throttled) {
    return
  }

  throttled[action.type] = action
  setTimeout(() => {
    next(throttled[action.type])
    delete throttled[action.type]
  }, time)
}
