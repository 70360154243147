import { createAction, createAsyncThunk, createReducer, createSelector } from "@reduxjs/toolkit"
import ApiClient from "../APIClient"
import loadingStates from "common/state/loadingStates"

const initialState = {
  global: {
    data: {},
    byTimestamp: [],
    error: null,
    lastUpdate: undefined,
    loading: loadingStates.INIT,
  },
  country: {
    data: {},
    byTimestamp: [],
    loading: loadingStates.INIT,
    lastUpdated: undefined,
    error: null,
    intervalId: undefined,
  },
}

export const getStories = createAsyncThunk("GET_STORIES", () => ApiClient.get("news/news.json"))

export const getCountryStories = createAsyncThunk("GET_COUNTRY_STORIES", arg =>
  ApiClient.get(`news/country/${arg}/news.json`),
)

export const clearCountryStories = createAction("CLEAR_COUNTRY_STORIES")

const reducer = createReducer(initialState, {
  [getStories.pending]: state => {
    const currentState = state.global.loading
    state.global.loading =
      currentState === loadingStates.INIT ? loadingStates.INIT : loadingStates.PENDING
  },
  [getStories.fulfilled]: (state, action) => {
    state.global.data = action.payload.results.reduce(
      (acc, s) => ({
        ...acc,
        [s.id]: s,
      }),
      {},
    )
    state.global.lastUpdated = action.payload.ts
    state.global.byTimestamp = Object.values(state.global.data)
      .sort((a, b) => (a.ts > b.ts ? -1 : 1))
      .map(s => s.id)
      .slice(0, 100)
    state.global.loading = loadingStates.IDLE
  },
  [getStories.rejected]: (state, action) => {
    // state.global.error = action.payload.error
    state.global.loading = loadingStates.IDLE
  },
  [getCountryStories.pending]: state => {
    state.country.loading =
      state.country.loading === loadingStates.INIT ? loadingStates.INIT : loadingStates.PENDING
  },
  [getCountryStories.fulfilled]: (state, action) => {
    state.country.data = action.payload.results.reduce(
      (acc, s) => ({
        ...acc,
        [s.id]: s,
      }),
      {},
    )
    state.country.lastUpdated = action.payload.ts
    state.country.byTimestamp = Object.values(state.country.data)
      .sort((a, b) => (a.ts > b.ts ? -1 : 1))
      .map(s => s.id)
      .slice(0, 50)
    state.country.loading = loadingStates.IDLE
  },
  [getCountryStories.rejected]: (state, action) => {
    state.country.error = action.error
    state.country.loading = loadingStates.IDLE
  },
  [clearCountryStories]: state => {
    state.country = { ...initialState.country }
  },
})

const selectSelectedCountry = state => state.ui.selectedCountry

const selectGlobalStories = state => state.stories.global.data
const selectGlobalByTimestamp = state => state.stories.global.byTimestamp
export const selectLastUpdated = state => state.stories.global.lastUpdated
export const selectIsLoadingGlobalStories = state =>
  state.stories.global.loading === loadingStates.INIT

const selectCountryStories = state => state.stories.country.data
const selectCountryByTimestamp = state => state.stories.country.byTimestamp
export const selectIsLoadingCountryStories = state =>
  state.stories.country.loading === loadingStates.INIT

export const selectIsLoadingStories = createSelector(
  [selectSelectedCountry, selectIsLoadingGlobalStories, selectIsLoadingCountryStories],
  (selectedCountry, globalLoading, countryLoading) => {
    return selectedCountry === undefined ? globalLoading : countryLoading
  },
)

export const selectStories = createSelector(
  [selectSelectedCountry, selectGlobalStories, selectCountryStories],
  (country, globalStories, countryStories) =>
    country !== undefined ? countryStories : globalStories,
)

export const selectTimestampOrder = createSelector(
  [selectSelectedCountry, selectGlobalByTimestamp, selectCountryByTimestamp],
  (country, globalStories, countryStories) =>
    country !== undefined ? countryStories : globalStories,
)
export const selectStoriesByOrder = createSelector(
  [selectStories, selectTimestampOrder],
  (stories, order) => order.map(id => stories[id]),
)

export default reducer
