import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { setIsMobile } from "../redux/userInterface.duck"

const useMobileWatcher = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")) || window.innerWidth < 960

  useEffect(() => {
    dispatch(setIsMobile(isMobile))
  }, [isMobile, dispatch])
}

export default useMobileWatcher
