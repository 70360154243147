import React, { useState } from "react"
import RelatedTopicModalView from "./RelatedTopicModal.style"
import { useSelector } from "react-redux"
import {
  selectHistoricRelatedData,
  selectHistoricRelatedIsLoading,
  selectHistoricRelatedLastUpdated,
} from "../../../redux/related.duck"
import moment from "moment-timezone"

const formatLastUpdated = lastUpdated => {
  if (!lastUpdated) {
    return undefined
  }
  const userTimezone = moment.tz.guess()
  return moment.utc(lastUpdated).tz(userTimezone).format("MMM Do, HH:mm:ss z")
}

const RelatedTopicModal = ({
  value,
  change,
  description,
  horizon: defaultHorizon,
  topic,
  country,
  scale: defaultScale,
}) => {
  const data = useSelector(selectHistoricRelatedData(topic))
  const [horizon, setHorizon] = useState(defaultHorizon)
  const [comparison, setComparison] = useState(undefined)
  const [scale, setScale] = useState(defaultScale)
  const isLoading = useSelector(selectHistoricRelatedIsLoading)

  const lastUpdated = useSelector(selectHistoricRelatedLastUpdated)
  const lastUpdatedDisplay = formatLastUpdated(lastUpdated)

  const actions = { setHorizon, setComparison, setScale }

  return (
    <RelatedTopicModalView
      value={value}
      country={country}
      change={change}
      topic={topic}
      data={data}
      horizon={horizon}
      comparison={comparison}
      lastUpdated={lastUpdatedDisplay}
      description={description}
      isLoading={isLoading}
      scale={scale}
      actions={actions}
    />
  )
}

export default RelatedTopicModal
