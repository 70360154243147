import React, { useRef } from "react"
import { Tooltip, Typography, withStyles } from "@material-ui/core"

import { Skeleton } from "@material-ui/lab"
import numericDisplay from "../utils/displayPercentage"
import { useAnimatedValue } from "../hooks/useAnimatedValue"

const styles = theme => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    margin: "4px 0 2px",
    flexWrap: "wrap",
    "& .animated-value": {
      transition: "color 500ms",
    },
  },
  numbers: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "baseline",
  },
  change: {
    color: theme.palette.secondary.main,
    textAlign: "right",
    minWidth: 50,
    fontSize: 17,
    marginLeft: 10,
    "@media (max-width: 350px)": {
      fontSize: 15,
    },
  },
  cases: {
    fontSize: 22,
    color: props => props.color,
    fontWeight: 700,
    "@media (max-width: 350px)": {
      fontSize: 17,
    },
  },
  subTitle: {
    minWidth: 113,
    fontSize: 16,
    color: props => props.color,
    "@media (max-width: 350px)": {
      fontSize: 14,
    },
  },
  yellow: {
    "& .animated-value": {
      color: "#EBB71E !important",
    },
  },
})

const CaseDisplaySkeleton = withStyles(styles)(({ subTitle, tooltip, classes }) => (
  <div className={classes.container} style={{ alignItems: "flex-end" }}>
    <Tooltip title={tooltip}>
      <Typography className={classes.subTitle}>{subTitle}</Typography>
    </Tooltip>
    <div className={classes.numbers} style={{ alignItems: "flex-end" }}>
      <Skeleton className={classes.cases} width={75} height={33} animation="wave" />
      <Skeleton className={classes.change} width={30} height={23} animation="wave" />
    </div>
  </div>
))

const CasesDisplay = ({
  value,
  change,
  subTitle,
  tooltip,
  color = "#FFFFFF",
  loading,
  classes,
}) => {
  const ref = useRef(null)
  useAnimatedValue(value, ref, classes.yellow, 1000)

  if (loading) {
    return <CaseDisplaySkeleton subTitle={subTitle} color={color} tooltip={tooltip} />
  }

  const valueDisplay = value ? numericDisplay(value) : "—"
  const changeDisplay = change === null ? null : numericDisplay(change, true, false, 1)

  return (
    <div className={classes.container} ref={ref}>
      <Tooltip title={tooltip}>
        <Typography className={classes.subTitle}>{subTitle}</Typography>
      </Tooltip>
      <div className={classes.numbers}>
        <Tooltip title={value ? "" : "No Data Available"}>
          <Typography className={classes.cases + " animated-value"}>{valueDisplay}</Typography>
        </Tooltip>
        <Typography
          className={classes.change}
          style={{ visibility: change === null ? "hidden" : "visible" }}
        >
          {changeDisplay}
        </Typography>
      </div>
    </div>
  )
}

export default withStyles(styles)(CasesDisplay)
