import { Menu, MenuItem } from "@material-ui/core"
import React, { useState, memo } from "react"
import { makeStyles } from "@material-ui/core/styles"
import FacebookShare from "../ShareIcons/FacebookShare"
import EmailShare from "../ShareIcons/EmailShare"
import LinkedInShare from "../ShareIcons/LinkedInShare"
import TwitterShare from "../ShareIcons/TwitterShare"

const useStyles = makeStyles({
  paper: {
    background: "#404040",
    marginTop: 10,
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
  },
})

const pageTitle = "Coronavirus Media Monitor | RavenPack"
const pageDescription =
  "The Coronavirus Media Monitor tracks the latest info on the Novel Coronavirus and measures the current levels of panic, misinformation, and other topics conveyed in the media."

const ShareButton = ({ children, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()

  const handleShare = event => {
    if (navigator.share) {
      navigator.share({
        title: pageTitle,
        text: pageDescription,
        url: `https://coronavirus.ravenpack.com/?utm_campaign=covid-dashboard&utm_medium=referral&utm_source=mobile-social&utm_content=share&utm_term=`,
      })
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <div onClick={handleShare} {...props}>
        {children}
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        classes={{
          paper: classes.paper,
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        getContentAnchorEl={null}
      >
        <MenuItem onClick={handleClose} className={classes.menuItem}>
          <LinkedInShare />
        </MenuItem>
        <MenuItem onClick={handleClose} className={classes.menuItem}>
          <TwitterShare />
        </MenuItem>
        <MenuItem onClick={handleClose} className={classes.menuItem}>
          <FacebookShare />
        </MenuItem>
        <MenuItem onClick={handleClose} className={classes.menuItem}>
          <EmailShare />
        </MenuItem>
      </Menu>
    </>
  )
}

export default memo(ShareButton)
