import productionConfig from "./production"
import developmentConfig from "./development"

const envConfig = process.env.REACT_APP_ENV === "prod" ? productionConfig : developmentConfig

const commonConfig = {}

export default {
  ...commonConfig,
  ...envConfig,
  ...(process.env.REACT_APP_ENV === "local" && { sentry: { enabled: false } }),
}
