import React, { useState, memo } from "react"
import { Typography, Popover } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import FacebookShare from "../ShareIcons/FacebookShare"
import EmailShare from "../ShareIcons/EmailShare"
import LinkedInShare from "../ShareIcons/LinkedInShare"
import TwitterShare from "../ShareIcons/TwitterShare"
import RavenPackLogo from "../RavenPackLogo"
import CloseIcon from "common/components/CloseIcon"

const useStyles = makeStyles(theme => ({
  link: {
    color: "#CCCCCC",
    textDecoration: "none",
    fontSize: 14,
    marginLeft: 50,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
    cursor: "pointer",
    "&:hover": {
      color: "#FFFFFF",
    },
  },
  container: {
    padding: 20,
    background: "#404040",
    fontSize: 14,
    maxWidth: 600,
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
  },
  poweredBy: {
    color: theme.palette.secondary.main,
    fontSize: 12,
    fontWeight: 700,
  },
  poweredByContainer: {
    display: "flex",
    marginTop: 5,
  },
  logo: {
    marginLeft: 5,
  },
  text: {
    fontSize: 14,
    marginTop: 15,
  },
  shareContainer: {
    display: "flex",
    marginTop: 20,
  },
  shareText: {
    fontSize: 14,
    fontWeight: 700,
  },
  shareIcon: {
    marginLeft: 10,
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  closeIcon: {
    fill: "#808080",
    cursor: "pointer",
    "&:hover": {
      fill: "#FFFFFF",
    },
    height: 16,
    width: 16,
    marginLeft: "auto",
  },
}))

const AboutUsContent = ({ onClose }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <span className={classes.header}>
        <Typography variant="h5" className={classes.title}>
          Coronavirus Media Monitor
        </Typography>
        <CloseIcon className={classes.closeIcon} onClick={onClose} />
      </span>
      <span className={classes.poweredByContainer}>
        <Typography className={classes.poweredBy}>Powered by</Typography>
        <RavenPackLogo className={classes.logo} width={100} />
      </span>
      <Typography className={classes.text}>
        The Coronavirus Media Monitor tracks the latest info on the Novel Coronavirus and measures
        the current levels of panic, misinformation, and other topics conveyed in the media.
      </Typography>
      <Typography className={classes.text}>
        {" "}
        This website was developed by the RavenPack Team.
      </Typography>
      <Typography className={classes.text}>
        {" "}
        If you have questions, suggestions, or feedback, please send us an email at
        info@ravenpack.com
      </Typography>
      <span className={classes.shareContainer}>
        <Typography className={classes.shareText}>Share this website:</Typography>
        <LinkedInShare hideText className={classes.shareIcon} />
        <TwitterShare hideText className={classes.shareIcon} />
        <FacebookShare hideText className={classes.shareIcon} />
        <EmailShare hideText className={classes.shareIcon} />
      </span>
    </div>
  )
}

const AboutUs = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(undefined)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(undefined)
  }

  return (
    <>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <AboutUsContent onClose={handleClose} />
      </Popover>
      <Typography className={classes.link} onClick={handleClick}>
        About Us
      </Typography>
    </>
  )
}

export default memo(AboutUs)
