import { createSelector } from "@reduxjs/toolkit"
import createFetchReducer from "common/state/createFetch"
import loadingStates from "common/state/loadingStates"
import { selectTopicById } from "./relatedTopics.duck"
import APIClient from "../APIClient"

const slice = createFetchReducer(
  {
    name: "HISTORICAL_RELATED_TOPICS",
    url: ({ country, horizon }) => {
      const filename = ["1D", "1W"].includes(horizon) ? "related_hourly" : "related_ytd"
      return country === undefined ? `/${filename}.json` : `/country/${country}/${filename}.json`
    },
    path: "relatedHistorical",
  },
  APIClient,
)

export const fetchHistoricRelatedData = slice.actions.fetch
export const clearHistoricRelatedData = slice.actions.clear

const { reducer } = slice

export const selectHistoricRelatedData = topic => state => {
  if (!topic) {
    return []
  }

  const relatedTopicsData = selectTopicById(topic)(state)

  const rawData = slice.selectors.data(state)[topic] || {}
  const sortedData = Object.keys(rawData)
    .map(x => ({ ts: x, value: rawData[x] }))
    .sort((a, b) => {
      if (a.ts < b.ts) {
        return -1
      }
      if (a.ts > b.ts) {
        return 1
      }
      return 0
    })

  return [
    ...sortedData.slice(0, sortedData.length - 1),
    { ...sortedData[sortedData.length - 1], value: relatedTopicsData.ratio.c },
  ]
}

export const selectHistoricRelatedIsLoading = createSelector(
  [slice.selectors.loadingValue],
  loadingValue => loadingValue !== loadingStates.IDLE,
)
export const selectHistoricRelatedLastUpdated = slice.selectors.lastUpdated

export default reducer
