import React, { useEffect, useRef, useState } from "react"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { Skeleton } from "@material-ui/lab"
import { useDispatch, useSelector } from "react-redux"
import { Input, Paper, Popover } from "@material-ui/core"
import WidgetContainer from "./WidgetContainer"
import { selectCountryValues, selectSelectedCountryName } from "../redux/countriesData.duck"
import { selectCountry, setMapPosition } from "../redux/userInterface.duck"
import DropdownIcon from "./DropdownIcon"
import CountrySuggester from "../utils/CountrySuggestor"
import { useKey } from "../hooks/useKey"

const useStyle = makeStyles(theme => ({
  displayContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "5px 0px 0px 0px",
    borderRadius: 4,
    cursor: "pointer",
    transition: theme.transitions.create(["background"], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeOut,
    }),
    padding: 5,
    "&:hover": {
      background: "#343434",
      transition: theme.transitions.create(["background"], {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeIn,
      }),
    },
  },
  displayTitle: {
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.secondary.main,
  },
  dropdownIcon: {
    fill: "#FFFFFF",
    width: 24,
    height: 24,
  },
  popover: {
    border: "solid #7F7F7F 1px",
    width: 350,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  optionContainer: {
    maxHeight: 300,
    overflow: "auto",
    marginRight: 5,
    paddingRight: 5,
    margin: "5px 0",
    "&::-webkit-scrollbar": {
      width: "0.5em",
    },
    "&::-webkit-scrollbar-thumb": {
      maxHeight: 10,
      borderRadius: 5,
      backgroundColor: "#d7d7d7",
    },
  },
  searchInput: {
    borderBottom: "solid 2px",
    borderBottomColor: theme.palette.primary.main,
    width: "100%",
    padding: "5px 20px",
  },
  optionMatch: {
    color: theme.palette.secondary.main,
  },
  noDataPlaceholder: {
    padding: 20,
    color: "#808080",
  },
  suggestionLink: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}))

const useItemStyles = makeStyles({
  item: {
    cursor: "pointer",
    padding: "10px 20px",
    fontSize: 16,
    "&:hover": {
      background: "#373737",
    },
  },

  itemFocused: {
    cursor: "pointer",
    padding: "10px 20px",
    fontSize: 16,
    background: "#373737",
    border: "1px solid white",
  },
})

const CountrySelectorItem = ({ value, children, onClick, focused, ...props }) => {
  const classes = useItemStyles()
  const dispatch = useDispatch()
  const handleClick = event => {
    dispatch(selectCountry(value))
    dispatch(setMapPosition(undefined))
    if (onClick) {
      onClick(event)
    }
  }
  return (
    <div className={focused ? classes.itemFocused : classes.item} onClick={handleClick} {...props}>
      {children}
    </div>
  )
}

const CountrySelector = () => {
  const classes = useStyle()
  const [anchorEl, setAnchorEl] = useState(undefined)
  const [searchQuery, setSearchQuery] = useState("")
  const [width, setWidth] = useState(200)
  const [focusedItem, setFocusedItem] = useState(-1)
  const ref = useRef(null)
  const countryListRef = useRef(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.getBoundingClientRect().width)
    }
  }, [anchorEl])

  const countryName = useSelector(selectSelectedCountryName)
  const countries = useSelector(selectCountryValues)

  const handleOpen = event => {
    setAnchorEl(event.currentTarget)
    const selectedChild = searchQuery === "" ? focusedItem + 1 : focusedItem
    setTimeout(
      () =>
        countryListRef.current && countryListRef.current.children[selectedChild].scrollIntoView(),
      100,
    )
  }

  const handleClose = index => {
    setAnchorEl(undefined)
    setTimeout(() => setSearchQuery(""), 500)
    !isNaN(index) && setFocusedItem(index)
  }

  const handleSearch = event => {
    setSearchQuery(event.target.value)
    setFocusedItem(0)
  }

  const handleSuggestionClick = country => {
    dispatch(selectCountry(country))
    dispatch(setMapPosition(undefined))
    handleClose()
  }

  const renderOption = title => {
    if (searchQuery === "") {
      return title
    }

    const lcTitle = title.toLowerCase()
    const lcQuery = searchQuery.toLowerCase()
    const queryIndex = lcTitle.indexOf(lcQuery)

    return (
      <>
        <span>{title.slice(0, queryIndex)}</span>
        <span className={classes.optionMatch}>
          {title.slice(queryIndex, queryIndex + searchQuery.length)}
        </span>
        <span>{title.slice(queryIndex + searchQuery.length)}</span>
      </>
    )
  }

  const results = countries.filter(i => i.title.toLowerCase().includes(searchQuery.toLowerCase()))
  const suggestion = results.length === 0 ? CountrySuggester.check(searchQuery) : undefined

  const handleArrowDown = () => {
    let [item, max] =
      searchQuery === "" ? [focusedItem + 1, results.length] : [focusedItem, results.length - 1]
    if (item < max) {
      setFocusedItem(focusedItem + 1)
      item++
      countryListRef.current &&
        countryListRef.current.children[item].scrollIntoView({ block: "nearest" })
    }
  }

  const handleArrowUp = () => {
    let item = searchQuery === "" ? focusedItem + 1 : focusedItem
    if (item > 0) {
      setFocusedItem(focusedItem - 1)
      item--
      countryListRef.current &&
        countryListRef.current.children[item].scrollIntoView({ block: "nearest" })
    }
  }

  const handleEnter = () => {
    const item = searchQuery === "" ? focusedItem + 1 : focusedItem
    countryListRef.current && countryListRef.current.children[item].click()
  }

  useKey(40, handleArrowDown)
  useKey(38, handleArrowUp)
  useKey(13, handleEnter)

  return (
    <WidgetContainer
      title="Selected Region"
      description="Search and select a country to update widgets"
    >
      <div ref={ref} className={classes.displayContainer} onClick={handleOpen}>
        {countryName ? (
          <Typography className={classes.displayTitle}>{countryName}</Typography>
        ) : (
          <Skeleton animation="wave" width={100} height={26} />
        )}
        <DropdownIcon variant="outline" className={classes.dropdownIcon} width={24} />
      </div>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Paper square className={classes.popover} style={{ width }}>
          <div className={classes.searchInput}>
            <Input
              type="text"
              value={searchQuery}
              placeholder="Search for a country or region"
              onInput={handleSearch}
              fullWidth
              disableUnderline
              autoFocus
            />
          </div>
          <div className={classes.optionContainer} ref={countryListRef}>
            {searchQuery === "" && (
              <CountrySelectorItem
                value={undefined}
                style={{ borderBottom: "solid 1px #373737" }}
                onClick={handleClose}
                focused={focusedItem === -1}
              >
                Worldwide
              </CountrySelectorItem>
            )}
            {results.length > 0 ? (
              results.map((i, index) => (
                <CountrySelectorItem
                  key={`selector-item-${i.value}`}
                  value={i.value}
                  onClick={() => handleClose(index)}
                  focused={focusedItem === index}
                >
                  {renderOption(i.title)}
                </CountrySelectorItem>
              ))
            ) : (
              <div className={classes.noDataPlaceholder}>
                <div>No countries found matching {searchQuery}.</div>
                {suggestion && (
                  <div>
                    Did you mean:{" "}
                    <span
                      className={classes.suggestionLink}
                      onClick={() => handleSuggestionClick(suggestion.isocode)}
                    >
                      {suggestion.country}
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        </Paper>
      </Popover>
    </WidgetContainer>
  )
}

export default CountrySelector
