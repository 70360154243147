import { withStyles } from "@material-ui/core"
import LineChartModal from "./LineChartModal.view"

const styles = theme => ({
  lineChartContainer: {
    background: "#141414",
    padding: 10,
    outline: "none",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  header: {
    margin: "10px 0",
    display: "flex",
    alignItems: "stretch",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px 0px 20px",
    },
  },
  controls: {
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
    },
  },
  options: {
    display: "flex",
    marginLeft: "auto",
    flexGrow: 1,
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      padding: "0px 10px",
      justifyContent: "center",
      width: "100%",
      order: 10,
    },
  },
  valueContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 20,
    "&:first-child": {
      marginLeft: 0,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },
  },
  valueLabel: {
    fontSize: 12,
    marginTop: -5,
  },
  currentValue: {
    fontSize: 30,
    fontWeight: 700,
    flexGrow: 1,
  },
  change: {
    fontSize: 21,
    flexGrow: 1,
    lineHeight: "45px",
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  description: {
    marginTop: 20,
    fontSize: 12,
    color: "#C0C0C0",
    [theme.breakpoints.down("sm")]: {
      margin: 20,
    },
  },
  main: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  linechart: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px",
      boxSizing: "border-box",
      background: "#232523",
    },
  },
})

export default withStyles(styles)(LineChartModal)
