import { useTheme } from "@material-ui/core/styles"
import { useMediaQuery } from "@material-ui/core"

const useIsMobile = () => {
  const theme = useTheme()
  // For detecting page width
  const isMobile = useMediaQuery(theme.breakpoints.down("xs")) || window.innerWidth < 600
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"))
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  // This is a slightly smaller aspect ratio than 21 / 9 which is the most common.
  const isUltraWide = useMediaQuery("(min-aspect-ratio: 32 / 15) and (min-width: 2500px)")

  return { isMobile, isTablet, isDesktop, isUltraWide }
}

export default useIsMobile
