import {
  Line,
  LineChart as LineChartComponent,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  ReferenceLine,
  Dot,
} from "recharts"
import React, { useEffect, useRef, useState } from "react"
import moment from "moment"
import { Paper, Typography, useTheme } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import numericDisplay from "../../../utils/displayPercentage"

const useTooltipStyle = makeStyles(theme => ({
  container: {
    background: "#141414",
    border: "solid 1px #404040",
    padding: "5px 8px",
    minWidth: 120,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  date: {
    fontSize: 12,
  },
  value: {
    fontWeight: 700,
    fontSize: 14,
    textAlign: "right",
  },
}))

const TooltipValueDisplay = ({ value, color, title, display, classes }) => {
  return (
    display && (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ height: 3, width: 12, background: color, marginRight: 8 }} />
        <Typography style={{ flexGrow: 1, fontSize: 14, paddingRight: 10 }}>{title}</Typography>
        <Typography className={classes.value} style={{ color }}>
          {value}
        </Typography>
      </div>
    )
  )
}

export const CustomTooltip = ({
  payload,
  format = "MMM DD, HH:mm ",
  seriesTitles,
  decimalPlaces,
}) => {
  const ref = useRef(undefined)
  const classes = useTooltipStyle()
  const item = (payload[0] || { value: 0 }).payload || { value: 0 }
  const date = moment.utc(item.ts).format(format)
  const [offset, setOffset] = useState({ x: 0, y: 0 })
  const theme = useTheme()

  useEffect(() => {
    if (ref.current) {
      const boundingBox = ref.current.getBoundingClientRect()
      setOffset({
        x: -boundingBox.width / 2,
        y: -(boundingBox.height + 10),
      })
    }
  }, [ref])

  const mainValue = numericDisplay(item.main || item.value, false, false, decimalPlaces.primary)
  const comparisonValue = item.secondary
    ? numericDisplay(item.secondary, false, false, decimalPlaces.secondary)
    : undefined

  return (
    <Paper
      ref={ref}
      elevation={8}
      className={classes.container}
      style={{ transform: `translate(10px, ${offset.y}px)` }}
    >
      <Typography className={classes.date}>{date}</Typography>
      <TooltipValueDisplay
        classes={classes}
        value={mainValue}
        title={seriesTitles.primary}
        display
        color={theme.palette.secondary.main}
      />
      <TooltipValueDisplay
        classes={classes}
        value={comparisonValue}
        color={theme.palette.altLine.main}
        title={seriesTitles.comparison}
        display={comparisonValue !== undefined}
      />
    </Paper>
  )
}

const CustomTick = ({ x, y, payload, horizon }) => {
  const date = moment.utc(payload.value)
  let format

  switch (horizon) {
    case "1D":
      if (date.hours() % 6 !== 0) {
        return null
      }
      format = "MMM DD, HH:mm"
      break
    case "1W":
      if (date.hours() !== 0) {
        return null
      }
      format = "MMM DD"
      break
    case "1M":
      if (date.day() !== 0) {
        return null
      }
      format = "MMM DD"
      break
    case "3M":
      if (date.date() !== 1) {
        return null
      }
      format = "MMMM"
      break
    case "YTD":
      if (date.date() !== 1) {
        return null
      }
      format = "MMM"
      break
    case "ALL":
      if (date.date() === 1 && date.month() === 0) {
        format = "YYYY"
      } else if (date.date() === 1 && date.month() % 3 === 0) {
        format = "MMM"
      } else {
        return null
      }
      break
    default:
      break
  }

  const value = date.format(format).split(",") // this just splits the 1D format in 2 lines
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fill="#FFFFFF">
        {value[0]}
      </text>
      <text x={0} y={0} dy={32} textAnchor="middle" fill="#FFFFFF">
        {value[1]}
      </text>
    </g>
  )
}

const formatYTick = value => {
  return numericDisplay(parseFloat(value.toFixed(value >= 10 ? 0 : 2)), false, false, null)
}

const tickStyle = { fill: "white" }

export const CustomDot = React.memo(({ x, y, onChange, hide }) => {
  const theme = useTheme()
  useEffect(() => {
    onChange({ x: Math.floor(x), y: Math.floor(y) })
  })
  return hide ? null : (
    <Dot
      style={{ stroke: "white", strokeWidth: 1, fill: theme.palette.secondary.main }}
      cx={x}
      cy={y}
      r={4}
    />
  )
})

const LineChart = ({
  data,
  horizon,
  primaryScale,
  secondaryScale,
  hasSecondary,
  singleAxis,
  primaryDataKey = "main",
  secondaryDataKey = "secondary",
  seriesTitles = {},
  decimalPlaces = {},
  aspect = 2.5,
  classes = {},
}) => {
  const theme = useTheme()
  const format = ["1M", "3M", "YTD", "ALL"].includes(horizon) ? "MMMM DD" : "MMM DD, HH:mm"
  const [coords, setCoords] = useState({ x: 0, y: 0 })
  const [currentY, setCurrentY] = useState(undefined)

  return (
    <div className={classes.linechart}>
      <ResponsiveContainer aspect={aspect}>
        <LineChartComponent
          data={data}
          onMouseMove={f => {
            if (f.activePayload && f.activePayload.length > 0) {
              setCurrentY(f.activePayload[0].payload.main || f.activePayload[0].payload.value)
            }
          }}
          onMouseLeave={() => {
            setCurrentY(undefined)
          }}
          margin={{
            left: 25,
            right: 25,
          }}
        >
          <XAxis
            dataKey="ts"
            interval={0}
            tick={<CustomTick horizon={horizon} />}
            tickFormatter={v => moment.utc(v).local().format("HH:mm:ss")}
            height={40}
          />
          {hasSecondary && (
            <Line
              yAxisId={singleAxis ? "left" : "right"}
              type="monotone"
              dataKey={secondaryDataKey}
              stroke={theme.palette.altLine.main}
              strokeWidth={2}
              dot={false}
              activeDot={props => <CustomDot x={props.cx} y={props.cy} onChange={f => f} hide />}
              isAnimationActive
            />
          )}
          <Line
            yAxisId="left"
            type="monotone"
            dataKey={primaryDataKey}
            stroke={theme.palette.secondary.main}
            strokeWidth={2}
            dot={false}
            activeDot={props => <CustomDot x={props.cx} y={props.cy} onChange={setCoords} />}
            isAnimationActive
          />
          <YAxis
            yAxisId="left"
            scale={primaryScale}
            tickCount={5}
            mirror
            tickFormatter={formatYTick}
            tick={{ ...tickStyle }}
            domain={[
              min => (Math.abs(min) < 10 ? Math.floor(min * 10) / 10 : Math.floor(min)),
              max => (Math.abs(max) < 10 ? Math.ceil(max * 10) / 10 : Math.ceil(max)),
            ]}
          />
          {hasSecondary && (
            <YAxis
              yAxisId="right"
              scale={secondaryScale}
              orientation="right"
              tickCount={5}
              mirror
              tickFormatter={formatYTick}
              tick={{ ...tickStyle }}
              domain={[
                min => (Math.abs(min) < 10 ? Math.floor(min * 10) / 10 : Math.floor(min)),
                max => (Math.abs(max) < 10 ? Math.ceil(max * 10) / 10 : Math.ceil(max)),
              ]}
            />
          )}
          <Tooltip
            content={
              <CustomTooltip
                format={format}
                seriesTitles={seriesTitles}
                decimalPlaces={decimalPlaces}
              />
            }
            position={coords}
            cursor={{ stroke: "rgba(255,255,255,0.5)", strokeWidth: 1, strokeDasharray: "3 3" }}
            isAnimationActive={false}
          />
          <ReferenceLine
            y={currentY}
            yAxisId="left"
            stroke="white"
            strokeWidth={0.5}
            strokeDasharray="3 3"
          />
        </LineChartComponent>
      </ResponsiveContainer>
    </div>
  )
}

export default LineChart
