import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import CloseIcon from "common/components/CloseIcon"

const styles = theme => ({
  textContainer: {
    display: "flex",
    alignItems: "baseline",
  },
  closeIcon: {
    fill: props => theme.palette[props.variant].main,
    marginLeft: 10,
    width: 10,
    height: 10,
    cursor: "pointer",
  },
  text: {
    fontSize: 14,
    color: props => theme.palette[props.variant].main,
  },
  container: {
    background: props => (props.variant === "secondary" ? "#4A3D17" : "#203A49"),
    borderRadius: 15,
    padding: "3px 10px",
    marginBottom: 5,
  },
})

const IndexChip = ({ title, country, classes, onRemove, loading, ...props }) => {
  return (
    <div className={classes.container} {...props}>
      <span className={classes.textContainer}>
        {loading ? (
          <Skeleton width={100} />
        ) : (
          <Typography className={classes.text}>{`${country}: ${title}`}</Typography>
        )}
        {onRemove && <CloseIcon className={classes.closeIcon} onClick={onRemove} width={10} />}
      </span>
    </div>
  )
}

export default withStyles(styles)(IndexChip)
