import { createReducer, createSelector, createAsyncThunk } from "@reduxjs/toolkit"
import moment from "moment"
import ApiClient from "../APIClient"
import loadingStates from "common/state/loadingStates"
import CountrySuggester from "../utils/CountrySuggestor"

const initialState = {
  daily: {
    data: {},
    error: null,
    lastUpdated: undefined,
    loading: loadingStates.INIT,
  },
  totals: {
    yesterday: {},
    today: {},
    historic: {
      data: [],
      error: null,
      loading: loadingStates.INIT,
      lastUpdated: undefined,
    },
  },
  country: {
    data: [],
    error: null,
    loading: loadingStates.INIT,
    lastUpdated: undefined,
  },
  lastUpdated: "",
}

export const getCountriesData = createAsyncThunk("GET_COUNTRY_DATA", async () => {
  const countryData = await ApiClient.get("/countries.json")
  CountrySuggester.updateWordList([...countryData.results])
  return countryData
})

export const getCountryHistoricCases = createAsyncThunk("GET_COUNTRY_HISTORIC_CASES", arg =>
  ApiClient.get(`/country/${arg}/cases.json`),
)

export const getHistoricCases = createAsyncThunk("GET_HISTORIC_CASES", () =>
  ApiClient.get("/cases.json"),
)

const calcCasesColor = cases => {
  if (cases === 0) return 0
  if (cases < 1000) return 1
  if (cases < 100000) return 2
  if (cases < 500000) return 3
  if (cases < 1000000) return 4
  if (cases < 10000000) return 5
  return 6
}

const reducer = createReducer(initialState, {
  [getCountriesData.pending]: state => {
    state.daily.loading =
      state.daily.loading === loadingStates.INIT ? loadingStates.INIT : loadingStates.pending
  },
  [getCountriesData.fulfilled]: (state, action) => {
    state.daily.data = {}
    action.payload.results.forEach((country, i) => {
      state.daily.data[country.isocode || country.country || i] = country
    })
    state.totals = {
      ...state.totals,
      yesterday: action.payload.totals[0],
      today: action.payload.totals[1],
    }
    state.lastUpdated = action.payload.ts
    state.daily.loading = loadingStates.IDLE
  },
  [getCountriesData.rejected]: (state, action) => {
    state.daily.loading = loadingStates.IDLE
    state.daily.error = action.error
  },
  [getHistoricCases.pending]: state => {
    const currentState = state.totals.historic.loading
    state.totals.historic.loading =
      currentState === loadingStates.INIT ? loadingStates.INIT : loadingStates.PENDING
  },
  [getHistoricCases.fulfilled]: (state, action) => {
    state.totals.historic.data = action.payload.results
    state.totals.historic.lastUpdates = action.payload.ts
    state.totals.historic.loading = loadingStates.IDLE
  },
  [getHistoricCases.rejected]: (state, action) => {
    state.totals.historic.error = action.error
    state.totals.historic.loading = loadingStates.IDLE
  },
  [getCountryHistoricCases.pending]: state => {
    const currentState = state.country.loading
    state.country.loading =
      currentState === loadingStates.INIT ? loadingStates.INIT : loadingStates.PENDING
  },
  [getCountryHistoricCases.fulfilled]: (state, action) => {
    state.country.data = action.payload.results
    state.country.lastUpdated = action.payload.ts
    state.country.loading = loadingStates.IDLE
  },
  [getCountryHistoricCases.rejected]: (state, action) => {
    state.country.error = action.error
    state.country.loading = loadingStates.IDLE
  },
})

const calculatePercent = (a, b) => {
  if (a === null || b === null) {
    return null
  }
  if (a === undefined || b === undefined) {
    return undefined
  }

  if (a === 0 && b === 0) {
    return 0
  }

  return Math.trunc(((a - b) / b) * 100 * 10) / 10
}

const calculateDeltaPercent = (a, b) => {
  if (a === null || b === null) {
    return null
  }
  if (a === undefined || b === undefined) {
    return undefined
  }
  return Math.trunc((a - b) * 100) / 100
}

export const singleCountryData = (isocode, state) => {
  if (!isocode) return
  const { data } = state.countriesData.daily
  const country = data[isocode]
  return {
    country: country.country,
    isocode: country.isocode,
    cases: {
      value: calcCasesColor(country.cases.c),
      rows: [
        {
          label: "Total Cases",
          value: country.cases.c,
          previous: country.cases.p,
          change: calculatePercent(country.cases.c, country.cases.p),
        },
        {
          label: "Cases Today",
          value: country.cases.c - country.cases.p,
          previous: country.cases.p,
          change: undefined,
        },
        {
          label: "Active Cases",
          value: country.active.c,
          previous: country.active.p,
          change: calculatePercent(country.active.c, country.active.p),
        },
        {
          label: "Recovered",
          value: country.recovered.c,
          previous: country.recovered.p,
          change: calculatePercent(country.recovered.c, country.recovered.p),
        },
        {
          label: "Total Deaths",
          value: country.deaths.c,
          previous: country.deaths.p,
          change: calculatePercent(country.deaths.c, country.deaths.p),
        },
        {
          label: "Deaths Today",
          value: country.deaths.c - country.deaths.p,
          previous: country.deaths.p,
          change: undefined,
        },
      ],
      mediaAnalytics: [
        {
          label: "Country Sentiment",
          value: country.sent.c,
          previous: country.sent.p,
          change: calculateDeltaPercent(country.sent.c, country.sent.p),
        },
        {
          label: "Media Exposure",
          value: country.media.c,
          previous: country.media.p,
          change: calculateDeltaPercent(country.media.c, country.media.p),
        },
      ],
    },
    sent: {
      value: country.sent.c,
      change: calculateDeltaPercent(country.sent.c, country.sent.p),
      showSubheader: true,
      description: "Coronavirus Sentiment Index",
    },
    media: {
      value: country.media.c,
      change: calculateDeltaPercent(country.media.c, country.media.p),
      showSubheader: true,
      description: "Coronavirus Media Exposure Index",
    },
  }
}

export const selectMapData = state => {
  const { data } = state.countriesData.daily
  return Object.keys(data).map(c => singleCountryData(c, state))
}

export const selectCasesTableData = state => {
  const { selectedCountry } = state.ui
  return Object.values(state.countriesData.daily.data).map(country => ({
    isocode: country.isocode,
    isSelectedCountry: selectedCountry === country.isocode,
    columns: {
      country: country.country,
      cases: country.cases.c,
      casesChange: calculatePercent(country.cases.c, country.cases.p),
      todayCases: country.cases.c - country.cases.p,
      active: country.active.c,
      activeChange: calculatePercent(country.active.c, country.active.p),
      recovered: country.recovered.c,
      recoveredChange: calculatePercent(country.recovered.c, country.recovered.p),
      todayRecovered: country.recovered.c - country.recovered.p,
      deaths: country.deaths.c,
      deathsChange: calculatePercent(country.deaths.c, country.deaths.p),
      todayDeaths: country.deaths.c - country.deaths.p,
      sent: country.sent.c,
      sentChange: calculateDeltaPercent(country.sent.c, country.sent.p),
      media: country.media.c,
      mediaChange: calculateDeltaPercent(country.media.c, country.media.p),
    },
  }))
}

const getDailyData = state => state.countriesData.daily.data
const getSelectedCountry = state => state.ui.selectedCountry

export const selectCasesTableDataV2 = createSelector(
  [getDailyData, getSelectedCountry],
  (dailyData, selectedCountry) => {
    return Object.values(dailyData).map(country => ({
      isocode: country.isocode,
      isSelectedCountry: selectedCountry === country.isocode,
      name: country.country,
      cases: country.cases.c,
      casesChange: calculatePercent(country.cases.c, country.cases.p),
      todayCases: country.cases.c - country.cases.p,
      active: country.active.c,
      activeChange: calculatePercent(country.active.c, country.active.p),
      recovered: country.recovered.c,
      recoveredChange: calculatePercent(country.recovered.c, country.recovered.p),
      todayRecovered: country.recovered.c - country.recovered.p,
      deaths: country.deaths.c,
      mortalityRate: (country.deaths.c / country.cases.c) * 100,
      deathsChange: calculatePercent(country.deaths.c, country.deaths.p),
      todayDeaths: country.deaths.c - country.deaths.p,
      sent: country.sent.c,
      sentChange: calculateDeltaPercent(country.sent.c, country.sent.p),
      media: country.media.c,
      mediaChange: calculateDeltaPercent(country.media.c, country.media.p),
    }))
  },
)

export const selectLastUpdated = state => state.countriesData.lastUpdated

export const selectIsLoadingCountryData = state =>
  state.countriesData.daily.loading === loadingStates.INIT

const selectCountryData = state => {
  if (selectIsLoadingCountryData(state)) {
    return undefined
  }
  return state.countriesData.daily.data[state.ui.selectedCountry]
}

const selectGlobalCases = state => {
  return Object.values(state.countriesData.daily.data).reduce((acc, v) => acc + (v.cases.c || 0), 0)
}
const selectGlobalDeaths = state => {
  return Object.values(state.countriesData.daily.data).reduce(
    (acc, v) => acc + (v.deaths.c || 0),
    0,
  )
}
const selectGlobalRecovered = state => {
  return Object.values(state.countriesData.daily.data).reduce(
    (acc, v) => acc + (v.recovered.c || 0),
    0,
  )
}

export const selectCurrentCases = createSelector(
  [selectCountryData, selectGlobalCases],
  (countryData, globalCases) => {
    return countryData ? countryData.cases.c : globalCases
  },
)

export const selectCurrentDeaths = createSelector(
  [selectCountryData, selectGlobalDeaths],
  (countryData, globalDeaths) => (countryData ? countryData.deaths.c : globalDeaths),
)

export const selectCurrentRecovered = createSelector(
  [selectCountryData, selectGlobalRecovered],
  (countryData, globalRecovered) => (countryData ? countryData.recovered.c : globalRecovered),
)

const selectTotalValues = state => state.countriesData.totals

const selectCasesChange = createSelector(
  [selectCurrentCases, selectCountryData, selectTotalValues],
  (cases, countryData, globalTotals) => {
    const previousTotal = countryData ? countryData.cases.p : globalTotals.yesterday.total_cases
    return calculatePercent(cases, previousTotal)
  },
)

const selectCasesDeaths = createSelector(
  [selectCurrentDeaths, selectCountryData, selectTotalValues],
  (deaths, countryData, globalTotals) => {
    const previousTotal = countryData ? countryData.deaths.p : globalTotals.yesterday.total_deaths
    return calculatePercent(deaths, previousTotal)
  },
)

const selectCasesRecovered = createSelector(
  [selectCurrentRecovered, selectCountryData, selectTotalValues],
  (recovered, countryData, globalTotals) => {
    const previousTotal = countryData
      ? countryData.recovered.p
      : globalTotals.yesterday.total_recovered
    return calculatePercent(recovered, previousTotal)
  },
)

export const selectTotals = createSelector(
  [
    selectCurrentCases,
    selectCurrentDeaths,
    selectCurrentRecovered,
    selectCasesChange,
    selectCasesDeaths,
    selectCasesRecovered,
  ],
  (currentCases, currentDeaths, currentRecovered, casesChange, deathsChange, recoveredChange) => {
    return {
      total_cases: currentCases || undefined,
      total_deaths: currentDeaths || undefined,
      total_recovered: currentRecovered || undefined,
      casesChange,
      deathsChange,
      recoveredChange,
    }
  },
)

export const selectIsLoadingHistoricCases = state => {
  return state.countriesData.totals.historic.loading === loadingStates.INIT
}

export const selectHistoricTotals = state => {
  const { selectedCountry } = state.ui
  return selectedCountry !== undefined
    ? state.countriesData.country.data
    : state.countriesData.totals.historic.data
}
export const selectHistoricCases = createSelector(
  [selectHistoricTotals, selectTotals],
  (results, totalsToday) => {
    const r = results.map(v => ({ ts: v.date, value: v.total_cases }))
    return [
      ...r.slice(0, r.length - 1),
      { ts: moment.utc().format("YYYY-MM-DD"), value: totalsToday.total_cases },
    ]
  },
)

export const selectHistoricDeaths = createSelector(
  [selectHistoricTotals, selectTotals],
  (results, totalsToday) => {
    const r = results.map(v => ({ ts: v.date, value: v.total_deaths }))
    return [...r.slice(0, r.length - 1), { ...r[r.length - 1], value: totalsToday.total_deaths }]
  },
)

export const selectHistoricRecovered = createSelector(
  [selectHistoricTotals, selectTotals],
  (results, totalsToday) => {
    return [
      ...results.map(v => ({ ts: v.date, value: v.total_recovered })),
      {
        ts: moment.utc().format("YYYY-MM-DD"),
        value: totalsToday.total_recovered,
      },
    ]
  },
)

export const selectHistoricCasesLastUpdate = state => state.countriesData.totals.historic.ts

export const selectCountriesByMediaExp = state => {
  const { selectedCountry } = state.ui
  return Object.values(state.countriesData.daily.data)
    .filter(country => country.media.c)
    .map(props => {
      const { country, media, isocode, sent } = props
      return {
        isSelectedCountry: selectedCountry === isocode,
        iso2: isocode,
        columns: {
          entity: country,
          sent: sent.c,
          sentChange: calculateDeltaPercent(sent.c, sent.p),
          media: media.c,
          mediaChange: calculateDeltaPercent(media.c, media.p),
        },
      }
    })
}

export const selectSelectedCountryName = state => {
  const selectedCountyCode = state.ui.selectedCountry

  if (selectIsLoadingCountryData(state)) {
    return undefined
  }

  if (selectedCountyCode === undefined) {
    return "Worldwide"
  }

  return state.countriesData.daily.data[selectedCountyCode]
    ? state.countriesData.daily.data[selectedCountyCode].country
    : ""
}

export const selectCountryValues = state => {
  return Object.values(state.countriesData.daily.data).map(i => ({
    title: i.country,
    value: i.isocode,
  }))
}

export default reducer
