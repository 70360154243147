import React from "react"
import { Checkbox as MUICheckbox, FormControlLabel } from "@material-ui/core"
import { Controller } from "react-hook-form"
import useStyles from "./SubscribeForm/SubscribeForm.styles"

const Checkbox = ({ name, label, control, required }) => {
  const validationRules = {
    required,
  }
  const classes = useStyles()
  return (
    <Controller
      control={control}
      name={name}
      type="checkbox"
      rules={validationRules}
      defaultValue={false}
      render={({ onBlur, onChange, value }) => {
        return (
          <FormControlLabel
            classes={{ label: classes.label }}
            control={
              <MUICheckbox
                checked={value}
                onBlur={onBlur}
                onChange={e => onChange(e.target.checked)}
                color="primary"
                classes={{
                  root: classes.checkbox,
                }}
              />
            }
            label={label}
          />
        )
      }}
    />
  )
}

export default Checkbox
