import useQueryParams from "./useQueryParams"

const useUtmParams = () => {
  const UTM_PARAMS = ["utm_campaign", "utm_medium", "utm_source", "utm_content", "utm_term", "rpid"]
  const localItems = { ...localStorage }
  const { queryParams } = useQueryParams()

  const utmParams = UTM_PARAMS
    // Filter out non utm items
    .filter(param => localItems[param])
    // Push them to the array
    .map(param => ({ k: [param], v: localItems[param] }))

  const setUtmParams = () => {
    UTM_PARAMS
      // Filter out non utm items
      .filter(param => queryParams[param])
      // Save utm values to localStorage
      .forEach(param => localStorage.setItem(param, queryParams[param]))
  }

  return { utmParams, setUtmParams }
}

export default useUtmParams

// Test query params
// ?utm_campaign=james_testing1&utm_medium=james_testing2&utm_source=james_testing3&utm_content=james_testing4&utm_term=james_testing5&rpid=james_testing6
