import { createAction, createAsyncThunk, createReducer, createSelector } from "@reduxjs/toolkit"
import ApiClient from "../APIClient"
import loadingStates from "common/state/loadingStates"

const initialState = {
  global: {
    data: {},
    loading: loadingStates.INIT,
    error: null,
    lastUpdated: undefined,
  },
  country: {
    data: {},
    loading: loadingStates.INIT,
    error: null,
    lastUpdated: undefined,
  },
}

export const getRelatedTopics = createAsyncThunk("GET_RELATED_TOPICS", () =>
  ApiClient.get("/related.json"),
)

export const getCountryRelatedTopics = createAsyncThunk("GET_COUNTRY_RELATED_TOPICS", arg =>
  ApiClient.get(`/country/${arg}/related.json`),
)

export const clearCountryRelatedTopics = createAction("CLEAR_COUNTRY_RELATED_TOPICS")

const reducer = createReducer(initialState, {
  [getRelatedTopics.pending]: state => {
    const currentState = state.global.loading
    state.global.loading =
      currentState === loadingStates.INIT ? loadingStates.INIT : loadingStates.PENDING
  },
  [getRelatedTopics.fulfilled]: (state, action) => {
    state.global.data = action.payload.results
    state.global.lastUpdated = action.payload.ts
    state.global.loading = loadingStates.IDLE
  },
  [getRelatedTopics.rejected]: (state, action) => {
    state.global.error = action.error
    state.global.loading = loadingStates.IDLE
  },
  [getCountryRelatedTopics.pending]: state => {
    const currentState = state.country.loading
    state.country.loading =
      currentState === loadingStates.INIT ? loadingStates.INIT : loadingStates.PENDING
  },
  [getCountryRelatedTopics.fulfilled]: (state, action) => {
    state.country.data = action.payload.results
    state.country.lastUpdated = action.payload.ts
    state.country.loading = loadingStates.IDLE
  },
  [getCountryRelatedTopics.rejected]: (state, action) => {
    state.country.error = action.error
    state.country.loading = loadingStates.IDLE
  },
  [clearCountryRelatedTopics]: state => {
    state.country = initialState.country
  },
})

const formatRelatedTopic = ({ name, ratio, id }) => ({
  columns: {
    id,
    entity: name,
    ratio: ratio.c ? ratio.c : "0.00",
    change: ratio.c && ratio.p ? ratio.c - ratio.p : 0,
  },
})

export const selectGlobalRelatedTopics = state => {
  return Object.values(state.relatedTopics.global.data)
}

export const selectCountryRelatedTopics = state => {
  return Object.values(state.relatedTopics.country.data)
}

const selectSelectedCountry = state => state.ui.selectedCountry

export const selectRelatedTopics = createSelector(
  [selectSelectedCountry, selectGlobalRelatedTopics, selectCountryRelatedTopics],
  (selectedCountry, globalTopics, countryTopics) => {
    const data = selectedCountry === undefined ? globalTopics : countryTopics
    return data.map(formatRelatedTopic)
  },
)

export const selectTopicById = topic =>
  createSelector(
    [selectSelectedCountry, selectGlobalRelatedTopics, selectCountryRelatedTopics],
    (selectedCountry, globalTopics, countryTopics) => {
      const data = selectedCountry === undefined ? globalTopics : countryTopics
      return data.filter(x => x.id === topic)[0]
    },
  )

export const selectGlobalIsLoading = state =>
  state.relatedTopics.global.loading === loadingStates.INIT
export const selectCountryIsLoading = state =>
  state.relatedTopics.country.loading === loadingStates.INIT

export const selectRelatedTopicsIsLoading = createSelector(
  [selectSelectedCountry, selectGlobalIsLoading, selectCountryIsLoading],
  (selectedCountry, globalLoading, countryLoading) => {
    return selectedCountry === undefined ? globalLoading : countryLoading
  },
)

export const selectLastUpdated = state => state.relatedTopics.global.lastUpdated

export default reducer
