import React, { memo } from "react"
// Packages
import PropTypes from "prop-types"
import { ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps"
import { scaleQuantize } from "d3-scale"
// Components
import MapControls from "./MapControls.view"
import MapInfoCard from "./MapInfoCard"
// Redux
import { useSelector, useDispatch } from "react-redux"
import { selectIsMobile, selectMapPosition, setMapPosition } from "../../redux/userInterface.duck"
import { useTheme } from "@material-ui/core"

const MapChart = ({
  data,
  colors,
  domain,
  scaleDisplay,
  geoData,
  setTooltipContent,
  height,
  selectedCountry,
  tooltipContent,
  type,
  handleCountryClicked,
  handleCountryHover,
}) => {
  const theme = useTheme()
  const isMobile = useSelector(selectIsMobile)
  const colorScale = scaleQuantize().domain(domain).range(colors)

  const dispatch = useDispatch()
  const { zoom, coordinates } = useSelector(selectMapPosition)
  const handleMoveEnd = position => {
    dispatch(setMapPosition(position))
  }

  const wasCountryClicked = e => {
    if (!e.target.classList.contains("rsm-geography")) {
      handleCountryClicked()
    }
  }

  const hoverStrokeWeight = zoom < 2 ? 2 : zoom < 4 ? 1 : 0.5

  return (
    <>
      <div
        style={{
          overflow: "hidden",
          position: "relative",
        }}
      >
        <ComposableMap
          style={{ height: height, width: "100%", touchAction: "none" }}
          data-tip=""
          height={isMobile ? 500 : 412}
          projection="geoEqualEarth"
          projectionConfig={{
            scale: 180,
          }}
          onClick={wasCountryClicked}
        >
          <ZoomableGroup zoom={zoom} center={coordinates} onMoveEnd={handleMoveEnd}>
            <Geographies geography={geoData}>
              {({ geographies }) =>
                geographies.map(geo => {
                  const cur = data.find(s => s.isocode === geo.properties.ISO_A2)
                  const curIsSelected = selectedCountry && cur && selectedCountry === cur.isocode
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={cur ? colorScale(cur[type].value) : "#7f7f7f"}
                      fillOpacity={!selectedCountry || curIsSelected ? 1 : 0.6}
                      stroke={curIsSelected ? "#efefef" : "#000"}
                      strokeOpacity={curIsSelected ? 1 : 0.2}
                      strokeWidth={curIsSelected ? hoverStrokeWeight : 0.5}
                      style={{
                        default: {
                          outline: "none",
                          transition: theme.transitions.create("fill-opacity", {
                            easing: theme.transitions.easing.easeInOut,
                            duration: theme.transitions.duration.shortest,
                          }),
                          strokeLinejoin: "round",
                        },
                        hover: {
                          stroke: "#fff",
                          cursor: "pointer",
                          strokeOpacity: 1,
                          fillOpacity: "0.9",
                          strokeWidth: hoverStrokeWeight,
                          outline: "none",
                          strokeLinejoin: "round",
                        },
                        pressed: {
                          outline: "none",
                        },
                      }}
                      onMouseEnter={() => handleCountryHover(cur, geo)}
                      onMouseLeave={() => setTooltipContent({})}
                      onClick={() => handleCountryClicked(cur, geo)}
                    />
                  )
                })
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
        <MapControls
          colors={colors}
          domain={domain}
          scaleDisplay={scaleDisplay}
          handleClose={setTooltipContent}
        />
      </div>
      {isMobile && (
        <MapInfoCard data={tooltipContent} type={type} handleClose={setTooltipContent} />
      )}
    </>
  )
}

MapChart.propTypes = {
  colors: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  domain: PropTypes.array.isRequired,
  geoData: PropTypes.object.isRequired,
  setTooltipContent: PropTypes.func.isRequired,
}

export default memo(MapChart)
