import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
  palette: {
    type: "dark",
    common: { black: "#000", white: "#fff" },
    background: { paper: "#252525", default: "#141414", tooltip: "#141414", outline: "#404040" },
    primary: {
      light: "rgba(66, 189, 158, 1)",
      main: "rgba(3, 166, 125, 1)",
      dark: "rgba(0, 123, 93, 1)",
      contrastText: "#fff",
    },
    secondary: {
      light: "rgb(246,219,32)",
      main: "rgb(237,184,32)",
      dark: "rgb(214,134,37)",
      contrastText: "#fff",
    },
    error: { light: "#e57373", main: "#f44336", dark: "#d32f2f", contrastText: "#fff" },
    text: {
      primary: "rgba(247, 247, 247, 1)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
  },
  typography: {
    fontFamily: ["Lato", "sans-serif"].join(","),
  },
  overrides: {
    MuiTooltip: {
      popper: {
        opacity: 1,
      },
      tooltip: {
        maxWidth: 400,
        padding: "10px 15px",
        fontSize: "14px",
        color: "#FFFFFF",
        background: "#343434 !important",
        border: "solid 1px #404040",
      },
      arrow: {
        color: "#404040",
      },
    },
  },
})

export default theme
