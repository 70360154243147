import { useSelector } from "react-redux"
import { selectIsMobile } from "../redux/userInterface.duck"

const PORTRAIT = "PORTRAIT"
const LANDSCAPE = "LANDSCAPE"

const useMobile = () => {
  const isMobile = useSelector(selectIsMobile)
  const orientation = window.innerWidth < window.innerHeight ? PORTRAIT : LANDSCAPE
  const isPortrait = orientation === PORTRAIT

  return { isMobile, isPortrait, orientation }
}

export default useMobile
