import React from "react"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"

const horizonSelectorStyles = theme => ({
  item: {
    padding: 5,
    marginRight: 10,
    fontWeight: 700,
    color: props => (props.active ? theme.palette.primary.main : "inherit"),
    borderBottom: props => (props.active ? `solid 4px ${theme.palette.primary.main}` : "none"),
    marginBottom: props => (props.active ? 0 : 4),
    cursor: props => (props.disabled ? "default" : "pointer"),
    opacity: props => (props.disabled ? 0.5 : 1),
    "&:hover": props =>
      props.disabled
        ? {}
        : {
            color: theme.palette.primary.main,
            borderBottom: `solid 4px ${theme.palette.primary.main}`,
            marginBottom: 0,
          },
  },
})

const HorizonSelectorItem = withStyles(horizonSelectorStyles)(
  ({ value, active, classes, disabled, onClick, ...props }) => {
    const handleClick = e => {
      if (!disabled) {
        onClick(e)
      }
    }
    return (
      <Typography className={classes.item} onClick={handleClick} {...props}>
        {value}
      </Typography>
    )
  },
)

const useHorizonStyles = makeStyles(theme => ({
  horizonSelector: {
    display: "flex",
    marginBottom: 15,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 0,
    },
  },
}))

const HorizonSelector = ({ horizons, selected, disabled, onChange }) => {
  const classes = useHorizonStyles()

  const handleClick = horizon => event => {
    if (onChange) {
      onChange(horizon, event)
    }
  }

  return (
    <div className={classes.horizonSelector}>
      {horizons.map(h => (
        <HorizonSelectorItem
          key={`horizon-item-${h}`}
          value={h}
          onClick={handleClick(h)}
          active={h === selected}
          disabled={disabled ? disabled(h) : false}
        />
      ))}
    </div>
  )
}

export default HorizonSelector
