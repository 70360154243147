import React, { useEffect, useState } from "react"
// Packages
import {
  Button,
  Paper,
  Typography,
  Link,
  CircularProgress,
  Modal,
  makeStyles,
  IconButton,
} from "@material-ui/core"
import CloseRoundedIcon from "@material-ui/icons/CloseRounded"
import { useDispatch, useSelector } from "react-redux"
// Components
import Icon from "common/components/Icon"
import NameInput from "./SubscribeForm/NameInput"
import EmailInput from "./SubscribeForm/EmailInput"
import GdprCheckbox from "./SubscribeForm/GdprCheckbox"
import useDataRequestForm from "./useDataRequestForm"
import Checkbox from "./Checkbox"
// Assets
import IndexesIcon from "../../../assets/indexes.svg"
import CovidCasesIcon from "../../../assets/covidCases.svg"
import TrendingTopicsIcon from "../../../assets/trendingTopics.svg"
import MediaAttentionIcon from "../../../assets/mediaAttention.svg"
//Data
import { selectShowDataForm, showDataForm } from "../../../redux/userInterface.duck"

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))

const Divider = () => (
  <hr style={{ border: "none", borderTop: "solid 1px #CCCCCC", width: "100%" }} />
)

const DataRequestForm = ({ onSuccess }) => {
  const { handleSubmit, control, errors, loading, success } = useDataRequestForm()

  useEffect(() => {
    if (onSuccess && success === true) {
      onSuccess()
    }
  }, [success, onSuccess])

  return loading ? (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: 400 }}>
      <CircularProgress />
    </div>
  ) : (
    <form
      style={{ display: "flex", flexDirection: "column", maxWidth: 400 }}
      onSubmit={handleSubmit}
    >
      <NameInput control={control} error={errors.name} size="large" />
      <EmailInput control={control} error={errors.email} size="large" />
      <Typography style={{ fontWeight: 700, marginTop: 8 }}>
        Subscribe to RavenPack updates:
      </Typography>
      <Checkbox name="coronavirusUpdates" label="Coronavirus Newsletter" control={control} />
      <Checkbox
        name="ravenpackUpdates"
        label="RavenPack Events, Webinars & Insights"
        control={control}
      />
      <Divider />
      <GdprCheckbox control={control} error={errors.gdpr} />
      <Button type="submit" variant="contained" color="primary" style={{ marginTop: 32 }}>
        Request Data
      </Button>
    </form>
  )
}

const SuccessContent = ({ closeModal }) => {
  const handleClick = () => {
    closeModal()
  }

  return (
    <Paper
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: 64,
        margin: 64,
        position: "relative",
      }}
    >
      <IconButton
        onClick={handleClick}
        color="default"
        style={{ position: "absolute", top: 16, right: 16 }}
      >
        <CloseRoundedIcon />
      </IconButton>
      <Typography style={{ fontSize: 30, fontWeight: 700, margin: "16px 0" }}>
        Thanks for submitting your request!
      </Typography>
      <Typography style={{ fontSize: 16, margin: "16px 0 32px" }}>
        One of our team members will be in touch shortly.
      </Typography>
      <Button onClick={handleClick} variant="contained" color="primary">
        Got it
      </Button>
    </Paper>
  )
}

const points = [
  { title: "RavenPack Indexes", image: IndexesIcon },
  { title: "Covid Cases Data", image: CovidCasesIcon },
  { title: "Coronavirus Trending Topics", image: TrendingTopicsIcon },
  { title: "Media Exposure by Segment", image: MediaAttentionIcon },
]

const Point = ({ title, image }) => (
  <div style={{ display: "flex", padding: "7px 0", alignItems: "center" }}>
    <Icon src={image} width={24} square style={{ height: 24, width: 24, marginRight: 12 }} />
    <Typography style={{ fontSize: 14 }}>{title}</Typography>
  </div>
)

const EmbedModal = () => {
  const classes = useStyles()

  const [success, setSuccess] = useState(false)

  const dispatch = useDispatch()
  const isModalOpen = useSelector(selectShowDataForm)

  const handleSuccess = () => {
    setSuccess(true)
  }

  const handleCloseModal = () => {
    dispatch(showDataForm(false))
    setSuccess(false)
  }

  return (
    <Modal open={isModalOpen} className={classes.container} onClose={handleCloseModal}>
      {success ? (
        <SuccessContent closeModal={handleCloseModal} />
      ) : (
        <Paper
          style={{
            display: "flex",
            alignItems: "center",
            margin: "64px auto",
            maxWidth: 1100,
            position: "relative",
          }}
        >
          <IconButton
            onClick={handleCloseModal}
            color="default"
            style={{ position: "absolute", top: 16, right: 16 }}
          >
            <CloseRoundedIcon />
          </IconButton>
          <div style={{ padding: "64px 0 64px 64px" }}>
            <div style={{ maxWidth: 480 }}>
              <Typography
                variant="h1"
                style={{ fontSize: 30.4, fontWeight: 700, whiteSpace: "nowrap" }}
              >
                DOWNLOAD RAVENPACK DATA
              </Typography>
              <Typography style={{ fontSize: 14, marginTop: 20 }}>
                Download all data displayed on RavenPack’s Coronavirus Media Monitor for your own
                use in analysis and projects. For media inquiries or external citation questions,
                please email{" "}
                <Link href="mailto:insights@ravenpack.com">insights@ravenpack.com</Link>.
              </Typography>
              <Typography variant="h2" style={{ fontSize: 18, fontWeight: 700, marginTop: 40 }}>
                What’s included
              </Typography>
              <Typography style={{ fontSize: 14 }}>
                Download all data available in the monitor since Jan 1, 2020 up to date at worldwide
                and country level. Data is available daily and includes:
              </Typography>
              <div style={{ margin: "12px 0", paddingLeft: 16 }}>
                {points.map(p => (
                  <Point key={p.title} {...p} />
                ))}
              </div>
              <Typography style={{ fontSize: 13, color: "#7F7F7F", marginTop: 40 }}>
                This RavenPack data is made available to you for free and under a{" "}
                <Link
                  href="https://creativecommons.org/licenses/by/4.0/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  Creative Commons Attribution 4.0 License
                </Link>
                . When the data is used, you must provide attribution to RavenPack's Coronavirus
                Media Monitor and include a link to this site.
              </Typography>
            </div>
          </div>
          <div style={{ padding: 64 }}>
            <DataRequestForm onSuccess={handleSuccess} />
          </div>
        </Paper>
      )}
    </Modal>
  )
}

export default EmbedModal
