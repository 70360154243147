import { withStyles } from "@material-ui/core"
import GenericModal from "./Modal.view"

const styles = theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "90vw",
    maxWidth: 1400,
    margin: "0 auto",
    outline: "none",
    border: "solid 2px #404040",
    [theme.breakpoints.down("sm")]: {
      background: "#141414 !important",
    },
  },
  fullscreenPaper: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      background: "#141414 !important",
    },
  },
  content: {
    [theme.breakpoints.down("sm")]: {
      padding:
        "20px env(safe-area-inset-right, 20px) env(safe-area-inset-bottom, 20px) env(safe-area-inset-left, 20px)",
    },
  },
})

export default withStyles(styles)(GenericModal)
