import { Typography } from "@material-ui/core"
import NewsFeed from "../NewsFeed/NewsFeed"
import { useSelector } from "react-redux"
import { selectIsLoadingStories, selectStories } from "../../redux/stories.duck"
import useStories from "../../hooks/useStories"
import { byTimestamp } from "common/utils/sorts"
import { selectIsMobile, selectSelectedCountry } from "../../redux/userInterface.duck"
import React from "react"

const ModalNewsFeed = ({ stories, loading }) => {
  const isMobile = useSelector(selectIsMobile)
  return (
    <div
      style={{
        width: isMobile ? "auto" : "40%",
        marginLeft: isMobile ? 20 : 25,
        marginRight: isMobile ? 20 : 0,
        marginTop: isMobile ? 20 : 0,
        marginBottom: isMobile ? 20 : 0,
        height: isMobile ? 500 : 517,
      }}
    >
      <Typography style={{ fontSize: 24, fontWeight: 700 }}>Related News</Typography>
      <NewsFeed
        stories={stories}
        loading={loading}
        height="calc(100% - 36px)"
        emptyMessage={country =>
          `No recent headlines available for this related topic and ${country}`
        }
      />
    </div>
  )
}

export const DefaultNewsFeed = () => {
  const loading = useSelector(selectIsLoadingStories)
  const stories = Object.values(useSelector(selectStories)).sort(byTimestamp)

  return <ModalNewsFeed stories={stories} loading={loading} />
}

export const TopicNewsFeed = ({ topic }) => {
  const selectedCountry = useSelector(selectSelectedCountry)
  const url = selectedCountry
    ? `news/country/${selectedCountry}/topic/${topic}_news.json`
    : `news/topic/${topic}_news.json`
  const [stories, loading] = useStories(url)

  return <ModalNewsFeed stories={stories} loading={loading} />
}
