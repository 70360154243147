const LETTERS = "abcdefghijklmnopqrstuvwxyz"

const range = n => [...Array(n).keys()]

class WordSuggester {
  constructor(wordList = []) {
    this.updateWordList(wordList)
  }

  updateWordList(wordList) {
    this.wordList = wordList.map(x => x.toLowerCase())
  }

  splitWord(word) {
    return range(word.length + 1).map(x => [word.slice(0, x), word.slice(x)])
  }

  edits1(word) {
    const parts = this.splitWord(word.toLowerCase())
    const deletes = parts.map(([L, R]) => [L, R.slice(1)].join(""))
    const transposes = parts.map(([L, R]) => [L, R[1], R[0], R.slice(2)].join(""))
    const replaces = parts.flatMap(([L, R]) => {
      return LETTERS.split("").map(m => [L, m, R.slice(1)].join(""))
    })
    const inserts = parts.flatMap(([L, R]) => {
      return LETTERS.split("").map(m => [L, m, R].join(""))
    })

    return new Set([...deletes, ...transposes, ...replaces, ...inserts])
  }

  edits2(word) {
    return new Set([...this.edits1(word)].flatMap(r => [...this.edits1(r)]))
  }

  candidates(word) {
    const singleEdits = this.edits1(word)
    const doubleEdits = this.edits2(word)
    return this.wordList.filter(name => singleEdits.has(name) || doubleEdits.has(name))
  }

  check(word) {
    return this.candidates(word)[0]
  }
}

export default WordSuggester
