import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Menu, MenuItem, Typography } from "@material-ui/core"
import { useDispatch } from "react-redux"
import cx from "classnames"
import DropdownIcon from "../../DropdownIcon"
import { setScale } from "../../../redux/modal.duck"
import useQueryParams from "common/hooks/useQueryParams"

const useStyles = makeStyles({
  container: {
    borderRadius: 4,
    width: "40%",
    maxWidth: 195,
    background: "#232523",
    height: 40,
    border: "solid 1px #7F7F7F",
    padding: "0px 10px",
    display: "flex",
    alignItems: "center",
    color: "#C0C0C0",
    cursor: "pointer",
  },
  icon: {
    marginLeft: "auto",
    fill: "#7F7F7F",
    width: 24,
    height: 24,
  },
  menu: {},
  menuItem: {
    fontSize: 16,
    lineHeight: "19px",
    color: "#C0C0C0",
  },
  menuItemActive: {
    color: "#FFFFFF",
    opacity: "1 !important",
  },
  title: {
    fontSize: 12,
    paddingBottom: 0,
  },
})

const scaleMap = {
  linear: "Linear Scale",
  log: "Logarithmic Scale",
}

const ScaleSelector = ({ value, primaryIndex, secondaryIndex, onChange }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState()
  const { queryParams, setQueryParams } = useQueryParams()

  const handleOpen = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(undefined)
  const handleSelect = val => event => {
    setQueryParams({ ...queryParams, s: val })
    if (onChange) {
      onChange(val)
    } else {
      dispatch(setScale(val))
    }
    handleClose(event)
  }
  return (
    <>
      <div className={classes.container} onClick={handleOpen}>
        <Typography>{scaleMap[value]}</Typography>
        <DropdownIcon className={classes.icon} width={24} />
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        getContentAnchorEl={null}
      >
        {["linear", "log"].map(v => {
          const logDisabled = v === "log" && primaryIndex === "sent" && secondaryIndex === undefined
          const itemClasses = cx({
            [classes.menuItem]: true,
            [classes.menuItemActive]: value === v,
          })
          return (
            <span
              title={logDisabled ? "Logarithmic scale is unavailable for this single index" : ""}
              key={`scale-${v}`}
            >
              <MenuItem
                onClick={handleSelect(v)}
                disabled={v === value || logDisabled}
                className={itemClasses}
              >
                {scaleMap[v]}
              </MenuItem>
            </span>
          )
        })}
      </Menu>
    </>
  )
}

export default ScaleSelector
