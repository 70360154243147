import React, { useEffect, useRef } from "react"
import { Typography, withStyles } from "@material-ui/core"
import { useSelector } from "react-redux"
import StoryCard from "./StoryCard"
import StoryCardSkeleton from "./StoryCardSkeleton"
import { selectSelectedCountryName } from "../../redux/countriesData.duck"
import { selectIsMobile } from "../../redux/userInterface.duck"

const styles = {
  storyContainer: {
    overflowX: "hidden",
    overflowY: "auto",
    position: "relative",
    flexGrow: 1,
    flexShrink: 1,
    paddingRight: 10,
    "&::-webkit-scrollbar": {
      width: "0.5em",
    },
    "&::-webkit-scrollbar-thumb": {
      maxHeight: 10,
      borderRadius: 5,
      backgroundColor: "#d7d7d7",
    },
  },
  storyLink: {
    textDecoration: "none",
  },
}

const NewsFeed = ({ stories, classes, loading, height, emptyMessage }) => {
  const isMobile = useSelector(selectIsMobile)

  const selectedCountry = useSelector(selectSelectedCountryName)
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0
    }
  }, [ref, selectedCountry])

  return (
    <div ref={ref} className={classes.storyContainer} style={{ maxHeight: height }}>
      {!loading ? (
        stories.length > 0 ? (
          stories.map(s => (
            <a
              href={s.url}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.storyLink}
              key={s.id}
            >
              <StoryCard
                headline={s.headline}
                timestamp={s.ts}
                source={s.src}
                showTooltip={!isMobile}
              />
            </a>
          ))
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography style={{ color: "#C0C0C0", margin: "10px 0", fontSize: 16 }}>
              {emptyMessage(selectedCountry)}
            </Typography>
          </div>
        )
      ) : (
        [0, 1, 2, 3, 4].map(n => <StoryCardSkeleton key={`newsFeedSkeleton${n}`} />)
      )}
    </div>
  )
}

export default withStyles(styles)(NewsFeed)
