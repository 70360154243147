import React from "react"
// Materia-UI
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import Table from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import CardContent from "@material-ui/core/CardContent"
import CardHeader from "@material-ui/core/CardHeader"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import { useTheme } from "@material-ui/core/styles"
// Redux
import { useSelector } from "react-redux"
import { selectLastUpdated } from "../../redux/stories.duck"
// Utils
import moment from "moment"
import numericDisplay from "../../utils/displayPercentage"

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 220,
    background: theme.palette.background.tooltip,
    border: `solid 1px ${theme.palette.background.outline}`,
    marginBottom: 12,
  },
  divider: {
    marginTop: 5,
    marginBottom: 5,
  },
  cell: {
    padding: 1,
    margin: "0 5px",
    borderBottom: "none",
    fontSize: 14,
    minWidth: 60,
  },
  tableHeader: {
    color: "#7F7F7F",
    fontSize: 12,
    textTransform: "uppercase",
  },
  headerRoot: {
    padding: "6px 16px 0px",
  },
  title: {
    flexGrow: 1,
    marginRight: 8,
    fontSize: 20,
  },
  valueDeltaContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  valueDelta: {
    fontSize: 20,
  },
  sectionHeader: {
    color: "#7F7F7F",
    fontSize: 14,
    paddingBottom: 0,
  },
}))

export default function MapInfoCard({ data, type }) {
  const classes = useStyles()
  const theme = useTheme()

  const lastUpdated = useSelector(selectLastUpdated)

  // Wait for the data to load
  if (!data || !data.country) return null

  const { value, change, rows = [], mediaAnalytics = [], showSubheader, description } =
    data[type] || {}

  // Don't show them if they don't exist
  const filteredMediaAnalytics = mediaAnalytics.filter(row => row.value)

  const calcColor = num => {
    if (num < 0) return "#CF2D00"
    if (num > 0) return theme.palette.secondary.main
    return "inherit"
  }

  const singleRow = (row, i) => {
    const valueDecimalPlaces = ["Country Sentiment", "Media Exposure"].includes(row.label) ? 2 : 0
    return (
      <TableRow className={classes.tableRow} key={i}>
        <TableCell className={classes.cell} style={{ minWidth: 120 }} component="th" scope="row">
          {row.label}
        </TableCell>
        <TableCell className={classes.cell} style={{ fontWeight: 700 }} align="right">
          {numericDisplay(row.value, false, false, valueDecimalPlaces)}
        </TableCell>
        <TableCell
          className={classes.cell}
          style={{ color: calcColor(row.change), fontWeight: 700 }}
          align="right"
        >
          {row.change || row.change === 0 ? numericDisplay(row.change, true, false, 1) : ""}
        </TableCell>
      </TableRow>
    )
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        title={data.country}
        subheader={
          showSubheader &&
          description && <Typography className={classes.sectionHeader}>{description}</Typography>
        }
        classes={{
          root: classes.headerRoot,
          content: classes.headerContent,
          title: classes.title,
        }}
      />
      <Divider />
      <CardContent style={{ padding: "6px 16px" }}>
        {showSubheader ? (
          <div className={classes.valueDeltaContainer}>
            <Typography className={classes.valueDelta}>{value}</Typography>
            <Typography
              className={classes.valueDelta}
              style={{
                color: calcColor(change),
              }}
            >
              {numericDisplay(change, true)}
            </Typography>
          </div>
        ) : (
          <Table>
            {rows.length ? (
              <TableHead>
                <TableRow>
                  <TableCell className={`${classes.tableHeader} ${classes.cell}`}></TableCell>
                  <TableCell className={`${classes.tableHeader} ${classes.cell}`} align="right">
                    {moment(lastUpdated).format("MMM DD")}
                  </TableCell>
                  <TableCell className={`${classes.tableHeader} ${classes.cell}`} align="right">
                    1D CHG
                  </TableCell>
                </TableRow>
              </TableHead>
            ) : null}
            <TableBody>
              {rows.map((row, i) => singleRow(row, i))}
              {!rows.length && !showSubheader && (
                <Typography className={classes.sectionHeader}>No Data Available</Typography>
              )}
              <TableRow className={classes.tableRow} style={{ height: 15 }}></TableRow>
              {filteredMediaAnalytics[0] &&
                filteredMediaAnalytics.map((row, i) => singleRow(row, i))}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  )
}
