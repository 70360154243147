import React, { forwardRef } from "react"
import PropTypes from "proptypes"
import { ReactSVG } from "react-svg"

const Icon = forwardRef(({ src, width, height, beforeInjection, square, ...props }, ref) => (
  <ReactSVG
    ref={ref}
    src={src}
    beforeInjection={svg => {
      if (width) {
        svg.setAttribute("style", `width: ${width}px; ${square ? `height: ${width}px` : ""}`)
      }

      if (height) {
        svg.setAttribute("style", `height: ${height}px; ${square ? `width: ${height}px` : ""}`)
      }
      beforeInjection(svg)
    }}
    {...props}
  />
))

Icon.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  beforeInjection: PropTypes.func,
}

Icon.defaultProps = {
  width: undefined,
  height: undefined,
  beforeInjection: f => f,
}

export default React.memo(Icon)
