import React from "react"
import Icon from "common/components/Icon"
import logoRight from "../../assets/logos/media-monitor-logo.svg"
import logoLeft from "../../assets/logos/coronavirus-logo.svg"

const SiteLogo = ({ isMobile, style }) => {
  const height = isMobile ? 30 : 42
  const margin = isMobile ? 6 : 16
  return (
    <div style={{ display: "flex", alignItems: "center", ...style }}>
      <a href="/">
        <Icon
          src={logoLeft}
          height={height}
          width={isMobile ? "auto" : 210}
          style={{ height, width: isMobile ? "auto" : 210, marginRight: margin }}
        />
      </a>
      <div style={{ height, borderRight: "1px solid #7f7f7f" }} />
      <a
        href="https://www.ravenpack.com/page/coronavirus-insights/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon
          src={logoRight}
          height={height}
          width={isMobile ? "auto" : 147}
          style={{ height, width: isMobile ? "auto" : 147, marginLeft: margin }}
        />
      </a>
    </div>
  )
}

export default SiteLogo
