import { createMuiTheme } from "@material-ui/core/styles"

export default theme =>
  createMuiTheme({
    ...theme,
    palette: {
      ...theme.palette,
      altLine: { main: "#45AAE6" },
    },
  })
