import { createAction, createReducer } from "@reduxjs/toolkit"
import { pathToRegexp } from "path-to-regexp"
import { clearCountryStories, getCountryStories } from "./stories.duck"
import {
  getCountryHistoricCases,
  selectIsLoadingCountryData,
  singleCountryData,
} from "./countriesData.duck"
import { clearCountryRelatedTopics, getCountryRelatedTopics } from "./relatedTopics.duck"
import { clearCountryIndexes, getDailyCountryData, getHourlyCountryData } from "./indexes.duck"
import { isoToUrlName, urlNameToIso } from "../constants"

const getCountryFromPath = () => {
  const { pathname: path } = window.location
  const regexp = pathToRegexp("/:country/:something?")
  const result = regexp.exec(path)
  const country = result ? result[1].toLowerCase() : undefined
  // convert country name to ISO2
  return urlNameToIso[country]
}

const initialState = {
  cookiesAccepted: localStorage.getItem("cookiesAccepted") === "true",
  electionDismissed: localStorage.getItem("electionDismissed") === "true",
  seenIntro: localStorage.getItem("seenIntro") === "true",
  showDataForm: false,
  selectedCountry: getCountryFromPath(),
  mapPosition: { coordinates: [10, 10], zoom: 1.15 },
  mapInfoCard: {},
  showMap: true,
  mapTabIndex: 0,
  intervalIds: [],
  mediaExposureTab: 0,
  isMobile: window.innerWidth < 960,
  comparisonCountry: null,
}

export const acceptCookies = createAction("ACCEPT_COOKIES")
export const dismissElection = createAction("DISMISS_ELECTION")
export const seenIntro = createAction("SEEN_INTRO")
export const showIntro = createAction("SHOW_INTRO")
export const showDataForm = createAction("SHOW_DATA_FORM")
export const setSelectedCountry = createAction("SET_COUNTRY_CODE")
export const setMapPosition = createAction("SET_MAP_POSITION")
export const setMapInfoCard = createAction("SET_MAP_INFO_CARD")
export const setShowMap = createAction("SET_SHOW_MAP")
export const setCountryIntervalIds = createAction("SET_COUNTRY_INTERVAL_ID")
export const setMediaExposureTab = createAction("SET_MEDIA_EXPOSURE_TAB")
export const setIsMobile = createAction("SET_IS_MOBILE")
export const setComparisonCountry = createAction("SET_COMPARISON_COUNTRY")
export const setMapTabIndex = createAction("SET_MAP_TAB_INDEX")

// Thunks
export const selectCountry = country => (dispatch, getState) => {
  const state = getState()
  const countryName = isoToUrlName[country]
  window.history.pushState({}, null, countryName ? `/${countryName}` : "/")
  const { intervalIds } = state.ui

  if (intervalIds.length) {
    intervalIds.forEach(id => clearInterval(id))
  }

  dispatch(clearCountryStories())
  dispatch(clearCountryRelatedTopics())
  dispatch(clearCountryIndexes())
  dispatch(setMediaExposureTab(0))

  if (country !== undefined) {
    dispatch(getCountryStories(country))
    dispatch(getCountryHistoricCases(country))
    dispatch(getCountryRelatedTopics(country))
    dispatch(getDailyCountryData({ country, type: "fake" }))
    dispatch(getDailyCountryData({ country, type: "sent" }))
    dispatch(getDailyCountryData({ country, type: "hype" }))
    dispatch(getDailyCountryData({ country, type: "infodemic" }))
    dispatch(getDailyCountryData({ country, type: "media" }))
    dispatch(getDailyCountryData({ country, type: "panic" }))
    dispatch(getHourlyCountryData(country))

    const storyInterval = setInterval(() => dispatch(getCountryStories(country)), 1000 * 60)

    const indexInterval = setInterval(() => {
      dispatch(getDailyCountryData({ country, type: "fake" }))
      dispatch(getDailyCountryData({ country, type: "sent" }))
      dispatch(getDailyCountryData({ country, type: "hype" }))
      dispatch(getDailyCountryData({ country, type: "infodemic" }))
      dispatch(getDailyCountryData({ country, type: "media" }))
      dispatch(getDailyCountryData({ country, type: "panic" }))
      dispatch(getHourlyCountryData(country))
      dispatch(getCountryHistoricCases(country))
    }, 1000 * 60 * 5)

    const dataInterval = setInterval(() => {
      dispatch(getCountryRelatedTopics(country))
      dispatch(getHourlyCountryData(country))
    }, 1000 * 60 * 10)

    dispatch(setCountryIntervalIds([storyInterval, indexInterval, dataInterval]))
  } else {
    dispatch(setCountryIntervalIds([]))
  }

  dispatch(setMapInfoCard(singleCountryData(country, state)))
  dispatch(setSelectedCountry(country))
}

const reducer = createReducer(initialState, {
  [acceptCookies]: state => {
    state.cookiesAccepted = true
  },
  [dismissElection]: state => {
    state.electionDismissed = true
  },
  [seenIntro]: state => {
    state.seenIntro = true
  },
  [showIntro]: state => {
    state.seenIntro = false
  },
  [showDataForm]: (state, action) => {
    state.showDataForm = action.payload
  },
  [setSelectedCountry]: (state, action) => {
    state.selectedCountry = action.payload
  },
  [setMapPosition]: (state, action) => {
    if (action.payload === undefined) {
      state.mapPosition = initialState.mapPosition
      return
    }
    state.mapPosition = action.payload
  },
  [setMapInfoCard]: (state, action) => {
    state.mapInfoCard = action.payload
  },
  [setShowMap]: (state, action) => {
    state.showMap = action.payload
  },
  [setCountryIntervalIds]: (state, action) => {
    state.intervalIds = action.payload
  },
  [setMediaExposureTab]: (state, action) => {
    state.mediaExposureTab = action.payload
  },
  [setIsMobile]: (state, action) => {
    state.isMobile = action.payload
  },
  [setComparisonCountry]: (state, action) => {
    state.comparisonCountry = action.payload
  },
  [setMapTabIndex]: (state, action) => {
    state.mapTabIndex = action.payload
  },
})

export const selectCookiesAccepted = state => state.ui.cookiesAccepted
export const selectElectionDismissed = state => state.ui.electionDismissed
export const selectSeenIntro = state => state.ui.seenIntro
export const selectShowDataForm = state => state.ui.showDataForm
export const selectSelectedCountry = state => state.ui.selectedCountry
export const selectMapPosition = state => state.ui.mapPosition
export const selectMapInfoCard = state => state.ui.mapInfoCard
export const selectShowMap = state => state.ui.showMap
export const selectMapTabIndex = state => state.ui.mapTabIndex
export const selectMediaExposureTab = state => state.ui.mediaExposureTab
export const selectIsMobile = state => state.ui.isMobile
export const selectComparisonCountry = state => state.ui.comparisonCountry

export const selectComparisonCountryName = state => {
  const selectedCountyCode = selectComparisonCountry(state)

  if (selectIsLoadingCountryData(state)) {
    return undefined
  }

  if (selectedCountyCode === undefined) {
    return "Worldwide"
  }

  return state.countriesData.daily.data[selectedCountyCode]
    ? state.countriesData.daily.data[selectedCountyCode].country
    : ""
}

export default reducer
