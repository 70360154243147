import React from "react"
// Components
import MapsContainer from "../Map"
import CasesTable from "../CasesTable"
import WidgetContainer from "../WidgetContainer"
// Redux
import { useDispatch, useSelector } from "react-redux"
import { setModal } from "../../redux/modal.duck"
import { selectIsMobile, selectMapTabIndex, selectShowMap } from "../../redux/userInterface.duck"
import { pathToRegexp } from "path-to-regexp"
import useQueryParams from "common/hooks/useQueryParams"

export const GeographicalExposure = ({ height, onTabChange }) => {
  const showMap = useSelector(selectShowMap)
  return showMap ? (
    <MapsContainer height={height || 300} onTabChange={onTabChange} />
  ) : (
    <CasesTable height={height || 348} />
  )
}

export const TAB_INDEX_MAP = {
  0: "cases",
  1: "sent",
  2: "media",
}

const GeographicalExposure_Wrapper = () => {
  const dispatch = useDispatch()
  const isMobile = useSelector(selectIsMobile)
  const showMap = useSelector(selectShowMap)
  const tabIndex = useSelector(selectMapTabIndex)

  const { queryParams, encodeQueryParams } = useQueryParams()

  const showModal = () => {
    const { pathname: path } = window.location
    const regexp = pathToRegexp("/:country(.*)")
    const result = regexp.exec(path)

    const country = result !== null ? result[1] : undefined
    const urlCountry = country || "worldwide"

    window.history.replaceState(
      {},
      null,
      `/${urlCountry}/geo-exposure${encodeQueryParams({
        ...queryParams,
        ...(!showMap && { v: "table" }),
        ...(tabIndex && tabIndex !== 0 && { i: TAB_INDEX_MAP[tabIndex] }),
      })}`,
    )

    dispatch(
      setModal({
        title: "Geographical Exposure",
        description: "Description",
        type: "geoExp",
        data: {},
      }),
    )
  }

  return (
    <WidgetContainer
      title="Geographical Exposure"
      description="The map shows the geographical impact of COVID-19 and shows confirmed cases along with sentiment, media hype, and total news volume by country."
      label="Shows the geographical impact of COVID-19"
      source="Worldometer"
      openModal={showModal}
      showToggleView
      style={isMobile ? {} : { height: 430 }}
    >
      {GeographicalExposure({})}
    </WidgetContainer>
  )
}

export default GeographicalExposure_Wrapper
