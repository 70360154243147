export const byKey = (key, reverse = false, defaultValue) => (a, b) => {
  let aValue = a[key]
  if (defaultValue !== undefined && (aValue === undefined || aValue === null)) {
    aValue = defaultValue
  }

  let bValue = b[key]
  if (defaultValue !== undefined && (bValue === undefined || bValue === null)) {
    bValue = defaultValue
  }

  if (aValue < bValue) {
    return reverse ? -1 : 1
  }
  if (aValue > bValue) {
    return reverse ? 1 : -1
  }
  return 0
}

export const byTimestamp = (...props) => byKey("ts", ...props)
