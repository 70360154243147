import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import { Typography, Fade } from "@material-ui/core"
import Tooltip from "@material-ui/core/Tooltip"
import WidgetErrorBoundary from "./WidgetErrorBoundary"
import Icon from "../Icon"
import FullscreenIcon from "./FullscreenIcon"
import InfoIcon from "../../assets/info_icon.svg"
import ToggleView from "../GeographicalExposure/ToggleView"
import useMobile from "../../hooks/useMobie"

const useStyles = makeStyles({
  container: {
    margin: "10px 0",
    padding: "7px 16px 5px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  titleContainer: {
    width: "100%",
    borderBottom: "solid 1px #555",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 7,
  },
  title: {
    fontWeight: 900,
    fontSize: 16,
  },
  helpIcon: {
    marginLeft: 5,
    fill: "#808080",
    height: 22,
    width: 22,
  },
  labelGroup: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "baseline",
    justifyContent: "space-between",
  },
  label: {
    color: "#C0C0C0",
    fontSize: 12,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
  },
})

const useTooltipContentStyles = makeStyles({
  title: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 10,
  },
  body: {
    fontSize: 14,
    color: "#E1E1E1",
  },
})

const TooltipContent = ({ title, description }) => {
  const classes = useTooltipContentStyles()

  if (!description) {
    return null
  }

  return (
    <div>
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="body1" className={classes.body}>
        {description}
      </Typography>
    </div>
  )
}

const WidgetContainer = ({
  title,
  tooltipTitle,
  description,
  label,
  source,
  children,
  openModal,
  showToggleView,
  growToFit,
  ...props
}) => {
  const classes = useStyles()
  const [hover, setHover] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const { isMobile } = useMobile()

  const handleMouseEnter = () => setHover(true)
  const handleMouseLeave = () => {
    setTooltipOpen(false)
    setHover(false)
  }

  const handleTooltipOpen = () => {
    setTooltipOpen(true)
  }

  const handleTooltipClose = () => {
    setTooltipOpen(false)
  }

  return (
    <Paper
      className={classes.container}
      elevation={3}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={growToFit ? { flexGrow: 1 } : {}}
      {...props}
    >
      <div className={classes.titleContainer}>
        <span style={{ display: "flex", alignItems: "center" }} onClick={handleTooltipOpen}>
          <Typography className={classes.title}>{title}</Typography>
          {description && (
            <Fade in={hover || isMobile} timeout={150}>
              <Tooltip
                title={<TooltipContent title={tooltipTitle || title} description={description} />}
                open={tooltipOpen}
                placement={isMobile ? "top" : "right-end"}
                onOpen={handleTooltipOpen}
                onClose={handleTooltipClose}
                arrow
              >
                <Icon src={InfoIcon} className={classes.helpIcon} />
              </Tooltip>
            </Fade>
          )}
        </span>
        <div className={classes.buttonContainer}>
          {showToggleView && <ToggleView />}
          {!!openModal && !isMobile && <FullscreenIcon onClick={openModal} />}
        </div>
      </div>
      <WidgetErrorBoundary>{children}</WidgetErrorBoundary>
      <div className={classes.labelGroup}>
        <Typography className={classes.label}>{label}</Typography>
      </div>
    </Paper>
  )
}

export default WidgetContainer
