import React from "react"
import Table from "./ReactTable.view"
// Redux
import { useSelector } from "react-redux"
import { selectCasesTableDataV2 } from "../../redux/countriesData.duck"

const headerStyles = {
  textAlign: "center",
  color: "white",
  fontWeight: "700",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}

const CasesTable = ({ height }) => {
  const data = useSelector(selectCasesTableDataV2)
  const columns = React.useMemo(
    () => [
      {
        Header: " ",
        style: { padding: 0, textAlign: "left" },
        sticky: "left",
        sort: false,
        showFilterMenu: true,
        columns: [
          {
            Header: "",
            accessor: "id",
            width: 40,
            sort: false,
            style: {
              fontWeight: "bolder",
              textAlign: "center",
            },
          },
          {
            Header: "Country",
            accessor: "name",
            sortIconAfter: true,
            width: 140,
            style: {
              fontWeight: "bolder",
              textAlign: "left",
            },
          },
        ],
      },
      {
        Header: "Cases",
        style: headerStyles,
        sort: false,
        columns: [
          {
            Header: "Total",
            accessor: "cases",
          },
          {
            Header: "1D CHG",
            accessor: "casesChange",
            colorChange: true,
            percentage: true,
            decimalPlaces: 2,
            sortType: "basic",
          },
          {
            Header: "Today",
            accessor: "todayCases",
          },
        ],
      },
      {
        Header: "Active",
        style: headerStyles,
        sort: false,
        columns: [
          {
            Header: "Total",
            accessor: "active",
          },
          {
            Header: "1D CHG",
            accessor: "activeChange",
            colorChange: true,
            percentage: true,
            decimalPlaces: 2,
            sortType: "basic",
          },
        ],
      },
      {
        Header: "Recovered",
        style: headerStyles,
        sort: false,
        columns: [
          {
            Header: "Total",
            accessor: "recovered",
          },
          {
            Header: "1D CHG",
            accessor: "recoveredChange",
            colorChange: true,
            percentage: true,
            decimalPlaces: 2,
            sortType: "basic",
          },
          {
            Header: "Today",
            accessor: "todayRecovered",
          },
        ],
      },
      {
        Header: "Deaths",
        style: headerStyles,
        sort: false,
        columns: [
          {
            Header: "Total",
            accessor: "deaths",
          },
          {
            Header: "Mortality Rate",
            accessor: "mortalityRate",
            percentage: true,
            decimalPlaces: 2,
            width: 120,
            sortType: "basic",
          },
          {
            Header: "1D CHG",
            accessor: "deathsChange",
            colorChange: true,
            percentage: true,
            decimalPlaces: 2,
            sortType: "basic",
          },
          {
            Header: "Today",
            accessor: "todayDeaths",
          },
        ],
      },
      {
        Header: "Sentiment",
        style: headerStyles,
        sort: false,
        columns: [
          {
            Header: "Value",
            accessor: "sent",
            decimalPlaces: 2,
          },
          {
            Header: "1D CHG",
            accessor: "sentChange",
            colorChange: true,
            percentage: true,
            decimalPlaces: 2,
            sortType: "basic",
          },
        ],
      },
      {
        Header: "Media Exposure",
        style: headerStyles,
        sort: false,
        columns: [
          {
            Header: "Value",
            accessor: "media",
            decimalPlaces: 2,
          },
          {
            Header: "1D CHG",
            accessor: "mediaChange",
            colorChange: true,
            percentage: true,
            decimalPlaces: 2,
            sortType: "basic",
          },
        ],
      },
    ],
    [],
  )

  return (
    <Table
      columns={columns}
      data={data}
      height={height}
      getHeaderProps={column => ({})}
      getColumnProps={c => {
        const parentCols = c.parent?.columns
        return {
          style: {
            borderRight:
              !c.parent || c.Header === parentCols[parentCols.length - 1]?.Header
                ? "1px solid #404040"
                : "none",
          },
        }
      }}
      getRowProps={row => ({})}
      getCellProps={cellInfo => ({})}
    />
  )
}

export default CasesTable
