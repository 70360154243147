import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%",
    maxWidth: 630,
    marginTop: 32,
  },
  input: {
    marginBottom: ({ size }) => (size === "large" ? 16 : 8),
  },
  inputLabel: {
    color: "#f7f7f7",
  },
  termsContainer: {
    alignItems: "flex-start",
  },
  checkbox: {
    color: "#f7f7f7",
  },
  label: {
    fontSize: 14,
  },
  gdprLabel: {
    fontSize: 12,
    lineHeight: 1.15,
    padding: "9px 0 5px 0",
    color: "#7f7f7f",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  submit: {
    margin: "40px 0 0 0",
    padding: "8px 16px",
    width: 170,
    textTransform: "none",
  },
  [theme.breakpoints.down("xs")]: {
    paper: {
      padding: "40px 23px",
    },
    title: {
      fontSize: 18,
    },
    submit: {
      margin: "16px 0 0 0",
    },
    form: {
      marginTop: 12,
    },
    input: {
      marginBottom: 0,
    },
  },
}))

export default useStyles
