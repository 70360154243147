import React, { useEffect, useState } from "react"
// Packages
import PropTypes from "prop-types"
import axios from "axios"
import ReactTooltip from "react-tooltip"
// Components
import MapWidget from "./MapWidget.view"
import MapInfoCard from "./MapInfoCard"
// Material-UI
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { Skeleton } from "@material-ui/lab"
// Redux
import { useSelector, useDispatch } from "react-redux"
import { selectMapData } from "../../redux/countriesData.duck"
import {
  selectCountry,
  selectIsMobile,
  selectMapTabIndex,
  selectSelectedCountry,
  setMapTabIndex,
} from "../../redux/userInterface.duck"
// Utils
import humanizeNumber from "../../utils/humanizeNumber"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid #404040",
    zIndex: 10,
  },
  tab: {
    textTransform: "none",
    textSize: 16,
    fontWeight: "bold",
    color: "#fff",
    minWidth: 150,
  },
  SnackbarContentRoot: {
    background: "transparent",
    color: "#FFFFFF",
    boxShadow: "none",
  },
  SnackbarContentMessage: {
    width: "100%",
  },
}))

// ***************************
// Tabs
// ***************************
const TabPanel = props => {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const a11yProps = index => ({
  id: `scrollable-auto-tab-${index}`,
  "aria-controls": `scrollable-auto-tabpanel-${index}`,
})

// ***************************
// Container
// ***************************
export const MapsContainer = ({ height, onTabChange }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isMobile = useSelector(selectIsMobile)

  const mapData = useSelector(selectMapData)
  const selectedCountry = useSelector(selectSelectedCountry)

  const currentTab = useSelector(selectMapTabIndex)
  const [tooltipContent, setTooltipContent] = useState({})
  const [geoData, setGeoData] = useState(undefined)

  useEffect(() => {
    axios.get("/geoMapData.200513.json").then(r => setGeoData(r.data))
  }, [])

  const handleCountryHover = (data, geo) => {
    if (!data) {
      setTooltipContent({
        country: geo.properties.NAME,
        isocode: geo.properties.ISO_A2,
      })
      return
    }
    setTooltipContent(data)
  }

  const handleCountryClicked = (data, geo) => {
    if (!data) {
      dispatch(selectCountry(undefined))
      geo &&
        setTooltipContent({
          country: geo.properties.NAME,
          isocode: geo.properties.ISO_A2,
        })
      return
    }
    dispatch(selectCountry(data.isocode))
    setTooltipContent(data)
  }

  const handleChange = (event, newValue) => {
    if (onTabChange) {
      onTabChange(newValue)
    }
    dispatch(setMapTabIndex(newValue))
  }

  const roundedHighestCases = num => {
    if (!num && !mapData.length) return "-"
    const maxCases = num || mapData[0].cases.rows[0].value
    const toTheNearest = maxCases < 100000 ? 1000 : 50000
    const rounded = Math.ceil(maxCases / toTheNearest) * toTheNearest
    return humanizeNumber(rounded)
  }

  const maps = [
    {
      label: "Confirmed Cases",
      type: "cases",
      options: {
        colors: ["#A0A0A0", "#E0BD9B", "#E59E58", "#E68445", "#D16328", "#C7450C", "#AA1300 "],
        domain: [0, 6], // Upper and lower bounds
        scaleDisplay: [0, roundedHighestCases()],
      },
    },
    {
      label: "Sentiment",
      type: "sent",
      options: {
        colors: ["#C11A13", "#EB726D", "#A0A0A0", "#8CC9BA", "#008B68"],
        domain: [-100, 100], // Upper and lower bounds
      },
    },
    {
      label: "Media Exposure",
      type: "media",
      options: {
        colors: ["#A0A0A0", "#89B9C4", "#3AACC7", "#005988"],
        domain: [0, 20], // Upper and lower bounds
        scaleDisplay: [0, "20+"],
      },
    },
  ]

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {/* Tabs */}
          {maps.map((t, i) => (
            <Tab
              key={i}
              label={t.label}
              disabled={t.disabled}
              {...a11yProps(i)}
              className={classes.tab}
            />
          ))}
        </Tabs>
      </AppBar>
      {/* maps */}
      {geoData ? (
        maps.map((map, i) => (
          <TabPanel key={i} value={currentTab} index={i} style={{ position: "relative" }}>
            <MapWidget
              data={mapData}
              type={map.type}
              {...map.options}
              index={i}
              tooltipContent={tooltipContent}
              setTooltipContent={setTooltipContent}
              handleCountryHover={handleCountryHover}
              handleCountryClicked={handleCountryClicked}
              geoData={geoData}
              height={height}
              selectedCountry={selectedCountry}
            />
            <ReactTooltip backgroundColor="transparent" place="bottom">
              {!isMobile && <MapInfoCard data={tooltipContent} type={map.type} />}
            </ReactTooltip>
          </TabPanel>
        ))
      ) : (
        <Skeleton variant="rect" animation="wave" height={height + 7} />
      )}
    </div>
  )
}

export default MapsContainer
