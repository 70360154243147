import { createSelector } from "@reduxjs/toolkit"
import createFetchReducer from "common/state/createFetch"
import loadingStates from "common/state/loadingStates"
import APIClient from "../APIClient"

const slice = createFetchReducer(
  {
    name: "COMPARISON_DATA",
    url: ({ country, index, horizon }) => {
      let filename
      if (index.startsWith("total_")) {
        filename = "cases"
      } else {
        filename = ["1D", "1W"].includes(horizon) ? "hourly" : index
      }

      return country === undefined ? `/${filename}.json` : `/country/${country}/${filename}.json`
    },
    path: "comparison",
    handleData: (data, meta) => {
      return data
        .map(x => ({ ts: x.ts, value: x[meta.arg.index] }))
        .sort((a, b) => {
          if (a.ts < b.ts) {
            return -1
          }
          if (a.ts > b.ts) {
            return 1
          }
          return 0
        })
    },
  },
  APIClient,
)

export const fetchComparisonData = slice.actions.fetch
export const clearComparisonData = slice.actions.clear

const { reducer } = slice

export const selectSecondaryData = slice.selectors.data
export const selectComparisonIsLoading = createSelector(
  [slice.selectors.loadingValue],
  loadingValue => loadingValue !== loadingStates.IDLE,
)

export const selectComparisonLastUpdated = slice.selectors.lastUpdated

export default reducer
