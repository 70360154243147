import React from "react"
import { useSelector } from "react-redux"
import {
  selectInfodemicData,
  selectDocsData,
  selectFakeData,
  selectHypeData,
  selectIsLoadingInfodemic,
  selectIsLoadingFake,
  selectIsLoadingHype,
  selectIsLoadingMedia,
  selectIsLoadingPanic,
  selectIsLoadingSent,
  selectPanicData,
  selectSentData,
} from "../../redux/indexes.duck"
import GenericChartWidget from "./GenericChartWidget"
import { selectSelectedCountry } from "../../redux/userInterface.duck"

export const HypeWidget = () => (
  <GenericChartWidget
    widgetInfo={{
      title: "Media Hype Index",
      tooltipTitle: "Coronavirus Media Hype Index",
      description:
        "The Coronavirus Media Hype Index measures the percentage of news talking about the novel coronavirus. Values range between 0 and 100 where a value of 75.00 indicates that 75 percent of all news globally is talking about COVID-19.",
      label: "The % of total news about the Coronavirus",
    }}
    selector={selectHypeData}
    isDailyLoading={useSelector(selectIsLoadingHype)}
    index="hype"
    percentage
  />
)

export const MediaCoverageWidget = () => (
  <GenericChartWidget
    widgetInfo={{
      title: "Media Coverage Index",
      tooltipTitle: "Coronavirus Media Coverage Index",
      description:
        "The Coronavirus Media Coverage Index calculates the percentage of all news sources covering the topic of the novel coronavirus. Values range between 0 and 100 where a value of 60.00 means that 60 percent of all sampled news providers are currently covering stories about the COVID-19.",
      label: "The % of all news sources covering the Coronavirus",
    }}
    selector={selectDocsData}
    isDailyLoading={useSelector(selectIsLoadingMedia)}
    index="media"
    percentage
  />
)

export const PanicWidget = () => (
  <GenericChartWidget
    widgetInfo={{
      title: "Panic Index",
      tooltipTitle: "Coronavirus Panic Index",
      description:
        "The Coronavirus Panic Index measures the level of news chatter that makes reference to panic or hysteria and coronavirus. Values range between 0 and 100 where a value of 7.00 indicates that 7 percent of all news globally is talking about panic and COVID-19. The higher the index value, the more references to panic found in the media.",
      label: "The % of news about panic and the Coronavirus",
    }}
    selector={selectPanicData}
    isDailyLoading={useSelector(selectIsLoadingPanic)}
    index="panic"
    percentage
  />
)

export const InfodemicWidget = () => (
  <GenericChartWidget
    widgetInfo={{
      title: "Infodemic Index",
      tooltipTitle: "Coronavirus Infodemic Index",
      description:
        "The Coronavirus Infodemic Index calculates the percentage of all entities (places, companies, etc.) that are somehow linked to COVID-19. Values range between 0 and 100 where a value of 60.00 means that 60 percent of all entities covered by the media are being linked or co-mentioned with COVID-19.",
      label: "The % of entities in the news linked to the coronavirus",
    }}
    selector={selectInfodemicData}
    isDailyLoading={useSelector(selectIsLoadingInfodemic)}
    index="infodemic"
    percentage
  />
)

export const FakeNewsWidget = () => (
  <GenericChartWidget
    widgetInfo={{
      title: "Fake News Index",
      tooltipTitle: "Coronavirus Fake News Index",
      description:
        "The Coronavirus Fake News Index measures the level of media chatter about the novel virus that makes reference to misinformation or fake news alongside COVID-19. Values range between 0 and 100 where a value of 2.00 indicates that 2 percent of all news globally is talking about fake news and COVID-19. The higher the index value, the more references to fake news found in the media.",
      label: "The % of news that links the Coronavirus to fake news",
    }}
    selector={selectFakeData}
    isDailyLoading={useSelector(selectIsLoadingFake)}
    index="fake"
    percentage
  />
)

export const GlobalSentimentWidget = () => {
  const isCountrySelected = useSelector(selectSelectedCountry) !== undefined
  return (
    <GenericChartWidget
      widgetInfo={{
        title: isCountrySelected ? "Country Sentiment Index" : "Worldwide Sentiment Index",
        tooltipTitle: "Coronavirus Worldwide Sentiment Index",
        description:
          "The Coronavirus Sentiment Index measures the level of sentiment across all entities mentioned in the news alongside the coronavirus. The index ranges between -100 and 100 where a value of 100 is the most positive sentiment, -100 is the most negative, and 0 is neutral.",
        label: "The level of sentiment from all news about the Coronavirus",
        data: useSelector(selectSentData),
      }}
      selector={selectSentData}
      isDailyLoading={useSelector(selectIsLoadingSent)}
      index="sent"
    />
  )
}
