import { withStyles } from "@material-ui/core"
import IndexTableView from "./IndexTable.view"

import HoverIcon from "../../assets/fullscreen-table.svg"

const styles = theme => ({
  tableBodyContainer: {
    outline: "none",
    "&::-webkit-scrollbar": {
      width: 5,
      paddingLeft: 5,
      marginRight: 5,
    },
    "&::-webkit-scrollbar-thumb": {
      maxHeight: 10,
      borderRadius: 2,
      backgroundColor: "#d7d7d7",
    },
  },
  tableBody: {
    height: 100,
    "& tr:nth-child(odd)": {
      backgroundColor: "#202020",
    },
  },
  tableRow: {
    outline: "none",
    "&:nth-child(odd):not([data-type=header])": {
      background: "#202020",
    },
    "&:hover:not([data-type=header])": {
      background: "rgba(255, 255, 255, 0.1)",
    },
    "&:hover div[data-column=entity]": {
      color: props =>
        props.highlightOnHover ? `${theme.palette.primary.main} !important` : undefined,
      "&::after": {
        content: props => (props.highlightOnHover ? `url(${HoverIcon})` : ""),
        maskSize: "cover",
        position: "absolute",
        height: 20,
        width: 20,
        marginLeft: 10,
      },
    },
  },
  sortIcon: {
    opacity: props => (props.active ? 1 : 0),
    fill: "#FFFFFF",
  },
  sortLabel: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#7F7F7F",
    "&:hover": {
      color: "#7F7F7F",
    },
  },
  headerCell: {
    outline: "none",
    color: "#7F7F7F",
    fontWeight: 700,
    fontSize: 10,
    borderBottom: "solid 1px #404040",
    margin: 0,
    height: 30,
    display: "flex",
    alignItems: "center",
    paddingLeft: 10,
    "&:first-child": {
      margin: 0,
    },
    "&:hover": {
      color: "#FFFFFF",
    },
    "&:hover $sortIcon": {
      opacity: 1,
    },
  },
})

export default withStyles(styles)(IndexTableView)
