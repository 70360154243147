import React, { memo } from "react"
// Material UI
import AppBar from "@material-ui/core/AppBar"
import ShareIcon from "@material-ui/icons/Share"
import Typography from "@material-ui/core/Typography"
import { IconButton, Tooltip, Button } from "@material-ui/core"
// Components
import Icon from "../Icon"
import ShareButton from "./ShareButton"
// Assets
import saveIcon from "../../assets/save.svg"
import SiteLogo from "./SiteLogo"
// Settings
import config from "../../config"
import { PAGE_DESCRIPTION } from "../../constants"
// Redux
import { useDispatch, useSelector } from "react-redux"
import { selectCountry, selectIsMobile, showDataForm } from "../../redux/userInterface.duck"

const Header = ({ classes }) => {
  const isMobile = useSelector(selectIsMobile)
  const dispatch = useDispatch()

  const handleTitleClick = () => {
    dispatch(selectCountry(undefined))
  }

  const openDataForm = () => {
    dispatch(showDataForm(true))
  }

  return (
    <>
      <AppBar position="static" color="transparent" className={classes.root}>
        <div className={classes.headerContainer}>
          <div className={classes.titleRow}>
            <Tooltip title={PAGE_DESCRIPTION}>
              <span className={classes.titleContainer} onClick={handleTitleClick}>
                <SiteLogo isMobile={isMobile} />
              </span>
            </Tooltip>
            <ShareButton className={classes.shareBtnMobile} style={{ marginLeft: 0 }}>
              <IconButton aria-label="share">
                <ShareIcon />
              </IconButton>
            </ShareButton>
          </div>
          <div className={classes.logoContainer}>
            {config.displayEnvironment && !isMobile && (
              <Typography
                variant="h5"
                color="inherit"
                align="center"
                className={classes.title}
                style={{ marginLeft: 24, color: "#7f7f7f" }}
              >
                {config.environment}
              </Typography>
            )}
            <ShareButton className={classes.shareBtnDesktop}>
              <Button
                color="primary"
                variant="contained"
                style={{ marginLeft: 24, fontSize: 14, textTransform: "none", padding: "3px 10px" }}
              >
                Share
                <ShareIcon style={{ marginLeft: 5, fontSize: 16 }} />
              </Button>
            </ShareButton>
            {!isMobile ? (
              <Tooltip title="Download Full Dataset">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={openDataForm}
                  style={{
                    marginLeft: 24,
                    fontSize: 14,
                    textTransform: "none",
                    padding: "3px 10px",
                  }}
                >
                  Download
                  <Icon
                    src={saveIcon}
                    square
                    style={{ width: 20, height: 20, marginLeft: 6, fill: "#fff" }}
                  />
                </Button>
              </Tooltip>
            ) : null}
          </div>
        </div>
      </AppBar>
    </>
  )
}

export default memo(Header)
