const numericDisplay = (value, percentage = false, sign = false, decimalPlaces = 0) => {
  const config =
    decimalPlaces === null
      ? {}
      : {
          minimumFractionDigits: decimalPlaces,
          maximumFractionDigits: decimalPlaces,
        }
  const v = !(value === undefined || value === null) ? value.toLocaleString(undefined, config) : 0
  const withSign = sign || percentage ? `${value > 0 ? "+" : ""}${v}` : v
  return percentage ? `${withSign}%` : withSign
}

export default numericDisplay
