import React from "react"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import TableChartIcon from "@material-ui/icons/TableChart"
// Assets
import Icon from "../Icon"
import worldIcon from "../../assets/world_icon.svg"
// Redux
import { useDispatch, useSelector } from "react-redux"
import { selectShowMap, setShowMap } from "../../redux/userInterface.duck"

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: 10,
    padding: 6,
    minWidth: 1,
    border: "none",
    height: 36,
    width: 36,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
}))

export default function ToggleView({ onChange }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const showMap = useSelector(selectShowMap)

  const handleClick = bool => {
    if (onChange) {
      onChange(bool)
    }
    dispatch(setShowMap(bool))
  }

  return (
    <div>
      <Button
        variant={showMap ? "contained" : "outlined"}
        color={showMap ? "primary" : "default"}
        size="small"
        className={classes.button}
        onClick={() => handleClick(true)}
      >
        <Icon src={worldIcon} style={{ marginTop: 8 }} />
      </Button>
      <Button
        variant={!showMap ? "contained" : "outlined"}
        color={!showMap ? "primary" : "default"}
        size="small"
        className={classes.button}
        onClick={() => handleClick(false)}
      >
        <TableChartIcon />
      </Button>
    </div>
  )
}
