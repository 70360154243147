import { Typography } from "@material-ui/core"
import React, { useRef } from "react"
import moment from "moment"
import { makeStyles } from "@material-ui/core/styles"
import { Skeleton } from "@material-ui/lab"
import { useSelector } from "react-redux"
import numericDisplay from "../../utils/displayPercentage"
import LineChart from "../LineChart/LineChart.view"
import { selectHourlyError, selectIsLoading } from "../../redux/indexes.duck"
import { useAnimatedValue } from "../../hooks/useAnimatedValue"

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "90%",
    justifyContent: "space-between",
    margin: "0 auto 5px",
    "& > p": {
      fontSize: 22,
      fontWeight: 700,
    },
    "& .animated-value": {
      transition: "color 500ms",
    },
  },
  change_pos: {
    color: "#03a67d",
  },
  change_neg: {
    color: "#CF2D00",
    fontWeight: "400 !important",
  },
  yellow: {
    "& .animated-value": {
      color: "#EBB71E !important",
    },
  },
})

const WidgetHeader = ({ data, percentage }) => {
  const classes = useStyles()
  const { value } = data[data.length - 1]
  const change = data ? value - data[Math.min(24, data.length - 1)].value : 0

  const ref = useRef(null)
  useAnimatedValue(data, ref, classes.yellow, 1000)

  return (
    <div className={classes.container} ref={ref}>
      <Typography
        style={{ marginRight: 10, textAlign: "right", width: 75 }}
        className="animated-value"
      >
        {numericDisplay(value, false, false, 2)}
      </Typography>
      <Typography className={change >= 0 ? classes.change_pos : classes.change_neg}>
        {numericDisplay(change, percentage, true, 2)}
      </Typography>
    </div>
  )
}

const ChartPlaceholder = () => (
  <>
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "90%",
        margin: "0 auto 5px",
      }}
    >
      <Skeleton height={38} width={75} />
      <Skeleton height={38} width={75} />
    </div>
    <Skeleton variant="rect" animation="wave" height={68} />
  </>
)

const ChartWidget = ({ data, onClick, percentage, ...props }) => {
  const isLoading = useSelector(selectIsLoading)
  const error = useSelector(selectHourlyError)
  const hasError = error !== null

  if (isLoading) {
    return <ChartPlaceholder />
  }

  if (hasError) {
    return (
      <Typography style={{ margin: "20px auto", textAlign: "center" }}>
        An unexpected error has occurred
      </Typography>
    )
  }

  if (data.length === 0) {
    return (
      <Typography style={{ margin: "20px auto", textAlign: "center" }}>
        No data is available
      </Typography>
    )
  }

  return (
    <div onClick={onClick} style={{ cursor: "pointer" }}>
      <WidgetHeader data={data} percentage={percentage} />
      <LineChart data={data} {...props} />
      <div style={{ textAlign: "center" }}>
        <Typography style={{ fontSize: 12, fontWeight: 700, paddingLeft: 5, margin: 0 }}>
          {moment
            .utc(data[data.length - 24].ts)
            .local()
            .format("MMM DD")}
        </Typography>
      </div>
    </div>
  )
}

export default ChartWidget
