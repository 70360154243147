import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Menu from "@material-ui/core/Menu"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Checkbox from "@material-ui/core/Checkbox"
import Collapse from "@material-ui/core/Collapse"
import IconButton from "@material-ui/core/IconButton"
import ListItemText from "@material-ui/core/ListItemText"
// Icons
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: "#343434",
    outline: "none",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

const StyledMenu = props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
)

export default function FilterMenu({ allColumns }) {
  const classes = useStyles()

  // We don't want the first group in the dropdown
  // eslint-disable-next-line
  const [first, ...groups] = allColumns.reduce((acc, cur) => {
    const groupName = cur.parent.Header
    const groupIndex = acc.findIndex(group => group.Header === groupName)
    if (groupIndex === -1) {
      acc.push({ ...cur.parent })
    }
    return acc
  }, [])

  // Control the collapsed sections
  const [open, setOpen] = useState(
    groups.reduce((acc, cur, i) => {
      if (i !== 0) {
        acc[i] = false
      }
      return acc
    }, {}),
  )
  const handleOpen = i => setOpen({ ...open, [i]: !open[i] })

  // Control if the menu is being shown
  const [anchorEl, setAnchorEl] = useState(null)
  const menuIsOpen = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // Control Checkboxes
  const [unchecked, setUnchecked] = useState([])
  const isChecked = i => unchecked.indexOf(i) === -1
  const isIndeterminate = columns => {
    const allTheSame =
      columns.every(c => !c.getToggleHiddenProps().checked) ||
      columns.every(c => c.getToggleHiddenProps().checked)
    return !allTheSame
  }

  const handleParentCheckboxState = value => {
    const currentIndex = unchecked.indexOf(value)
    const newUnchecked = [...unchecked]

    if (currentIndex === -1) {
      newUnchecked.push(value)
    } else {
      newUnchecked.splice(currentIndex, 1)
    }

    setUnchecked(newUnchecked)
  }

  const handleOnChange = (onChange, e) => {
    onChange(e)
  }

  const handleOnChangeMany = (value, columns, e) => {
    // Stop the collapse from changing when clicked
    e.stopPropagation()
    handleParentCheckboxState(value)
    columns.forEach(col => {
      const { onChange } = col.getToggleHiddenProps()
      handleOnChange(onChange, e)
    })
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <FilterListRoundedIcon />
      </IconButton>
      <StyledMenu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={menuIsOpen}
        onClose={handleClose}
      >
        <List
          dense={true}
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.root}
        >
          {groups.map((group, index) => {
            const labelId = `checkbox-list-secondary-label-${index}`
            return (
              <>
                <ListItem key={index} button onClick={() => handleOpen(index)}>
                  <Checkbox
                    edge="start"
                    color="primary"
                    checked={isChecked(index)}
                    indeterminate={isIndeterminate(group.columns)}
                    onChange={e => handleOnChangeMany(index, group.columns, e)}
                    onClick={e => e.stopPropagation()}
                    inputProps={{ "aria-labelledby": labelId }}
                    style={isChecked(index) ? {} : { color: "white" }}
                  />
                  <ListItemText primary={group.Header} />
                  {open[index] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open[index]} timeout="auto" unmountOnExit>
                  <List
                    dense={true}
                    component="div"
                    disablePadding
                    style={{ backgroundColor: "#2a2a2a" }}
                  >
                    {group.columns.map(column => {
                      const { checked, onChange } = column.getToggleHiddenProps()
                      return (
                        <ListItem key={column.id} className={classes.nested}>
                          <Checkbox
                            edge="start"
                            color="primary"
                            checked={checked}
                            onChange={e => handleOnChange(onChange, e)}
                            onClick={e => e.stopPropagation()}
                            inputProps={{ "aria-labelledby": labelId }}
                            style={checked ? {} : { color: "white" }}
                          />
                          <ListItemText primary={column.Header} />
                        </ListItem>
                      )
                    })}
                  </List>
                </Collapse>
              </>
            )
          })}
        </List>
      </StyledMenu>
    </div>
  )
}
