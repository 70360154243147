import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit"
import loadingStates from "./loadingStates"

const createFetchReducer = ({ name, url, path, handleData = f => f }, client) => {
  if (client === undefined) {
    throw new Error("Missing API client in fetch reducer")
  }

  const initialState = {
    data: null,
    loading: loadingStates.INIT,
    requestCount: 0,
    lastUpdated: undefined,
    error: null,
  }

  const baseSelector = state => {
    return (path || name)
      .split(".")
      .reverse()
      .reduce((acc, v) => acc[v], state)
  }

  const selectData = state => baseSelector(state).data || []
  const selectLoadingValue = state => baseSelector(state).loading
  const selectLoading = createSelector(
    [selectLoadingValue],
    loadingValue => loadingValue === loadingStates.INIT,
  )
  const selectLastUpdated = state => baseSelector(state).lastUpdated
  const selectError = state => baseSelector(state).error
  const selectHasRequested = state => baseSelector(state).requestCount > 0
  const selectHasData = createSelector([state => baseSelector(state).data], data => data !== null)

  const fetch = createAsyncThunk(`FETCH_${name}`, (args = {}, thunkAPI) => {
    const request_url = typeof url === "string" ? url : url(args.data ? args.data : args)
    return client.get(request_url)
  })

  const slice = createSlice({
    name,
    initialState,
    reducers: {
      clear: () => initialState,
    },
    extraReducers: {
      [fetch.pending]: state => {
        if (state.loading !== loadingStates.INIT) {
          state.loading = loadingStates.PENDING
        }
        state.requestCount += 1
      },
      [fetch.fulfilled]: (state, action) => {
        if (action.payload !== null) {
          state.data = handleData(action.payload.results || action.payload.entities, action.meta)
          state.lastUpdated = action.payload.ts
          state.loading = loadingStates.IDLE
        }
      },
      [fetch.rejected]: (state, action) => {
        state.error = action.error
        state.loading = loadingStates.IDLE
      },
    },
  })

  const { actions, reducer } = slice
  const { clear } = actions

  return {
    reducer,
    actions: { fetch, clear },
    selectors: {
      data: selectData,
      hasData: selectHasData,
      error: selectError,
      loading: selectLoading,
      loadingValue: selectLoadingValue,
      lastUpdated: selectLastUpdated,
      hasRequested: selectHasRequested,
    },
  }
}

export default createFetchReducer
