import React from "react"
import { Link } from "@material-ui/core"

const BusinessEmailError = (
  <span>
    Please use your business email. If you don&apos;t have one, please email{" "}
    <Link href="mailto:info@ravenpack.com" color="secondary">
      info@ravenpack.com
    </Link>
    .
  </span>
)

export default BusinessEmailError
