import React, { useRef, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import moment from "moment"
import {
  LineChart as LineChartComponent,
  Line,
  ReferenceLine,
  Tooltip,
  YAxis,
  ResponsiveContainer,
} from "recharts"
import { Paper, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import numericDisplay from "../../utils/displayPercentage"
import { selectIsMobile } from "../../redux/userInterface.duck"
import { CustomDot } from "../Modal/LineChartModal/LC"

const calculateChartData = data => {
  const afterData = data.slice(24)
  const beforeData = data.slice(0, 24)

  return [
    ...beforeData.map(d => ({
      ts: d.ts,
      before: d.value,
      value: d.value,
    })),
    ...(afterData.length && [
      {
        ts: afterData[0].ts,
        before: afterData[0].value,
        after: afterData[0].value,
        value: afterData[0].value,
      },
    ]),
    ...afterData.slice(1, afterData.length).map(d => ({
      ts: d.ts,
      after: d.value,
      value: d.value,
    })),
  ]
}

const useTooltipStyle = makeStyles({
  container: {
    background: "#141414",
    border: "solid 1px #404040",
    padding: 5,
    minWidth: 120,
    textAlign: "center",
  },
  date: {
    fontSize: 12,
  },
  value: {
    fontWeight: 700,
    fontSize: 14,
    textAlign: "center",
  },
})

const CustomTooltip = ({ payload, format = "MMM DD, HH:mm " }) => {
  const ref = useRef(undefined)
  const classes = useTooltipStyle()
  const item = (payload[0] || { value: 0 }).payload || { value: 0 }
  const date = moment.utc(item.ts).local().format(format)
  const [offset, setOffset] = useState({ x: 0, y: 0 })

  useEffect(() => {
    if (ref.current) {
      const boundingBox = ref.current.getBoundingClientRect()
      setOffset({
        x: -boundingBox.width / 2,
        y: -(boundingBox.height + 10),
      })
    }
  }, [ref])

  return (
    <Paper
      ref={ref}
      elevation={8}
      className={classes.container}
      style={{ transform: `translate(${offset.x}px, ${offset.y}px)` }}
    >
      <Typography className={classes.date}>{date}</Typography>
      <Typography className={classes.value}>
        {numericDisplay(item.main || item.value, false, false, 2)}
      </Typography>
    </Paper>
  )
}

const calculateOffset = (data, reference) => {
  if (data === undefined) {
    return 0
  }
  const dataMax = Math.max(...data.map(i => i.value).filter(i => i !== undefined))
  const dataMin = Math.min(...data.map(i => i.value).filter(i => i !== undefined))

  if (dataMax <= reference) {
    return 0
  }
  if (dataMin >= reference) {
    return 1
  }
  return (dataMax - reference) / (dataMax - dataMin)
}

const Chart = ({ data, offset }) => {
  const [coords, setCoords] = useState({ x: 0, y: 0 })
  const gradientId = `gradient_${Math.random()}`
  // Disable tooltips on mobile
  const enableToolTip = !useSelector(selectIsMobile)

  return (
    <ResponsiveContainer height={55} width="100%">
      <LineChartComponent data={data} style={{ cursor: "pointer" }}>
        <YAxis domain={["dataMin", "dataMax"]} hide />
        <defs>
          <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
            <stop offset={offset} stopColor="#03a67d" stopOpacity={1} />
            <stop offset={offset} stopColor="#CF2D00" stopOpacity={1} />
          </linearGradient>
        </defs>
        <Line
          type="monotone"
          dataKey="value"
          stroke="none"
          strokeWidth={0}
          dot={false}
          isAnimationActive={false}
          activeDot={({ cx, cy }) => <CustomDot x={cx} y={cy} onChange={setCoords} />}
        />
        <Line
          type="monotone"
          dataKey="before"
          stroke="#FFFFFF"
          strokeWidth={2}
          dot={false}
          isAnimationActive={false}
        />
        <Line
          type="monotone"
          dataKey="after"
          stroke={`url(#${gradientId})`}
          strokeWidth={2}
          dot={false}
          isAnimationActive={false}
        />
        <ReferenceLine x={24} stroke="#FFFFFF" strokeWidth={2} strokeDasharray="3 3" />
        {enableToolTip && (
          <Tooltip content={<CustomTooltip />} position={coords} isAnimationActive={false} />
        )}
      </LineChartComponent>
    </ResponsiveContainer>
  )
}

const LineChart = ({ data, ...props }) => {
  const chartData = calculateChartData(data)
  const offset = calculateOffset(chartData.slice(24), chartData[24].value)

  return (
    <div style={{ display: "flex" }} {...props}>
      <Chart data={chartData} offset={offset} />
    </div>
  )
}

export default LineChart
