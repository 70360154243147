import React from "react"
import PropTypes from "proptypes"
import Icon from "./Icon"
import dropdownIcon from "../assets/dropdown_icon.svg"
import dropdownOutline from "../assets/dropdown_outline.svg"

const DropdownIcon = ({ variant, ...props }) => {
  const variants = {
    default: dropdownIcon,
    outline: dropdownOutline,
  }

  return <Icon src={variants[variant]} square {...props} />
}

DropdownIcon.propTypes = {
  variant: PropTypes.string,
}

DropdownIcon.defaultProps = {
  variant: "default",
}

export default DropdownIcon
