import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { Tooltip, Typography, useTheme } from "@material-ui/core"
import { useSelector } from "react-redux"
import { selectSelectedCountry, selectIsMobile } from "../../redux/userInterface.duck"
import { Column, Table, AutoSizer } from "react-virtualized"
import "react-virtualized/styles.css"
import numericDisplay from "../../utils/displayPercentage"
import TableSortLabel from "@material-ui/core/TableSortLabel"

const IndexTableView = ({
  data,
  classes,
  tableColumns,
  sorting,
  loading,
  minRows,
  label,
  onRowClick,
  highlightOnHover,
  highlightTooltip = "",
  columnOrder = [],
  selectedId,
}) => {
  const { enableSort, defaultSortDir, defaultSortBy } = sorting
  const [sortDir, setSortDir] = useState(defaultSortDir)
  const [sortBy, setSortBy] = useState(defaultSortBy)
  const ref = useRef(null)
  const selectedCountry = useSelector(selectSelectedCountry)
  const isMobile = useSelector(selectIsMobile)

  useEffect(() => {
    if (ref.current !== null) {
      const row = document.getElementById("selected_country_row")
      if (row) {
        ref.current.scroll({ top: row.offsetTop, behaviour: "smooth" })
      } else {
        ref.current.scroll({ top: 0, behaviour: "smooth" })
      }
    }
  }, [ref, selectedCountry])

  const handleRequestSort = (event, property) => {
    const isAsc = sortBy === property && sortDir === "asc"
    setSortDir(isAsc ? "desc" : "asc")
    setSortBy(property)
  }

  function descendingComparator(a, b, sortBy) {
    const aVal = typeof a[sortBy] === "string" ? a[sortBy].toLowerCase() : a[sortBy]
    const bVal = typeof b[sortBy] === "string" ? b[sortBy].toLowerCase() : b[sortBy]
    if (bVal < aVal) {
      return -1
    }
    if (bVal > aVal) {
      return 1
    }
    return 0
  }

  function getComparator(sortDir, sortBy) {
    const sign = sortDir === "desc" ? 1 : -1
    return (a, b) => sign * descendingComparator(a, b, sortBy)
  }

  const sort = ({ sortBy, sortDir }) => {
    const comparator = getComparator(sortDir, sortBy)

    if (!enableSort) {
      return list
    }
    const stabilizedThis = list.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const dir = comparator(a[0], b[0])
      if (dir !== 0) {
        return dir
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
  }

  const columns = tableColumns.reduce((acc, c) => ({ ...acc, [c.id]: c }), {
    rank: {
      id: "rank",
      label: "",
      width: 40,
      flexGrow: 0,
      flexShrink: 0,
      alignLeft: true,
      sortable: false,
    },
  })

  const columnIds = ["rank", ...columnOrder].filter(c => {
    return c in columns && (!isMobile || (!columns[c].hideOnMobile && isMobile))
  })

  const list = data.map(d => ({
    id: d.iso2,
    ...d.columns,
    selected: d.isSelectedCountry,
  }))

  const sortedList = sort({ sortBy, sortDir })

  const theme = useTheme()

  const renderCell = ({ cellData, dataKey, rowIndex, columnIndex, rowData, ...props }) => {
    const columnData = columns[dataKey] || {}

    const calcColor = () => {
      if (!columnData) {
        return "inherit"
      }
      if (columnData.colorChange && cellData < 0) {
        return "#CF2D00"
      }
      if ((columnData.colorChange && cellData > 0) || (columnIndex === 1 && rowData.selected)) {
        return theme.palette.secondary.main
      }
      return "inherit"
    }

    return (
      <div
        key={`${rowIndex}-${columnIndex}-${rowData.selected}`}
        data-column={dataKey}
        style={{
          color: calcColor(),
          textAlign: columnData.alignLeft ? "left" : "right",
          fontWeight: "bold",
          padding: "10px",
          paddingRight: columnIndex === columnOrder.length ? 10 : 0,
          cursor: onRowClick ? "pointer" : "initial",
          overflow: "hidden",
          textOverflow: "ellipsis",
          background: rowData.selected ? "rgba(255, 255, 255, 0.1)" : "none",
        }}
        className={columnIndex > 0 && columnIndex % 2 === 0 ? "animated-value" : null}
      >
        {numericDisplay(
          cellData || 0,
          columnData && columnData.percentage,
          false,
          columnIndex === 0 ? 0 : 2,
        )}
      </div>
    )
  }

  const renderHeaderCell = ({ label, dataKey, columnIndex, ...props }) => {
    const alignment = !columns[dataKey] || columns[dataKey].id === "entity" ? "left" : "right"
    const columnSortable = columns[dataKey] && columns[dataKey].sortable !== false
    const onClick = columnSortable ? e => handleRequestSort(e, dataKey) : f => f
    return (
      <div
        style={{
          color: sortBy === dataKey ? "white" : "inherit",
          width: "100%",
          justifyContent: "flex-end",
          display: "flex",
          alignItems: "center",
          flexDirection: alignment === "left" ? "row-reverse" : "row",
        }}
        onClick={onClick}
      >
        {columnSortable && (
          <TableSortLabel
            active={enableSort && sortBy === dataKey}
            direction={sortBy === dataKey ? sortDir : "asc"}
            classes={{
              icon: classes.sortIcon,
              root: classes.sortLabel,
            }}
          />
        )}
        <Typography style={{ fontSize: 10 }}>{label}</Typography>
      </div>
    )
  }

  const renderHeaderRow = ({ className, columns, style }) => {
    return (
      <div
        className={className}
        role="row"
        style={{ ...style, paddingRight: 15 }}
        data-type="header"
      >
        {columns}
      </div>
    )
  }

  const renderRow = ({ className, columns, style, key, ...props }) => {
    return (
      <Tooltip
        key={key}
        title={highlightOnHover ? highlightTooltip : ""}
        enterDelay={2000}
        onClick={() => props.onRowClick(props)}
      >
        <div className={className} role="row" style={style} data-type="row">
          {columns}
        </div>
      </Tooltip>
    )
  }

  const handleRowClick = ({ rowData }) => {
    if (onRowClick) {
      onRowClick(rowData)
    }
  }

  const [scrollToIndex, setScrollToIndex] = useState(0)
  useEffect(() => {
    if (selectedId) {
      const hasId = obj => obj.id === selectedId
      const idx = sortedList.findIndex(hasId)
      setScrollToIndex(idx)
    }
  }, [selectedId, sortedList])

  if (loading) {
    return ""
  }

  if (data.length === 0) {
    return (
      <div>
        <p style={{ textAlign: "center" }}>
          No data available for {label} for the selected country.
        </p>
      </div>
    )
  }

  return (
    <AutoSizer>
      {({ width, height }) => (
        <Table
          width={width}
          height={height}
          headerHeight={isMobile ? 38 : 30}
          rowHeight={40}
          rowCount={sortedList.length}
          rowGetter={({ index }) => ({ ...sortedList[index], rank: index + 1 })}
          gridClassName={classes.tableBodyContainer}
          headerClassName={classes.headerCell}
          onRowClick={handleRowClick}
          headerRowRenderer={renderHeaderRow}
          rowRenderer={renderRow}
          rowClassName={classes.tableRow}
          sort={sort}
          sortBy={sortBy}
          sortDirection={sortDir.toUpperCase()}
          scrollToAlignment="start"
          scrollToIndex={scrollToIndex}
        >
          {columnIds.map(c => {
            const meta = columns[c]
            return (
              <Column
                width={meta.width || 100}
                style={{ margin: 0 }}
                dataKey={meta.id}
                key={meta.id}
                label={meta.label}
                flexShrink={meta.flexShrink !== undefined ? meta.flexShrink : 1}
                flexGrow={meta.flexGrow !== undefined ? meta.flexGrow : 1}
                headerRenderer={renderHeaderCell}
                cellRenderer={renderCell}
              />
            )
          })}
        </Table>
      )}
    </AutoSizer>
  )
}

IndexTableView.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.array,
  enableSort: PropTypes.bool,
  tableColumns: PropTypes.array.isRequired,
  minRows: PropTypes.number,
  onRowClick: PropTypes.func,
  highlightOnHover: PropTypes.bool,
}

IndexTableView.defaultProps = {
  data: [],
  minRows: 18,
  onRowClick: undefined,
  highlightOnHover: false,
}

export default IndexTableView
