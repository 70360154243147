import styled from "styled-components"

const TableStyles = styled.div`
  .table {
    color: #f7f7f7;
    font-weight: bold;
    color: black;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      padding-left: 5px;
    }
    &::-webkit-scrollbar-thumb {
      max-height: 10px;
      border-radius: 2px;
      background-color: #d7d7d7;
    }
    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }

    .tr {
      /* cursor: pointer; */
      background-color: #252525;
      :nth-child(odd) {
        background-color: #202020;
      }
      :hover {
        background-color: #363636;
      }
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      color: white;
      padding: 10px;
      overflow: hidden;
      text-align: right;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }
    .td {
      background-color: inherit;
    }
    .th {
      background-color: #252525;
      color: #7f7f7f;
      font-size: 10px;
      text-transform: uppercase;
      padding: 5px 5px;
      border-bottom: 1px solid #404040;
    }

    .activeSortIcon {
      opacity: 1;
      fill: #fff;
    }
    .inactiveSortIcon {
      opacity: 0;
      fill: #fff;
    }
    .sortLabel {
      font-size: 10px;
      font-weight: bold;
      color: #7f7f7f;
      &:hover {
        color: #fff;
      }
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        /* border-right: 3px double #7f7f7f !important; */
        /* border-right: none !important; */
      }

      [data-sticky-first-right-td] {
        padding-left: 18px;
      }
    }
  }
`

export default TableStyles
