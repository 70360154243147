import React, { useRef } from "react"
// Components
// Material-UI
import { makeStyles } from "@material-ui/core/styles"
// Redux
import { useDispatch, useSelector } from "react-redux"
import IndexTable from "../IndexTable"
import {
  selectRelatedTopics,
  getRelatedTopics,
  selectRelatedTopicsIsLoading,
} from "../../redux/relatedTopics.duck"
// Utils
import useInterval from "../../hooks/useInterval"
import { selectIsMobile, selectSelectedCountry } from "../../redux/userInterface.duck"
import { fetchHistoricRelatedData } from "../../redux/related.duck"
import { setModal } from "../../redux/modal.duck"
import equal from "deep-equal"
import HoverIcon from "../../assets/fullscreen.svg"
import { useAnimatedValue } from "../../hooks/useAnimatedValue"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    "& .animated-value": {
      transition: "color 500ms",
    },
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid #404040",
  },
  tab: {
    textTransform: "none",
    textSize: 16,
    fontWeight: "bold",
    color: "#fff",
    minWidth: 100,
  },

  yellow: {
    "& .animated-value": {
      color: "#EBB71E !important",
    },
  },
}))

const RelatedTopicsBody = React.memo(({ onRowClick, loading, data }) => {
  const isMobile = useSelector(selectIsMobile)
  const classes = useStyles()
  const tableColumns = [
    { id: "entity", label: "Related Topic", alignLeft: true },
    { id: "ratio", label: "Ratio (%)" },
    { id: "change", label: "1D CHG", colorChange: true, percentage: true },
  ]

  const sorting = {
    enableSort: true,
    defaultSortDir: "desc",
    defaultSortBy: "ratio",
  }

  const ref = useRef(null)
  useAnimatedValue(data, ref, classes.yellow, 1000)

  return (
    <div className={classes.root} ref={ref} style={{ minHeight: 400 }}>
      <IndexTable
        loading={loading}
        minRows={22}
        data={data}
        sorting={sorting}
        tableColumns={tableColumns}
        onRowClick={!isMobile && onRowClick}
        highlightOnHover={!isMobile}
        highlightTooltip="Click on a topic to pop-out chart"
        columnOrder={["entity", "ratio", "change"]}
        hoverIcon={HoverIcon}
      />
    </div>
  )
}, equal)

const RealtedTopicsWidget = () => {
  const loading = useSelector(selectRelatedTopicsIsLoading)

  const dispatch = useDispatch()
  useInterval(
    () => {
      dispatch(getRelatedTopics())
    },
    1000 * 60 * 10,
    true,
  )
  const relatedTopics = useSelector(selectRelatedTopics)
  const selectedCountry = useSelector(selectSelectedCountry)

  const handleRowClick = React.useCallback(
    columns => {
      const { entity: topicName, id: topic, ratio: value, change } = columns
      dispatch(
        fetchHistoricRelatedData({
          force: true,
          data: {
            topic,
            country: selectedCountry,
            horizon: "ALL",
          },
        }),
      )
      dispatch(
        setModal({
          title: `${topicName}`,
          type: "topic",
          description: `Shows the theme of ${topicName.toLowerCase()} mentioned alongside the Coronavirus. Each ratio is the % of all news stories about the Coronavirus that mention the topic of ${topicName.toLowerCase()}.`,
          data: {
            topic,
            country: selectedCountry,
            value,
            change,
            horizon: "ALL",
            scale: "linear",
          },
        }),
      )
    },
    [selectedCountry, dispatch],
  )

  return <RelatedTopicsBody data={relatedTopics} loading={loading} onRowClick={handleRowClick} />
}

export default RealtedTopicsWidget
