import React, { useEffect, useRef, useState } from "react"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { useDispatch, useSelector } from "react-redux"
import { Input, MenuList, MenuItem, Paper, Popover } from "@material-ui/core"
import { selectCountryValues } from "../../../redux/countriesData.duck"
import DropdownIcon from "../../DropdownIcon"
import NestedMenuItem from "../../NestedMenuItem/NestedMenuItem.view"
import { setComparisonCountry } from "../../../redux/userInterface.duck"
import CountrySuggester from "../../../utils/CountrySuggestor"

const useStyle = makeStyles(theme => ({
  container: {
    borderRadius: 4,
    maxWidth: 195,
    background: "#232523",
    height: 40,
    border: "solid 1px #7F7F7F",
    padding: "0px 10px",
    display: "flex",
    alignItems: "center",
    color: "#C0C0C0",
    cursor: "pointer",
    marginLeft: 20,
    flexGrow: 1,
    flexShrink: 1,
    whiteSpace: "nowrap",
  },
  icon: {
    marginLeft: "auto",
    fill: "#7F7F7F",
    width: 24,
    height: 24,
  },
  displayContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "5px 0px 0px 0px",
    borderRadius: 4,
    cursor: "pointer",
    transition: theme.transitions.create(["background"], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeOut,
    }),
    padding: 5,
    "&:hover": {
      background: "#343434",
      transition: theme.transitions.create(["background"], {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeIn,
      }),
    },
  },
  displayTitle: {
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.secondary.main,
  },
  dropdownIcon: {
    fill: "#FFFFFF",
    width: 24,
    height: 24,
  },
  popover: {
    border: "solid #7F7F7F 1px",
    width: 350,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  optionContainer: {
    maxHeight: 300,
    overflow: "auto",
    marginRight: 5,
    paddingRight: 5,
    margin: "5px 0",
    "&::-webkit-scrollbar": {
      width: "0.5em",
    },
    "&::-webkit-scrollbar-thumb": {
      maxHeight: 10,
      borderRadius: 5,
      backgroundColor: "#d7d7d7",
    },
  },
  searchInput: {
    borderBottom: "solid 2px",
    borderBottomColor: theme.palette.primary.main,
    width: "100%",
    padding: "5px 20px",
  },
  optionMatch: {
    color: theme.palette.secondary.main,
  },
  title: {
    fontSize: 12,
    paddingBottom: 0,
  },
  suggestionLink: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}))

const CountrySelectorItem = ({ value, children, onClick, isOpen, ...props }) => {
  const handleClick = event => {
    if (onClick) {
      onClick(event)
    }
  }
  return (
    <NestedMenuItem isParentOpen={isOpen} onClick={handleClick} {...props}>
      {children}
    </NestedMenuItem>
  )
}

const ComparisonSelector = ({ onChange, topics }) => {
  const classes = useStyle()
  const [anchorEl, setAnchorEl] = useState(undefined)
  const [searchQuery, setSearchQuery] = useState("")
  const [width, setWidth] = useState(200)
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.getBoundingClientRect().width)
    }
  }, [anchorEl])

  const countries = useSelector(selectCountryValues)

  const handleClose = () => {
    setAnchorEl(undefined)
    setTimeout(() => setSearchQuery(""), 500)
  }

  const handleSearch = event => {
    setSearchQuery(event.target.value)
  }

  const dispatch = useDispatch()
  const handleSelection = (country, index) => {
    // setQueryParams({ ...queryParams, cc: country || "world", ci: index })
    dispatch(setComparisonCountry(country))
    if (onChange) {
      onChange(country, index)
    }
    handleClose()
  }

  const renderOption = title => {
    if (!title.toLowerCase().includes(searchQuery.toLowerCase())) {
      return title
    }

    if (searchQuery === "") {
      return title
    }

    const lcTitle = title.toLowerCase()
    const lcQuery = searchQuery.toLowerCase()
    const queryIndex = lcTitle.indexOf(lcQuery)

    return (
      <>
        <span>{title.slice(0, queryIndex)}</span>
        <span className={classes.optionMatch}>
          {title.slice(queryIndex, queryIndex + searchQuery.length)}
        </span>
        <span>{title.slice(queryIndex + searchQuery.length)}</span>
      </>
    )
  }

  const results = countries.filter(i => i.title.toLowerCase().includes(searchQuery.toLowerCase()))
  const suggestion = results.length === 0 ? CountrySuggester.check(searchQuery) : undefined

  const renderItem = (country, label) => {
    return (
      <CountrySelectorItem
        value={country}
        isOpen={!!anchorEl}
        label={renderOption(label)}
        key={label}
      >
        {topics.map(t => (
          <MenuItem key={t.id} onClick={() => handleSelection(country, t.id)}>
            {t.title}
          </MenuItem>
        ))}
      </CountrySelectorItem>
    )
  }

  return (
    <>
      <div className={classes.container} onClick={evt => setAnchorEl(evt.currentTarget)}>
        <Typography>Comparison</Typography>
        <DropdownIcon className={classes.icon} width={24} />
      </div>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Paper square className={classes.popover} style={{ width }}>
          <div className={classes.searchInput}>
            <Input
              type="text"
              value={searchQuery}
              placeholder="Search for a country"
              onInput={handleSearch}
              fullWidth
              disableUnderline
              autoFocus
            />
          </div>
          <MenuList className={classes.optionContainer}>
            {searchQuery === "" && renderItem(undefined, "Worldwide")}
            {results.length > 0 ? (
              results.map(i => renderItem(i.value, i.title))
            ) : (
              <div className={classes.noDataPlaceholder}>
                {suggestion && renderItem(suggestion.isocode, suggestion.country)}
              </div>
            )}
          </MenuList>
        </Paper>
      </Popover>
    </>
  )
}

export default ComparisonSelector
