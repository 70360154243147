import { parse, stringify } from "qs"

const useQueryParams = userParams => {
  const [, paramsString = ""] = window.location.search.split("?")
  const extractQueryParams = () => parse(paramsString)
  const allParams = extractQueryParams()

  const queryParams = userParams
    ? Object.entries(allParams).reduce(
        (acc, [k, v]) => (userParams.includes(k) ? { ...acc, [k]: v } : acc),
        {},
      )
    : allParams

  const encodeQueryParams = params => {
    const str = stringify(params)
    return str === "" ? "" : `?${str}`
  }

  const setQueryParams = params => {
    const { origin, pathname } = window.location
    const url = `${origin}${pathname}${encodeQueryParams(params)}`
    window.history.pushState({ path: url }, "", url)
  }

  const removeQueryParams = keys => {
    const result = Object.entries(extractQueryParams()).reduce(
      (acc, [k, v]) => (keys.includes(k) ? acc : { ...acc, [k]: v }),
      {},
    )
    setQueryParams(result)
  }

  return {
    queryParams,
    setQueryParams,
    removeQueryParams,
    encodeQueryParams,
  }
}

export default useQueryParams
