import { withStyles } from "@material-ui/core/styles"
import Header from "./Header.view"

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 10,
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: 1640,
    width: "100%",
    margin: "0 auto",
  },
  titleContainer: {
    marginLeft: 23,
    display: "flex",
    alignItems: "center",
    height: 36, // IE11 fix
    cursor: "pointer",
  },
  title: {
    textAlign: "left",
    marginLeft: 16,
  },
  logo: {
    paddingLeft: 45,
    paddingTop: 5,
  },
  logoContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 23,
  },
  dashboardIcon: {
    fill: theme.palette.secondary.main,
    height: 36,
  },
  shareBtnMobile: {
    display: "none",
    padding: 0,
    marginLeft: "auto",
  },
  shareBtnDesktop: {
    display: "block",
  },
  titleRow: {
    display: "flex",
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      height: "auto",
    },
    headerContainer: {
      padding: "10px 20px",
      flexDirection: "column-reverse",
    },
    titleContainer: {
      marginLeft: 0,
    },
    title: {
      fontWeight: 700,
      fontSize: 15,
      marginLeft: 8,
    },
    dashboardIcon: {
      display: "none",
    },
    logo: {
      padding: 0,
      height: 18,
    },
    logoContainer: {
      marginTop: 10,
      padding: 0,
    },
    titleRow: {
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
    },
    shareBtnMobile: {
      display: "block",
      padding: 0,
    },
    shareBtnDesktop: {
      display: "none",
    },
  },
  "@media (max-width:350px)": {
    headerContainer: {
      padding: "0px 10px",
    },
  },
})

export default withStyles(styles)(Header)
