import React, { useState, useEffect } from "react"
// Material-UI
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import { Button, Typography, Link } from "@material-ui/core"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import CircularProgress from "@material-ui/core/CircularProgress"
// Packages
import AnimateHeight from "react-animate-height"
import axios from "axios"
import TagManager from "react-gtm-module"
// Components
import WidgetContainer from "../WidgetContainer"
import config from "../../config"
// Hooks
import useUtmParams from "../../hooks/useUtmParams"

const useStyles = makeStyles(theme => ({
  formContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  inputAndButtonContainer: {
    display: "flex",
    alignItems: "flex-start",
    paddingTop: 8,
  },
  textField: {
    flexGrow: 1,
    marginRight: 10,
  },
  heading: {
    fontSize: 22,
    fontWeight: 700,
    paddingTop: 8,
    paddingBottom: 8,
  },
  subheading: {
    fontSize: 14,
  },
  termsContainer: {
    alignItems: "flex-start",
    paddingTop: 8,
  },
  label: {
    fontSize: 12,
    paddingTop: 9,
  },
  checkbox: {
    color: "#fff",
  },
}))

const Subscribe = () => {
  // Get Classy 🍸
  const classes = useStyles()

  const [isLoading, setIsLoading] = useState(false)
  const [displayWidget, setDisplayWidget] = useState(false)
  const [submissionSuccessful, setSubmissionSuccessful] = useState(false)
  // Email Input
  const [email, setEmail] = useState("")
  const [error, setError] = useState(undefined)
  const [isValidEmail, setIsValidEmail] = useState(false)
  // Checkbox
  const [checked, setChecked] = useState(false)
  const [showCheckBox, setShowCheckBox] = useState(false)
  const [showCheckBoxError, setShowCheckBoxError] = useState(false)

  // Have they already subscribed?
  useEffect(() => {
    const hasSubScribed = window.localStorage.getItem("hasSubscribed")
    setDisplayWidget(!hasSubScribed)
  }, [])

  // Are there any utm params in localStorage
  const { utmParams } = useUtmParams()

  const handleSubmit = async () => {
    if (!checked) setShowCheckBoxError(true)
    if (!isValidEmail) setError("Please enter a valid email address")
    if (!checked || !isValidEmail) return

    setIsLoading(true)

    const formData = new FormData()
    formData.set("email", email)
    formData.set("name", "Subscription for Updates")
    formData.set("form_type", "corona")
    utmParams.length && utmParams.map(({ k, v }) => formData.set(k, v))

    const url = config.baseUrls.subscriptions

    const options = {
      method: "post",
      url,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formData,
    }

    try {
      await axios(options)
      setSubmissionSuccessful(true)
      window.localStorage.setItem("hasSubscribed", true)

      if (window.dataLayer) {
        const tagManagerArgs = {
          dataLayer: {
            event: "subscribe",
            request_type: "COVID Updates",
            page_type: "Monitor",
          },
        }
        TagManager.dataLayer(tagManagerArgs)
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.email
      ) {
        setError(businessEmailError)
      } else if (error.message) {
        setError(error.message)
      } else {
        setError(error)
      }
      setIsLoading(false)
    }
  }

  const handleEmailInputChange = event => {
    const { value } = event.target
    setEmail(value)

    const isEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value,
    )
    if (isEmail !== isValidEmail) {
      setIsValidEmail(!isValidEmail)
    }
    if (isEmail && !isValidEmail) {
      setError(undefined)
    }
  }

  const handleCheckbox = () => {
    setShowCheckBoxError(false)
    setChecked(!checked)
  }

  const businessEmailError = (
    <span>
      Please use your business email. If you don't have one, please email{" "}
      <Link href="mailto:info@ravenpack.com">info@ravenpack.com</Link>.
    </span>
  )

  const checkboxLabel = (
    <>
      {showCheckBoxError && (
        <>
          <span style={{ color: "#f44336" }}>Please check this box if you want to proceed</span>{" "}
          <br />
        </>
      )}
      By submitting this form you agree and consent to receive communications from RavenPack. We
      will process your personal data with the purpose of managing your personal account on
      RavenPack and offering our services. You can exercise your rights of access, rectification,
      erasure, restriction of processing, data portability and objection by emailing us at{" "}
      <Link href="mailto:privacy@ravenpack.com" color="primary">
        privacy@ravenpack.com
      </Link>
      . For more information, you can check out our{" "}
      <Link href="https://www.ravenpack.com/privacy/" target="_blank" color="primary">
        Privacy Policy
      </Link>
      .
    </>
  )

  const subscribeForm = (
    <div className={classes.formContainer}>
      <Typography className={classes.subheading} style={{ fontWeight: 700 }}>
        Get our regular news analysis, infographics, and video insights on the coronavirus via
        email.
      </Typography>
      <div className={classes.inputAndButtonContainer}>
        <TextField
          id="email-input"
          placeholder="Enter your email here"
          value={email}
          onChange={handleEmailInputChange}
          variant="filled"
          className={classes.textField}
          onClick={() => setShowCheckBox(true)}
          inputProps={{
            style: { paddingTop: 10 },
          }}
          type="email"
          autoComplete="email"
          error={error}
          helperText={error}
          tabIndex="1"
        />
        <div style={{ width: 100, minWidth: 100, display: "flex", justifyContent: "center" }}>
          {isLoading ? (
            <CircularProgress size={30} />
          ) : (
            <Button
              variant={(checked && isValidEmail) || email === "" ? "contained" : "outlined"}
              color="primary"
              onClick={handleSubmit}
              tabIndex="3"
              style={{ height: 38, cursor: !checked || !isValidEmail ? "not-allowed" : "pointer" }}
            >
              Sign Up
            </Button>
          )}
        </div>
      </div>
      <AnimateHeight duration={500} height={showCheckBox ? "auto" : 0}>
        {email === "who made me?" && (
          <Typography className={classes.subheading} style={{ paddingTop: 10 }}>
            This awesome app was made by the dream team at RavenPack. Special thanks to James,
            Ashley, Dario, Kat, Cristina and Ricard
          </Typography>
        )}
        <FormControlLabel
          classes={{
            root: classes.termsContainer,
            label: classes.label,
          }}
          control={
            <Checkbox
              checked={checked}
              onChange={handleCheckbox}
              color="primary"
              className={classes.checkbox}
              tabIndex="2"
            />
          }
          label={checkboxLabel}
        />
      </AnimateHeight>
    </div>
  )

  const subscribeSuccess = (
    <div>
      <Typography className={classes.heading}>Registration Confirmed!</Typography>
      <Typography className={classes.subheading}>
        You will receive our next email update soon. In the meantime, feel free to visit our
        <Link href="https://www.ravenpack.com/page/coronavirus-insights/" target="_blank">
          {" "}
          Coronavirus Insights
        </Link>{" "}
        page.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setDisplayWidget(false)}
        style={{ margin: "10px 0" }}
      >
        Got It
      </Button>
    </div>
  )

  return (
    <AnimateHeight duration={500} height={displayWidget ? "auto" : 0}>
      <WidgetContainer title="Subscribe to Insights">
        {submissionSuccessful ? subscribeSuccess : subscribeForm}
      </WidgetContainer>
    </AnimateHeight>
  )
}

export default Subscribe
