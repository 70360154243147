import React from "react"
import { TextField } from "@material-ui/core"
import { Controller } from "react-hook-form"
import { useDeviceInfo } from "common/hooks"
import useStyles from "./SubscribeForm.styles"

const errorTexts = {
  required: "Please enter your name",
}

const validationRules = {
  required: true,
}

const NameInput = ({ control, error, size }) => {
  const classes = useStyles({ size })
  const { isMobile } = useDeviceInfo()

  const hasError = error !== undefined
  const errorText = hasError ? error.message || errorTexts[error.type] : undefined

  return (
    <Controller
      name="name"
      control={control}
      defaultValue=""
      rules={validationRules}
      render={props => (
        <TextField
          fullWidth
          label="Full Name"
          placeholder="Enter your Full Name"
          variant="filled"
          autoFocus={!isMobile}
          error={hasError}
          helperText={errorText}
          size="small"
          className={classes.input}
          InputProps={{
            className: classes.textInputProps,
          }}
          InputLabelProps={{
            className: classes.inputLabel,
          }}
          {...props}
        />
      )} // props contains: onChange, onBlur and value
    />
  )
}

export default NameInput
