import React from "react"
import * as Sentry from "@sentry/browser"
import { Typography, Button, withStyles } from "@material-ui/core"

const styles = theme => ({
  container: {
    color: theme.palette.error.dark,
    textAlign: "center",
    padding: 20,
    flexGrow: 1,
  },
})

class WidgetErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, eventId: null }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render() {
    const { classes, children } = this.props
    const { hasError, eventId } = this.state
    if (hasError) {
      return (
        <div className={classes.container}>
          <Typography style={{ marginBottom: eventId === undefined ? 10 : 0 }}>
            An error has occurred
          </Typography>
          {eventId && (
            <Button
              variant="outlined"
              color="error"
              onClick={() => Sentry.showReportDialog({ eventId })}
            >
              Report Feedback
            </Button>
          )}
        </div>
      )
    }

    return children
  }
}

export default withStyles(styles)(WidgetErrorBoundary)
