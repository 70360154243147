import { useEffect } from "react"

const useKey = (keyCode, handler) => {
  useEffect(() => {
    const handle = e => {
      if (e.keyCode === keyCode) {
        e.preventDefault()
        handler()
      }
    }

    document.addEventListener("keydown", handle)
    return () => document.removeEventListener("keydown", handle)
  }, [keyCode, handler])
}

export { useKey }
