import React, { useRef } from "react"
import PropTypes from "prop-types"
// Components
// Material-UI
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
// Redux
import { useDispatch, useSelector } from "react-redux"
import IndexTable from "../IndexTable"
import {
  selectCurrentSector,
  selectSectorHeaders,
  selectSectors,
} from "../../redux/sectorsData.duck"
import {
  selectCountry,
  selectMediaExposureTab,
  selectSelectedCountry,
  setMapPosition,
  setMediaExposureTab,
} from "../../redux/userInterface.duck"
import equal from "deep-equal"
import { useAnimatedValue } from "../../hooks/useAnimatedValue"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    "& .animated-value": {
      transition: "color 500ms",
    },
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid #404040",
    zIndex: 10,
  },
  tab: {
    textTransform: "none",
    textSize: 16,
    fontWeight: "bold",
    color: "#fff",
    minWidth: 100,
  },
  yellow: {
    "& .animated-value": {
      color: "#EBB71E !important",
    },
  },
}))

const TabPanel = props => {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      style={{ height: "100%" }}
      {...other}
    >
      {value === index && <div style={{ height: "100% !important" }}>{children}</div>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const a11yProps = index => ({
  id: `scrollable-auto-tab-${index}`,
  "aria-controls": `scrollable-auto-tabpanel-${index}`,
})

const MediaExposureHeader = React.memo(() => {
  const selectedTab = useSelector(selectMediaExposureTab)
  const tableHeaders = useSelector(selectSectorHeaders)
  const dispatch = useDispatch()
  const classes = useStyles()

  const handleChange = (event, newValue) => {
    dispatch(setMediaExposureTab(newValue))
  }

  return (
    <AppBar position="static" className={classes.appBar}>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {/* Tabs */}
        {tableHeaders.map((t, i) => (
          <Tab
            key={i}
            label={
              <Tooltip title={t.description || ""} placement="top">
                <span>{t.label}</span>
              </Tooltip>
            }
            disabled={t.disabled}
            {...a11yProps(i)}
            className={classes.tab}
          />
        ))}
      </Tabs>
    </AppBar>
  )
}, equal)

const MediaExposureBody = ({ table: { label, data, loading }, columns }) => {
  const dispatch = useDispatch()
  const selectedCountry = useSelector(selectSelectedCountry)

  const sorting = {
    enableSort: true,
    defaultSortDir: "desc",
    defaultSortBy: "media",
  }

  const isCountryTable = label === "Country"
  const handleCountryRowClick = React.useCallback(
    d => {
      if (label === "Country") {
        dispatch(selectCountry(d.id))
        dispatch(setMapPosition(undefined))
      }
    },
    [dispatch, label],
  )
  const handleRowClick = isCountryTable ? handleCountryRowClick : undefined

  return (
    <div
      role="tabpanel"
      style={{ height: "100%", minHeight: 400, flexGrow: 1 }}
      id="scrollable-auto-tabpanel"
      aria-labelledby="scrollable-auto-tab"
    >
      <IndexTable
        data={data}
        label={label}
        sorting={sorting}
        loading={loading}
        tableColumns={columns}
        onRowClick={handleRowClick}
        selectedId={selectedCountry}
        columnOrder={["entity", "sent", "sentChange", "media", "mediaChange"]}
      />
    </div>
  )
}

const MediaExposureWidget = () => {
  const index = useSelector(selectMediaExposureTab)
  const data = useSelector(index === 0 ? selectCurrentSector : selectSectors)
  const classes = useStyles()
  const ref = useRef(null)

  useAnimatedValue(data, ref, classes.yellow, 1000)

  const columnsWithSent = [
    { id: "entity", label: "Name", alignLeft: true },
    { id: "sent", label: "Sentiment" },
    {
      id: "sentChange",
      label: "1D CHG",
      colorChange: true,
      percentage: true,
      hideOnMobile: true,
    },
    { id: "media", label: "Media Exp." },
    {
      id: "mediaChange",
      label: "1D CHG",
      colorChange: true,
      percentage: true,
      hideOnMobile: true,
    },
  ]

  const columns = [
    { id: "entity", label: "Name", alignLeft: true },
    { id: "media", label: "Ratio (%)" },
    {
      id: "mediaChange",
      label: "1D CHG",
      colorChange: true,
      percentage: true,
    },
  ]

  return (
    <div className={classes.root} ref={ref}>
      <MediaExposureHeader />
      <MediaExposureBody table={data} columns={index === 0 ? columnsWithSent : columns} />
    </div>
  )
}

export default MediaExposureWidget
