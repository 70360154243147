import React, { useState } from "react"
import moment from "moment"
import { Typography, Tooltip, withStyles } from "@material-ui/core"
import useInterval from "../../hooks/useInterval"

const styles = theme => ({
  container: {
    padding: "10px 5px 10px 5px",
    borderBottom: "solid 1px #555",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "&:hover": {
      background: "#303030",
    },
  },
  head: {
    margin: 0,
    fontSize: 12,
    color: "#808080",
    display: "flex",
    "& > p": {
      margin: 0,
    },
  },
  headline: {
    fontWeight: 700,
    fontSize: 14,
    width: "100%",
    textAlign: "left",
    color: "#FFFFFF",
  },
  source: {
    color: theme.palette.secondary.main,
  },
})

const calculateNiceDate = momentDate => {
  const relativeString = momentDate.from(moment.utc())
  if (relativeString === "a few seconds ago") {
    return "Now"
  }
  return relativeString
}

const StoryCard = ({ headline, timestamp, source, classes, showTooltip }) => {
  const momentDate = moment.utc(timestamp)
  const tooltipTimestamp = momentDate.local().format("MMM Do, HH:mm:ss")

  const [date, setDate] = useState(calculateNiceDate(momentDate))

  useInterval(() => {
    setDate(calculateNiceDate(moment.utc(timestamp)))
  }, 60 * 1000)

  return (
    <div className={classes.container}>
      <div className={classes.head}>
        <Tooltip title={showTooltip ? tooltipTimestamp : ""}>
          <p>{date[0].toUpperCase() + date.slice(1)}</p>
        </Tooltip>
        <p style={{ margin: "0 5px" }}>|</p>
        <p className={classes.source}>{source}</p>
      </div>
      <Typography className={classes.headline}>{headline}</Typography>
    </div>
  )
}

export default withStyles(styles)(StoryCard)
