import { useState } from "react"
import { useForm } from "react-hook-form"
import { useUtmParams } from "common/hooks"
import axios from "axios"
import TagManager from "react-gtm-module"
import config from "../../../config"
import BusinessEmailError from "./BusinessEmailError"

const sendGTMEvent = (event, requestType, pageType) => {
  if (window.dataLayer) {
    const tagManagerArgs = {
      dataLayer: {
        event,
        request_type: requestType,
        page_type: pageType,
      },
    }
    TagManager.dataLayer(tagManagerArgs)
  }
}

const useDataRequestForm = (formType, eventType) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const { utmParams } = useUtmParams()
  const { handleSubmit: rhfHandleSubmit, ...methods } = useForm()

  const onSubmit = async data => {
    setLoading(true)

    const formData = new FormData()
    formData.set("email", data.email)
    formData.set("name", data.name)
    formData.set("coronavirus_updates", data.coronavirusUpdates)
    formData.set("ravenpack_updates", data.ravenpackUpdates)
    formData.set("form_type", "coronavirusData")
    utmParams.length && utmParams.map(({ k, v }) => formData.set(k, v))

    const url = config.baseUrls.subscriptions
    const options = {
      method: "post",
      url,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formData,
    }

    // Send the data and listen for errors
    try {
      await axios(options)
      setSuccess(true)

      sendGTMEvent("request", "Coronavirus Data", "downloadMonitor")

      if (data.coronavirusUpdates) {
        sendGTMEvent("subscribe", "Coronavirus Data", "Newsletter")
        window.localStorage.setItem("hasSubscribed", true)
      }

      if (data.ravenpackUpdates) {
        sendGTMEvent("request", "rpUpdates", "downloadMonitor")
      }
    } catch (error) {
      if (error?.response?.data?.error?.email !== undefined) {
        methods.setError("email", { message: BusinessEmailError })
      } else if (error.message) {
        methods.setError("email", { message: error.message })
      } else {
        methods.setError("email", { message: error })
      }
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = rhfHandleSubmit(onSubmit)

  return { handleSubmit, loading, success, ...methods }
}

export default useDataRequestForm
