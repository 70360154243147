import React from "react"
import { Typography } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment-timezone"
import LineChart from "../LineChartModal/LC"
import numericDisplay from "../../../utils/displayPercentage"
import HorizonSelector from "common/components/HorizonSelector"
import { fetchHistoricRelatedData, selectHistoricRelatedData } from "../../../redux/related.duck"
import { TopicNewsFeed } from "../ModalNewsFeed"
import IndexChip from "../LineChartModal/IndexChip"
import {
  selectComparisonCountry,
  selectComparisonCountryName,
  selectSelectedCountry,
} from "../../../redux/userInterface.duck"
import { selectSelectedCountryName } from "../../../redux/countriesData.duck"
import {
  fetchHistoricRelatedComparisonData,
  selectHistoricRelatedComparisonData,
} from "../../../redux/relatedComparison.duck"
import ComparisonSelector from "./ComparisonSelector"
import { selectRelatedTopics } from "../../../redux/relatedTopics.duck"
import { byKey } from "common/utils/sorts"
import ScaleSelector from "../LineChartModal/ScaleSelector"
import useMobile from "../../../hooks/useMobie"
import { LastUpdated } from "../LineChartModal/LineChartModal.view"

const toTitleCase = str =>
  str
    .split("-")
    .map(p => p[0].toUpperCase() + p.slice(1))
    .join(" ")

const RelatedTopicModalView = ({
  topic,
  data,
  country,
  change,
  comparison,
  horizon,
  lastUpdated,
  description,
  isLoading,
  value,
  scale,
  actions,
  classes,
}) => {
  const selectedCountry = useSelector(selectSelectedCountry)
  const selectedCountryName = useSelector(selectSelectedCountryName)
  const comparisonCountry = useSelector(selectComparisonCountry)
  const comparisonCountryName = useSelector(selectComparisonCountryName)

  const { isMobile } = useMobile()
  const topics = useSelector(selectRelatedTopics)
    .map(t => ({
      id: t.columns.id,
      title: t.columns.entity,
    }))
    .sort(byKey("id", true))

  const comparisonSelector =
    selectedCountry === comparisonCountry
      ? selectHistoricRelatedData
      : selectHistoricRelatedComparisonData
  const comparisonData = useSelector(comparisonSelector(comparison))

  const dataPoints = {
    ALL: data.length,
    YTD: moment().dayOfYear(),
    "3M": 3 * 30,
    "1M": 30,
    "1W": 7 * 24,
    "1D": 24,
  }

  const isDaily = !isLoading && ["1M", "3M", "YTD", "ALL"].includes(horizon)
  const pointsToRemove = isDaily ? 1 : 0
  let chartData = data.slice(data.length - dataPoints[horizon], data.length - pointsToRemove)

  if (comparison) {
    chartData = chartData.map((c, i) => ({
      ...c,
      secondary: (comparisonData[comparisonData.length - dataPoints[horizon] + i] || {}).value,
    }))
  }

  const smallestValue = 0.1
  if (scale === "log") {
    chartData = chartData.map(i => ({
      ...i,
      value: Math.max(i.value || smallestValue, smallestValue),
      secondary: Math.max(i.secondary || smallestValue, smallestValue),
    }))
  }

  const dispatch = useDispatch()
  const handleHorizonChange = h => {
    actions.setHorizon(h)
    if (
      (["1D", "1W"].includes(h) && ["1D", "1W"].includes(horizon)) ||
      (["1M", "3M", "YTD", "ALL"].includes(h) && ["1M", "3M", "YTD", "ALL"].includes(horizon))
    ) {
      return
    }
    dispatch(
      fetchHistoricRelatedData({
        force: true,
        data: {
          topic,
          horizon: h,
          country,
        },
      }),
    )
    dispatch(
      fetchHistoricRelatedComparisonData({
        data: { country: comparisonCountry, topic, horizon: h },
      }),
    )
  }

  const onRemove = () => {
    actions.setComparison(undefined)
  }

  const handleComparisonSelect = (country, topic) => {
    actions.setComparison(topic)
    if (country !== comparisonCountry) {
      dispatch(fetchHistoricRelatedComparisonData({ data: { country, topic, horizon } }))
    }
  }

  const handleScaleChange = val => {
    actions.setScale(val)
  }

  return (
    <div className={classes.main}>
      <div
        style={{
          maxWidth: 800,
          width: isMobile ? "100%" : "60%",
          zIndex: 10,
          padding: isMobile ? 0 : "0 0 20px 20px",
        }}
      >
        <div className={classes.header}>
          <div className={classes.valueContainer}>
            <Typography className={classes.currentValue}>
              {numericDisplay(value, false, false, 2)}
            </Typography>
            <Typography className={classes.valueLabel}>Latest Value</Typography>
          </div>
          <div className={classes.valueContainer}>
            <Typography className={classes.change}>
              {numericDisplay(change, change, true, 2)}
            </Typography>
            <Typography className={classes.valueLabel} style={{ color: "#EDB820" }}>
              Daily Change
            </Typography>
          </div>
          {!isMobile && <LastUpdated value={lastUpdated} />}
        </div>
        <div className={classes.lineChartContainer}>
          <div className={classes.controls}>
            <HorizonSelector
              horizons={["1D", "1W", "1M", "3M", "YTD", "ALL"]}
              selected={horizon}
              onChange={handleHorizonChange}
            />
            <span className={classes.options}>
              <ScaleSelector value={scale} onChange={handleScaleChange} />
              <ComparisonSelector
                topics={topics}
                horizon={horizon}
                onChange={handleComparisonSelect}
              />
            </span>
          </div>
          {comparison !== undefined && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <IndexChip
                title={toTitleCase(topic)}
                country={selectedCountryName}
                variant="secondary"
                loading={false}
              />
              <IndexChip
                title={comparison && toTitleCase(comparison)}
                country={comparisonCountryName}
                variant="altLine"
                style={{ marginLeft: 10 }}
                onRemove={onRemove}
                loading={false}
              />
            </div>
          )}
          {isLoading ? (
            <div style={{ height: 335 }} />
          ) : (
            <LineChart
              data={chartData}
              horizon={horizon}
              primaryDataKey="value"
              primaryScale={scale}
              hasSecondary={comparison !== undefined}
              singleAxis
              secondaryScale="linear"
              secondaryDataKey="secondary"
              seriesTitles={{
                primary: `${comparison ? `${selectedCountryName}: ` : ""}${toTitleCase(topic)}`,
                comparison: comparison && `${comparisonCountryName}: ${toTitleCase(comparison)}`,
              }}
              decimalPlaces={{ primary: 2, secondary: 2 }}
              aspect={isMobile ? 1 : 2.5}
              classes={classes}
            />
          )}
        </div>
        <Typography className={classes.description}>{description}</Typography>
        {isMobile && <LastUpdated value={lastUpdated} />}
      </div>
      <TopicNewsFeed topic={topic} />
    </div>
  )
}

export default RelatedTopicModalView
