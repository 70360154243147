import React, { memo } from "react"
import { Paper, Modal, Typography, makeStyles, Button } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import { Carousel } from "react-responsive-carousel"
import InfoIcon from "./InfoIcon"
import NewsFeedIcon from "../assets/news_feed_icon.png"
import LineChartIcon from "../assets/line_chart_icon.png"
import MapIcon from "../assets/map_icon.png"

import { seenIntro, selectIsMobile, selectSeenIntro } from "../redux/userInterface.duck"
import "react-responsive-carousel/lib/styles/carousel.min.css"

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    maxWidth: 900,
    padding: "40px 20px",
    outline: "none",
    border: "solid 2px #404040",
    textAlign: "center",
    "& .control-dots": {
      padding: 0,
      position: "relative",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  showcaseContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-around",
    padding: "20px 0",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
      padding: 0,
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
  subheader: {
    fontSize: 14,
    marginTop: -5,
    color: "#C0C0C0",
  },
  text: {
    marginTop: 10,
    marginBottom: 20,
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  bottomText: {
    margin: "10px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  infoIcon: {
    fill: "#FFFFFF",
    margin: "0 5px",
    height: 18,
    width: 18,
  },
  bottomLink: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },
}))

const useShowcaseStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flexStart",
    height: "100%",
    maxWidth: 250,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px",
      background: "#252525",
      maxHeight: "50vh",
      overflow: "auto",
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  body: {
    fontSize: 16,
    color: "#C0C0C0",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  image: {
    maxWidth: 200,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 150,
    },
  },
}))

const ShowcaseItem = ({ image, title, description }) => {
  const classes = useShowcaseStyles()
  return (
    <div className={classes.container}>
      <img src={image} className={classes.image} alt={title} />
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.body}>{description}</Typography>
    </div>
  )
}

const showcaseItems = [
  {
    image: LineChartIcon,
    title: "Media Analytics",
    description:
      "Explore news indexes that track important themes tied to the Coronavirus, such as panic, media hype, and fake news.",
  },
  {
    image: MapIcon,
    title: "COVID-19 Case Tracking",
    description:
      "Monitor the geographical impact and changes in confirmed cases, recoveries, and fatalities by country.",
  },
  {
    image: NewsFeedIcon,
    title: "Live News Feed",
    description: "Real-time updates on relevant news from thousands of sources across the globe.",
  },
]

const IntroductionModal = () => {
  const dispatch = useDispatch()
  const hasSeenIntro = useSelector(selectSeenIntro)
  const mobile = useSelector(selectIsMobile)
  const classes = useStyles()

  const handleClose = () => {
    dispatch(seenIntro())
  }

  const Container = ({ children, ...props }) =>
    !mobile ? (
      <div {...props}>{children}</div>
    ) : (
      <Carousel {...props} showThumbs={false} width={250} showStatus={false} showArrows={false}>
        {children}
      </Carousel>
    )

  return (
    <Modal open={!hasSeenIntro} className={classes.container} onClose={handleClose}>
      <Paper className={classes.paper}>
        <Typography className={classes.title}>Coronavirus Media Monitor</Typography>
        <Typography className={classes.text}>
          This live and interactive website was built by RavenPack to track the latest news and
          trending topics surrounding the COVID-19 pandemic.
        </Typography>
        <Container className={classes.showcaseContainer}>
          {showcaseItems.map(i => (
            <ShowcaseItem key={i.title.replace(" ", "_")} {...i} />
          ))}
        </Container>

        {mobile ? (
          <Typography className={classes.bottomText}>
            By using our site, you consent to the use of cookies unless you have disabled them. See
            Cookie Policy.{" "}
            <a href="https://www.ravenpack.com/cookie-policy/" className={classes.bottomLink}>
              Cookies Policy
            </a>
            .
          </Typography>
        ) : (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Typography className={classes.bottomText}>Hover over the</Typography>
            <InfoIcon className={classes.infoIcon} size={18} />{" "}
            <Typography className={classes.bottomText}>
              icon in widgets to read more about the data being showcased.
            </Typography>
          </div>
        )}

        <Button color="primary" variant="contained" onClick={handleClose}>
          Got it
        </Button>
      </Paper>
    </Modal>
  )
}

export default memo(IntroductionModal)
