import { createSelector } from "@reduxjs/toolkit"
import createFetchReducer from "common/state/createFetch"
import loadingStates from "common/state/loadingStates"
import APIClient from "../APIClient"

const slice = createFetchReducer(
  {
    name: "HISTORICAL_RELATED_COMPARISON_TOPICS",
    url: ({ country, horizon }) => {
      const filename = ["1D", "1W"].includes(horizon) ? "related_hourly" : "related_ytd"
      return country === undefined ? `/${filename}.json` : `/country/${country}/${filename}.json`
    },
    path: "relatedTopicsComparison",
  },
  APIClient,
)

export const fetchHistoricRelatedComparisonData = slice.actions.fetch
export const clearHistoricRelatedComparisonData = slice.actions.clear

const { reducer } = slice

export const selectHistoricRelatedComparisonData = topic => state => {
  if (!topic) {
    return []
  }

  const rawData = slice.selectors.data(state)[topic] || {}
  return Object.keys(rawData)
    .map(x => ({ ts: x, value: rawData[x] }))
    .sort((a, b) => {
      if (a.ts < b.ts) {
        return -1
      }
      if (a.ts > b.ts) {
        return 1
      }
      return 0
    })
}

export const selectHistoricRelatedComparisonIsLoading = createSelector(
  [slice.selectors.loadingValue],
  loadingValue => loadingValue !== loadingStates.IDLE,
)
export const selectHistoricRelatedComparisonLastUpdated = slice.selectors.lastUpdated

export default reducer
