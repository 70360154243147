import React from "react"
import { Checkbox, FormControlLabel, Link, Typography } from "@material-ui/core"
import { Controller } from "react-hook-form"
import useStyles from "./SubscribeForm.styles"

const validationRules = {
  required: true,
}

const GdprCheckbox = ({ control, error }) => {
  const classes = useStyles()

  const hasError = error !== undefined

  const checkboxLabel = (
    <Typography className={classes.gdprLabel}>
      {hasError && (
        <>
          <span style={{ color: "#f44336" }}>Please check this box if you want to proceed</span>{" "}
          <br />
        </>
      )}
      By submitting this form you agree to the terms of the RavenPack data license and consent to
      receive communications from the RavenPack team. We will process your personal data with the
      purpose of managing your personal account on RavenPack and offering our services. You can
      exercise your rights of access, rectification, erasure, restriction of processing, data
      portability and objection by emailing us at{" "}
      <Link href="mailto:privacy@ravenpack.com" color="primary">
        privacy@ravenpack.com
      </Link>
      . For more information, you can check out our{" "}
      <Link href="https://www.ravenpack.com/privacy/" target="_blank" color="primary">
        Privacy Policy
      </Link>
      .
    </Typography>
  )

  return (
    <Controller
      control={control}
      name="gdpr"
      type="checkbox"
      rules={validationRules}
      defaultValue={false}
      render={({ onBlur, onChange, value }) => {
        return (
          <FormControlLabel
            classes={{
              root: classes.termsContainer,
            }}
            control={
              <Checkbox
                checked={value}
                onBlur={onBlur}
                onChange={e => onChange(e.target.checked)}
                color="primary"
                classes={{
                  root: classes.checkbox,
                }}
              />
            }
            label={checkboxLabel}
          />
        )
      }}
    />
  )
}

export default GdprCheckbox
