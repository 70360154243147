import React, { memo } from "react"
import moment from "moment-timezone"
import { makeStyles } from "@material-ui/core/styles"
import { useDispatch, useSelector } from "react-redux"
import { Typography } from "@material-ui/core"
import { selectGlobalLastUpdated } from "../../redux/globalSelectors"
import AboutUs from "./AboutLink"
import { selectCookiesAccepted, showIntro } from "../../redux/userInterface.duck"

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 1640,
    width: "100%",
    margin: "0 auto",
    padding: "0 23px 20px 23px",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      padding:
        "0px env(safe-area-inset-right, 10px) env(safe-area-inset-right, 20px)  env(safe-area-inset-left, 10px)",
      marginTop: 20,
    },
  },
  footerLeft: {
    display: "flex",
    marginBottom: 10,
    flexDirection: "column",
  },
  footerLeftTop: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 10,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  footerRight: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 10,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  footerLink: {
    color: "#CCCCCC",
    textDecoration: "none",
    marginLeft: 50,
    fontSize: 14,
    cursor: "pointer",
    "&:first-child": {
      marginLeft: 0,
    },
    "&:hover": {
      color: "#FFFFFF",
    },
    [theme.breakpoints.down("xs")]: {
      width: "80vw",
      marginLeft: 0,
    },
  },
  footerSourceLink: {
    color: "#cccccc",
    textDecoration: "none",
    "&:hover": {
      color: "#FFFFFF",
    },
  },
  lastUpdatedTitle: {
    fontWeight: 700,
    marginRight: 5,
  },
  lastUpdatedValue: {
    color: theme.palette.secondary.main,
  },
  divider: {
    color: "#808080",
    margin: "0 10px",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  copyright: {
    color: "#808080",
  },
}))

const Footer = () => {
  const classes = useStyles()
  const lastUpdate = useSelector(selectGlobalLastUpdated)
  const userTimezone = moment.tz.guess()
  const lastUpdateDisplay = moment.utc(lastUpdate).tz(userTimezone).format("MMM Do, HH:mm:ss z")
  const cookiesAccepted = useSelector(selectCookiesAccepted)

  const dispatch = useDispatch()

  const showIntroModal = () => dispatch(showIntro())

  return (
    <div className={classes.container} style={{ marginBottom: cookiesAccepted ? 0 : 40 }}>
      <span className={classes.footerLeft}>
        <div className={classes.footerLeftTop}>
          <span className={classes.lastUpdateContainer}>
            <span className={classes.lastUpdatedTitle}>Last Updated:</span>
            <span className={classes.lastUpdatedValue}>{`${lastUpdateDisplay}`}</span>
          </span>
          <span className={classes.divider}>|</span>
          <span className={classes.copyright}>
            RavenPack &copy;
            {moment.utc().format("YYYY")}
          </span>
        </div>
        <span className={classes.copyright}>
          Sources:{" "}
          <a
            className={classes.footerSourceLink}
            href="https://www.ravenpack.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            RavenPack
          </a>
          ,{" "}
          <a
            className={classes.footerSourceLink}
            href="https://github.com/CSSEGISandData/COVID-19"
            target="_blank"
            rel="noopener noreferrer"
          >
            Johns Hopkins University (CSSE)
          </a>
          ,{" "}
          <a
            className={classes.footerSourceLink}
            href="https://www.worldometers.info/coronavirus/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Worldometer
          </a>
        </span>
      </span>
      <span className={classes.footerRight}>
        <Typography className={classes.footerLink} onClick={showIntroModal}>
          Introduction
        </Typography>
        <AboutUs />
        <a
          className={classes.footerLink}
          href="https://www.ravenpack.com/cookie-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cookie Policy
        </a>
        <a
          className={classes.footerLink}
          href="https://www.ravenpack.com/privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        <a
          className={classes.footerLink}
          href="https://www.ravenpack.com/terms/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Service
        </a>
      </span>
    </div>
  )
}

export default memo(Footer)
