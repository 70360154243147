import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { pathToRegexp } from "path-to-regexp"
import ChartWidget from "../ChartWidget/ChartWidget.view"
import WidgetContainer from "../WidgetContainer"
import { setModal } from "../../redux/modal.duck"
import { INDEX_URL_MAP } from "../../constants"
import useMobile from "../../hooks/useMobie"

const GenericChartWidget = ({ widgetInfo, selector, isDailyLoading, index, percentage }) => {
  const data = useSelector(selector)
  const dispatch = useDispatch()
  const { isMobile } = useMobile()

  const chartData = data ? data.slice(data.length - 49) : undefined

  const handleClick = () => {
    const { pathname: path, search } = window.location
    const regexp = pathToRegexp("/:country(.*)")
    const result = regexp.exec(path)

    const country = result !== null ? result[1] : undefined
    const urlCountry = country || "worldwide"

    window.history.replaceState({}, null, `/${urlCountry}/${INDEX_URL_MAP[index]}${search}`)

    dispatch(
      setModal({
        title: `Coronavirus ${widgetInfo.title}`,
        description: widgetInfo.description,
        type: "line",
        data: {
          primaryIndex: index,
          horizon: "ALL",
          scale: "linear",
        },
      }),
    )
  }

  return (
    <WidgetContainer {...widgetInfo} openModal={!isDailyLoading ? handleClick : undefined}>
      <ChartWidget
        data={chartData}
        onClick={!isDailyLoading && !isMobile ? handleClick : undefined}
        percentage={percentage}
      />
    </WidgetContainer>
  )
}

export default GenericChartWidget
