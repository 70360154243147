import { useEffect, useRef } from "react"

const useInterval = (func, interval = 1000, initalRequest = false) => {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = func
  }, [func])

  // Set up the interval.
  useEffect(() => {
    const tick = () => {
      savedCallback.current()
    }

    if (initalRequest) {
      tick()
    }

    const id = setInterval(tick, interval)
    return () => clearInterval(id)
  }, [interval, initalRequest])
}

export default useInterval
