import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "@material-ui/core"
import darkTheme from "./themes/dark"
import lightTheme from "./themes/light"

const GlobalThemeProvider = ({ dark, children }) => {
  const theme = dark ? darkTheme : lightTheme
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

GlobalThemeProvider.propTypes = {
  dark: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

GlobalThemeProvider.defaultProps = {
  dark: false,
}

export default GlobalThemeProvider
