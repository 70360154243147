import { useEffect } from "react"

const useAnimatedValue = (state, ref, animatedStyle, milliseconds) => {
  useEffect(() => {
    if (ref.current) {
      ref.current.classList.add(animatedStyle)
      setTimeout(() => ref.current && ref.current.classList.remove(animatedStyle), milliseconds)
    }
  }, [state, ref, animatedStyle, milliseconds])
}

export { useAnimatedValue }
