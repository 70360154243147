import React from "react"
import { Typography } from "@material-ui/core"
import { EmailShareButton } from "react-share"
import Icon from "../Icon"
import EmailLogo from "../../assets/logos/email.svg"
import { PAGE_TITLE, PAGE_DESCRIPTION } from "../../constants"

const EmailShare = ({ hideText, style, ...props }) => {
  return (
    <EmailShareButton
      url={window.location.href}
      subject={PAGE_TITLE}
      body={PAGE_DESCRIPTION}
      style={{ display: "flex", ...style }}
      {...props}
    >
      <Icon src={EmailLogo} />
      {!hideText && <Typography style={{ marginLeft: 10 }}>Share by Email</Typography>}
    </EmailShareButton>
  )
}

EmailShare.defaultProps = {
  hideText: false,
}

export default EmailShare
