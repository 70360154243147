import React from "react"
import { Typography } from "@material-ui/core"
import { LinkedinShareButton } from "react-share"
import LinkedInLogo from "../../assets/logos/linkedin.svg"
import { PAGE_TITLE, PAGE_DESCRIPTION } from "../../constants"
import Icon from "../Icon"

const LinkedInShare = ({ hideText, style, ...props }) => {
  return (
    <LinkedinShareButton
      url="https://coronavirus.ravenpack.com/?utm_campaign=covid-dashboard&utm_medium=referral&utm_source=linkedin&utm_content=share&utm_term="
      title={PAGE_TITLE}
      summary={PAGE_DESCRIPTION}
      source="RavenPack"
      style={{ display: "flex", ...style }}
      {...props}
    >
      <Icon src={LinkedInLogo} />
      {!hideText && <Typography style={{ marginLeft: 10 }}>Share on LinkedIn</Typography>}
    </LinkedinShareButton>
  )
}

export default LinkedInShare
