import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { pathToRegexp } from "path-to-regexp"
import { Paper, Modal, Typography } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import CloseIcon from "common/components/CloseIcon"
import LineChartModal from "./LineChartModal/LineChartModal.style"
import ErrorBoundary from "./ModalErrorBoundary"
import ToggleView from "../GeographicalExposure/ToggleView"
import { GeographicalExposure, TAB_INDEX_MAP } from "../GeographicalExposure/GeographicalExposure"
import RelatedTopicModalView from "./RelatedTopicModal"
import useQueryParams from "common/hooks/useQueryParams"
import { useSelector } from "react-redux"
import { selectShowMap } from "../../redux/userInterface.duck"
import { makeStyles } from "@material-ui/core/styles"
import BackIcon from "../BackIcon"
import useMobile from "../../hooks/useMobie"

const modalComponent = (data, description, onTabChange) => ({
  line: <LineChartModal {...data} description={description} />,
  topic: <RelatedTopicModalView {...data} description={description} />,
  geoExp: <GeographicalExposure height="calc(85vh - 130px)" onTabChange={onTabChange} />,
})

const useHeaderStyles = makeStyles(theme => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px",
    [theme.breakpoints.down("sm")]: {
      background: "#252325",
      padding:
        "env(safe-area-inset-top, 20px) env(safe-area-inset-right, 20px) 20px env(safe-area-inset-left, 20px)",
      paddingTop: 20,
    },
  },
  title: {
    margin: 0,
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  closeIcon: {
    fill: "#808080",
    cursor: "pointer",
    "&:hover": {
      fill: "#FFFFFF",
    },
    height: 20,
    width: 20,
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
  },
}))

const ModalHeader = ({ title, country, onClose, HeaderActions }) => {
  const classes = useHeaderStyles()
  const { isMobile } = useMobile()
  return (
    <div className={classes.header}>
      <span style={{ display: "flex", alignItems: "center" }}>
        {isMobile && (
          <div className={classes.buttonContainer}>
            <BackIcon
              className={classes.closeIcon}
              onClick={onClose}
              width={30}
              style={{ width: 30, height: 30, marginRight: 20 }}
            />
          </div>
        )}
        {country ? (
          <Typography id="simple-modal-title" className={classes.title} variant="h5">
            {`${title} / ${country}`}
          </Typography>
        ) : (
          <Skeleton height={32} width={100} style={{ marginLeft: 5 }} />
        )}
      </span>

      <div className={classes.buttonContainer}>
        <HeaderActions />
        {!isMobile && <CloseIcon className={classes.closeIcon} onClick={onClose} />}
      </div>
    </div>
  )
}

const useResetScroll = () => {
  const { isMobile } = useMobile()
  const [scrollPos] = useState([window.scrollX, window.scrollY])
  useEffect(() => {
    window.scrollTo(0, 0)
    return () => {
      window.scrollTo(scrollPos[0], scrollPos[1])
    }
  }, [isMobile, scrollPos])
}

const GenericModal = ({
  open,
  title,
  description,
  type,
  data,
  actions,
  selectedCountryName,
  classes,
  children,
  fullscreen,
}) => {
  useResetScroll()
  const isGeoExp = type === "geoExp"
  const { queryParams, setQueryParams, encodeQueryParams } = useQueryParams()
  const modalQueryParams = ["h", "s", "cc", "ci", "i", "v"]
  const showMap = useSelector(selectShowMap)

  const handleTabChange = value => {
    setQueryParams({ ...queryParams, i: TAB_INDEX_MAP[value] })
  }

  const handleClose = () => {
    const { pathname: path } = window.location
    const regexp = pathToRegexp("/:country/:index?/:topic?")
    const result = regexp.exec(path)

    const country = result !== null ? result[1] : undefined
    const urlCountry = country === "worldwide" ? undefined : country

    const query = Object.entries(queryParams).reduce(
      (acc, [k, v]) => (modalQueryParams.includes(k) ? acc : { ...acc, [k]: v }),
      {},
    )

    window.history.replaceState(
      {},
      null,
      `/${urlCountry ? urlCountry : ""}${encodeQueryParams(query)}`,
    )

    actions.closeModal()
  }

  const ModalContent = ({ HeaderActions }) => {
    return (
      <Paper
        className={fullscreen ? classes.fullscreenPaper : classes.paper}
        style={!fullscreen && isGeoExp ? { width: "80%", maxWidth: "unset", height: "85vh" } : {}}
      >
        <ModalHeader
          title={title}
          country={selectedCountryName}
          onClose={handleClose}
          HeaderActions={HeaderActions}
        />
        <ErrorBoundary>
          <div className={classes.content}>
            {modalComponent(data, description, handleTabChange)[type]}
          </div>
        </ErrorBoundary>
      </Paper>
    )
  }

  // if (fullscreen) {
  //   return open ? <ModalContent /> : children
  // }

  const HeaderActions = () => {
    return (
      isGeoExp && (
        <ToggleView
          onChange={() => setQueryParams({ ...queryParams, v: showMap ? "table" : "map" })}
        />
      )
    )
  }

  return (
    <>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
        className={classes.container}
      >
        <ModalContent HeaderActions={HeaderActions} />
      </Modal>
      {children}
    </>
  )
}

GenericModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.oneOf(["line", "geoExp", "topic"]),
  selectedCountryName: PropTypes.string,
  actions: PropTypes.shape({
    closeModal: PropTypes.func.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    paper: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({}).isRequired,
}

GenericModal.defaultProps = {
  title: "",
  description: undefined,
  selectedCountryName: undefined,
  type: "line",
  open: false,
}

export default GenericModal
