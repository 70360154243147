export const EXPECTED_DATA_VERSION = "1.5"

export const PAGE_TITLE = "Coronavirus Media Monitor | RavenPack"
export const PAGE_DESCRIPTION =
  "The Coronavirus Media Monitor tracks the latest info on the Novel Coronavirus and measures the current levels of panic, misinformation, and other topics conveyed in the media."

export const urlNameToIso = {
  usa: "US",
  spain: "ES",
  italy: "IT",
  germany: "DE",
  france: "FR",
  china: "CN",
  iran: "IR",
  "united-kingdom": "GB",
  turkey: "TR",
  switzerland: "CH",
  belgium: "BE",
  netherlands: "NL",
  canada: "CA",
  austria: "AT",
  portugal: "PT",
  brazil: "BR",
  "north-korea": "KP",
  "south-korea": "KR",
  israel: "IL",
  sweden: "SE",
  russia: "RU",
  australia: "AU",
  norway: "NO",
  ireland: "IE",
  india: "IN",
  chile: "CL",
  denmark: "DK",
  czechia: "CZ",
  poland: "PL",
  romania: "RO",
  pakistan: "PK",
  malaysia: "MY",
  japan: "JP",
  philippines: "PH",
  ecuador: "EC",
  luxembourg: "LU",
  "saudi-arabia": "SA",
  indonesia: "ID",
  peru: "PE",
  serbia: "RS",
  mexico: "MX",
  finland: "FI",
  thailand: "TH",
  panama: "PA",
  uae: "AE",
  "dominican-republic": "DO",
  qatar: "QA",
  greece: "GR",
  "south-africa": "ZA",
  argentina: "AR",
  iceland: "IS",
  colombia: "CO",
  singapore: "SG",
  ukraine: "UA",
  algeria: "DZ",
  egypt: "EG",
  croatia: "HR",
  "new-zealand": "NZ",
  estonia: "EE",
  morocco: "MA",
  iraq: "IQ",
  slovenia: "SI",
  moldova: "MD",
  "hong-kong": "HK",
  lithuania: "LT",
  belarus: "BY",
  armenia: "AM",
  hungary: "HU",
  bahrain: "BH",
  bosnia: "BA",
  kuwait: "KW",
  azerbaijan: "AZ",
  kazakhstan: "KZ",
  cameroon: "CM",
  macedonia: "MK",
  "north-macedonia": "MK",
  tunisia: "TN",
  slovakia: "SK",
  bulgaria: "BG",
  lebanon: "LB",
  latvia: "LV",
  andorra: "AD",
  uzbekistan: "UZ",
  cyprus: "CY",
  "costa-rica": "CR",
  afghanistan: "AF",
  uruguay: "UY",
  cuba: "CU",
  albania: "AL",
  taiwan: "TW",
  oman: "OM",
  "burkina-faso": "BF",
  jordan: "JO",
  reunion: "RE",
  "channel-islands": "JE",
  "cote-divoire": "CI",
  honduras: "HN",
  malta: "MT",
  ghana: "GH",
  "san-marino": "SM",
  mauritius: "MU",
  "palestinian-territory": "PS",
  niger: "NE",
  vietnam: "VN",
  montenegro: "ME",
  nigeria: "NG",
  senegal: "SN",
  kyrgyzstan: "KG",
  georgia: "GE",
  bolivia: "BO",
  "sri-lanka": "LK",
  "faroe-islands": "FO",
  kenya: "KE",
  venezuela: "VE",
  mayotte: "YT",
  bangladesh: "BD",
  drc: "CD",
  martinique: "MQ",
  "isle-of-man": "IM",
  guadeloupe: "GP",
  brunei: "BN",
  guinea: "GN",
  cambodia: "KH",
  paraguay: "PY",
  gibraltar: "GI",
  "trinidad-and-tobago": "TT",
  rwanda: "RW",
  djibouti: "DJ",
  madagascar: "MG",
  "el-salvador": "SV",
  monaco: "MC",
  guatemala: "GT",
  liechtenstein: "LI",
  "french-guiana": "GF",
  aruba: "AW",
  togo: "TG",
  barbados: "BB",
  jamaica: "JM",
  mali: "ML",
  ethiopia: "ET",
  uganda: "UG",
  congo: "CG",
  macao: "MO",
  "french-polynesia": "PF",
  zambia: "ZM",
  "cayman-islands": "KY",
  bermuda: "BM",
  "sint-maarten": "SX",
  bahamas: "BS",
  "guinea-bissau": "GW",
  "saint-martin": "MF",
  eritrea: "ER",
  guyana: "GY",
  gabon: "GA",
  benin: "BJ",
  haiti: "HT",
  tanzania: "TZ",
  myanmar: "MM",
  maldives: "MV",
  syria: "SY",
  libya: "LY",
  "new-caledonia": "NC",
  namibia: "NA",
  "equatorial-guinea": "GQ",
  angola: "AO",
  dominica: "DM",
  "antigua-and-barbuda": "AG",
  mongolia: "MN",
  fiji: "FJ",
  laos: "LA",
  sudan: "SD",
  "saint-lucia": "LC",
  liberia: "LR",
  curacao: "CW",
  grenada: "GD",
  greenland: "GL",
  seychelles: "SC",
  "saint-kitts-and-nevis": "KN",
  swaziland: "SZ",
  suriname: "SR",
  chad: "TD",
  mozambique: "MZ",
  zimbabwe: "ZW",
  nepal: "NP",
  "turks-and-caicos-islands": "TC",
  "central-african-republic": "CF",
  malawi: "MW",
  somalia: "SO",
  "cabo-verde": "CV",
  belize: "BZ",
  "saint-vincent-and-the-grenadines": "VC",
  vatican: "VA",
  montserrat: "MS",
  "sierra-leone": "SL",
  "st-barth": "BL",
  nicaragua: "NI",
  mauritania: "MR",
  botswana: "BW",
  bhutan: "BT",
  gambia: "GM",
  anguilla: "AI",
  "british-virgin-islands": "VG",
  burundi: "BI",
  "papua-new-guinea": "PG",
  "caribbean-netherlands": "BQ",
  "timor-leste": "TL",
  guam: "GU",
  "us-virgin-islands": "VI",
  "puerto-rico": "PR",
}

export const isoToUrlName = {
  ...Object.keys(urlNameToIso).reduce((acc, k) => ({ ...acc, [urlNameToIso[k]]: k }), {}),
  MK: "north-macedonia",
}

export const MODAL_DATA = {
  cases: {
    title: "COVID-19 Cases",
    fullTitle: "COVID-19 Cases",
    description: "COVID-19 Cases includes the total number of confirmed cases.",
    label: "Changes from the previous day",
    type: "line",
    index: "total_cases",
  },
  deaths: {
    title: "COVID-19 Deaths",
    fullTitle: "COVID-19 Deaths",
    description: "COVID-19 Deaths includes the total number of deaths.",
    label: "Changes from the previous day",
    type: "line",
    index: "total_deaths",
  },
  recovered: {
    title: "COVID-19 Recoveries",
    fullTitle: "COVID-19 Recoveries",
    description: "COVID-19 Cases includes the total number of recovered patients.",
    label: "Changes from the previous day",
    type: "line",
    index: "total_recovered",
  },
  panic: {
    title: "Panic Index",
    fullTitle: "Coronavirus Panic Index",
    description:
      "The Coronavirus Panic Index measures the level of news chatter that makes reference to panic or hysteria and coronavirus. Values range between 0 and 100 where a value of 7.00 indicates that 7 percent of all news globally is talking about panic and COVID-19. The higher the index value, the more references to panic found in the media.",
    label: "The % of news about panic and the Coronavirus",
    type: "line",
  },
  hype: {
    title: "Media Hype Index",
    fullTitle: "Coronavirus Media Hype Index",
    description:
      "The Coronavirus Media Hype Index measures the percentage of news talking about the novel coronavirus. Values range between 0 and 100 where a value of 75.00 indicates that 75 percent of all news globally is talking about COVID-19.",
    label: "The % of total news about the Coronavirus",
    type: "line",
  },
  media: {
    title: "Media Coverage Index",
    fullTitle: "Coronavirus Media Coverage Index",
    description:
      "The Coronavirus Media Coverage Index calculates the percentage of all news sources covering the topic of the novel coronavirus. Values range between 0 and 100 where a value of 60.00 means that 60 percent of all sampled news providers are currently covering stories about the COVID-19.",
    label: "The % of all news sources covering the Coronavirus",
    type: "line",
  },
  infodemic: {
    title: "Infodemic Index",
    fullTitle: "Coronavirus Infodemic Index",
    description:
      "The Coronavirus Infodemic Index calculates the percentage of all entities (places, companies, etc.) that are somehow linked to COVID-19. Values range between 0 and 100 where a value of 60.00 means that 60 percent of all entities covered by the media are being linked or co-mentioned with COVID-19.",
    label: "The % of entities in the news linked to the coronavirus",
    type: "line",
  },
  fake: {
    title: "Fake News Index",
    fullTitle: "Coronavirus Fake News Index",
    description:
      "The Coronavirus Fake News Index measures the level of media chatter about the novel virus that makes reference to misinformation or fake news alongside COVID-19. Values range between 0 and 100 where a value of 2.00 indicates that 2 percent of all news globally is talking about fake news and COVID-19. The higher the index value, the more references to fake news found in the media.",
    label: "The % of news that links the Coronavirus to fake news",
    type: "line",
  },
  sent: {
    title: "Sentiment Index",
    fullTitle: "Coronavirus Sentiment Index",
    description:
      "The Coronavirus Sentiment Index measures the level of sentiment across all entities mentioned in the news alongside the coronavirus. The index ranges between -100 and 100 where a value of 100 is the most positive sentiment, -100 is the most negative, and 0 is neutral.",
    label: "The level of sentiment from all news about the Coronavirus",
    type: "line",
  },
}

export const URL_PARAM_MAP = {
  sentiment: "sent",
  panic: "panic",
  "fake-news": "fake",
  infodemic: "infodemic",
  "media-coverage": "media",
  "media-hype": "hype",
  cases: "cases",
  deaths: "deaths",
  recovered: "recovered",
}

export const INDEX_URL_MAP = Object.entries(URL_PARAM_MAP).reduce(
  (acc, [k, v]) => ({ ...acc, [v]: k }),
  {},
)
