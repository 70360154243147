export default {
  environment: "production",
  displayEnvironment: false,
  baseUrls: {
    data: "https://coronavirus.ravenpack.com/data",
    subscriptions: "https://app.ravenpack.com/public-form/",
  },
  tagManager: {
    enabled: true,
    id: "GTM-5Z44G44",
  },
  sentry: {
    enabled: true,
    dsn: "https://1b842044c57e4667b6f17a23614f8e79@sentry.ravenpack.com/7",
  },
}
