import React, { useEffect } from "react"
import { pathToRegexp } from "path-to-regexp"
// Material UI
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
// Components
import CasesWidget from "./components/CasesWidget"
import CookieConsent from "./components/CookieConsent"
import CountrySelector from "./components/CountrySelector"
import Footer from "./components/Footer/Footer"
import GeographicalExposure from "./components/GeographicalExposure"
import Header from "./components/Header"
import IntroductionModal from "./components/IntroductionModal"
import DataDownloadModal from "./components/Modal/DataDownloadModal"
import MediaExposureWidget from "./components/MediaExposureWidget/MediaExposureWidget.view"
import RelatedTopicsWidget from "./components/RelatedTopics"
import Subscribe from "./components/Subscribe"
import WidgetContainer from "./components/WidgetContainer"
import {
  InfodemicWidget,
  FakeNewsWidget,
  GlobalSentimentWidget,
  HypeWidget,
  MediaCoverageWidget,
  PanicWidget,
} from "./components/Widgets"
// Redux
import store from "./redux/store"
import { useDispatch, useSelector } from "react-redux"
import { getSectorsData } from "./redux/sectorsData.duck"
import { getCountryStories } from "./redux/stories.duck"
import { getCountryRelatedTopics } from "./redux/relatedTopics.duck"
import { EXPECTED_DATA_VERSION, MODAL_DATA, urlNameToIso, URL_PARAM_MAP } from "./constants"
import {
  getDailyCountryData,
  getDailyData,
  getHourlyCountryData,
  getHourlyData,
} from "./redux/indexes.duck"
import {
  getCountriesData,
  getCountryHistoricCases,
  getHistoricCases,
} from "./redux/countriesData.duck"
import {
  selectIsMobile,
  selectSelectedCountry,
  setComparisonCountry,
  setCountryIntervalIds,
  setMapTabIndex,
  setShowMap,
} from "./redux/userInterface.duck"
// Utils / Hooks
import ApiClient from "./APIClient"
import useInterval from "./hooks/useInterval"
import useMobileWatcher from "./hooks/useMobileWatcher"
import useUtmParams from "./hooks/useUtmParams"
import { setModal } from "./redux/modal.duck"
import useQueryParams from "common/hooks/useQueryParams"
import { fetchComparisonData } from "./redux/comparison.duck"
import NewsFeedWidget from "./components/NewsFeed/NewsFeedWidget"

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  mainContainer: {
    width: "100%",
    maxWidth: 1640,
    margin: "0 auto",
    padding:
      "env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)",
    paddingTop: 0,
    paddingBottom: 0,
    // display: "flex",
  },
  paper: {
    margin: "10px 0",
  },
  map: {
    height: 300,
  },
})

const showInitialModal = params => {
  const { pathname: path, search } = window.location
  const regexp = pathToRegexp("/:country/:index/:topic?")
  const result = regexp.exec(path)

  if (!result) {
    return
  }
  const { 1: country, 2: urlIndex } = result

  const isKnownCountry = country in urlNameToIso

  if (urlIndex === "geo-exposure") {
    if (params.v === "table") {
      store.dispatch(setShowMap(false))
    }

    if (params.i) {
      switch (params.i) {
        case "sent":
          store.dispatch(setMapTabIndex(1))
          break
        case "media":
          store.dispatch(setMapTabIndex(2))
          break
        case "cases":
        default:
          store.dispatch(setMapTabIndex(0))
          break
      }
    }

    store.dispatch(
      setModal({
        title: "Geographical Exposure",
        type: "geoExp",
        data: {},
        show: true,
      }),
    )
    return
  }

  if (!isKnownCountry) {
    window.history.replaceState({}, null, `/worldwide/${urlIndex}${search}`)
  }

  if (!(urlIndex in URL_PARAM_MAP)) {
    window.history.replaceState({}, null, `/${isKnownCountry ? country : "worldwide"}${search}`)
    return
  }

  const index = URL_PARAM_MAP[urlIndex]
  const { fullTitle, description, type } = MODAL_DATA[index]
  const { h: horizon, s: scale, cc: compCountry, ci: compIndex } = params

  if (compCountry && compIndex) {
    const comparison = compCountry === "world" ? undefined : compCountry
    store.dispatch(setComparisonCountry(comparison))
    store.dispatch(
      fetchComparisonData({
        country: comparison,
        index: compIndex,
        horizon,
        force: true,
      }),
    )
  }

  store.dispatch(
    setModal({
      title: fullTitle,
      description,
      type,
      data: {
        primaryIndex: MODAL_DATA[index].index || index,
        ...(compCountry && compIndex && { secondaryIndex: compIndex }),
        horizon: (horizon || "All").toUpperCase(),
        scale: scale || "linear",
      },
      show: true,
    }),
  )
}

const useInitialModal = () => {
  const { queryParams: params } = useQueryParams()
  const isMobile = useSelector(selectIsMobile)

  useEffect(() => {
    if (!isMobile) {
      showInitialModal(params, isMobile)
    }
  }, [params, isMobile])
}

const CountryWatcher = () => {
  const selectedCountry = useSelector(selectSelectedCountry)
  const dispatch = useDispatch()

  const getCountryData = () => {
    if (selectedCountry !== undefined) {
      dispatch(getCountryStories(selectedCountry))
      dispatch(getCountryRelatedTopics(selectedCountry))
      dispatch(getHourlyCountryData(selectedCountry))
      dispatch(getCountryHistoricCases(selectedCountry))
      dispatch(getDailyCountryData({ country: selectedCountry, type: "fake" }))
      dispatch(getDailyCountryData({ country: selectedCountry, type: "sent" }))
      dispatch(getDailyCountryData({ country: selectedCountry, type: "hype" }))
      dispatch(getDailyCountryData({ country: selectedCountry, type: "infodemic" }))
      dispatch(getDailyCountryData({ country: selectedCountry, type: "media" }))
      dispatch(getDailyCountryData({ country: selectedCountry, type: "panic" }))

      const storyInterval = setInterval(
        () => dispatch(getCountryStories(selectedCountry)),
        1000 * 60,
      )

      const indexInterval = setInterval(() => {
        dispatch(getDailyCountryData({ country: selectedCountry, type: "fake" }))
        dispatch(getDailyCountryData({ country: selectedCountry, type: "sent" }))
        dispatch(getDailyCountryData({ country: selectedCountry, type: "hype" }))
        dispatch(getDailyCountryData({ country: selectedCountry, type: "infodemic" }))
        dispatch(getDailyCountryData({ country: selectedCountry, type: "media" }))
        dispatch(getDailyCountryData({ country: selectedCountry, type: "panic" }))
        dispatch(getHourlyCountryData(selectedCountry))
        dispatch(getCountryHistoricCases(selectedCountry))
      }, 1000 * 60 * 5)

      const dataInterval = setInterval(() => {
        dispatch(getCountryRelatedTopics(selectedCountry))
        dispatch(getHourlyCountryData(selectedCountry))
      }, 1000 * 60 * 10)
      dispatch(setCountryIntervalIds([storyInterval, indexInterval, dataInterval]))
    }
  }

  useEffect(() => {
    getCountryData()
    // eslint-disable-next-line
  }, [])

  return null
}

const UTMWatcher = () => {
  const { setUtmParams } = useUtmParams()

  useEffect(() => {
    setUtmParams()
    // eslint-disable-next-line
  }, [])

  return null
}

const App = () => {
  useMobileWatcher()
  useInitialModal()

  const classes = useStyles()
  const dispatch = useDispatch()
  const isMobile = useSelector(selectIsMobile)

  useInterval(
    () => {
      dispatch(getDailyData("fake"))
      dispatch(getDailyData("sent"))
      dispatch(getDailyData("hype"))
      dispatch(getDailyData("infodemic"))
      dispatch(getDailyData("media"))
      dispatch(getDailyData("panic"))
      dispatch(getHourlyData())
      dispatch(getHistoricCases())
    },
    5 * 60 * 1000,
    true,
  )

  useInterval(
    async () => {
      const { v: dataVersion } = await ApiClient.get(`/status.json`)
      const serverVersion = dataVersion.split(".").map(v => parseInt(v))
      const localVersion = EXPECTED_DATA_VERSION.split(".").map(v => parseInt(v))
      if (serverVersion[0] > localVersion[0] || serverVersion[1] > localVersion[1]) {
        window.location.reload()
      }
    },
    5 * 60 * 1000,
    true,
  )

  // Fetch data every 10 minutes
  useInterval(
    () => {
      dispatch(getCountriesData())
      dispatch(getSectorsData())
    },
    1000 * 60 * 10,
    true,
  )

  return (
    <div className={classes.root}>
      <Header />
      <IntroductionModal />
      <DataDownloadModal />
      <CookieConsent />
      <CountryWatcher />
      <UTMWatcher />
      <Grid
        container
        spacing={isMobile ? 0 : 3}
        alignItems="stretch"
        className={classes.mainContainer}
      >
        <Grid container item xs={12} md={3}>
          <CountrySelector />
          <CasesWidget />
          <PanicWidget />
          <HypeWidget />
          <FakeNewsWidget />
          <GlobalSentimentWidget />
          <InfodemicWidget />
          <MediaCoverageWidget />
        </Grid>
        <Grid container item xs={12} md={5} direction="column" wrap="nowrap">
          {/* Maps */}
          <GeographicalExposure className={classes.map} />
          <WidgetContainer
            growToFit
            title="Media Exposure to Coronavirus"
            label="The table shows the media exposure of COVID-19 by entities"
            description="The Media Exposure table shows the changes in media coverage of countries and sectors with the Coronavirus. The ratio for each country or company is the % of all news stories about the Coronavirus that also mention that particular country or company. The higher the ratio value, the more co-mentions of the entity with COVID-19 in the media related to the global content (for countries) or to each sector (for companies)."
          >
            <MediaExposureWidget />
          </WidgetContainer>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={4}
          direction="column"
          wrap="nowrap"
          style={{
            display: "flex",
            flexDirection: isMobile ? "column-reverse" : "column",
          }}
        >
          <Subscribe />
          <NewsFeedWidget />
          <WidgetContainer
            growToFit
            title="Related Topics"
            label="The top themes related to COVID-19"
            description="The Related Topics table shows the main themes mentioned alongside the Coronavirus. Each ratio is the % of all news stories about the Coronavirus that mention the topic."
          >
            <RelatedTopicsWidget />
          </WidgetContainer>
        </Grid>
      </Grid>
      <Footer />
    </div>
  )
}

export default App
