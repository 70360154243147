import React from "react"
// Packages
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import RefreshIcon from "@material-ui/icons/Refresh"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"
// Redux
import { useSelector, useDispatch } from "react-redux"
import { selectMapPosition, setMapPosition, selectCountry } from "../../redux/userInterface.duck"

const useStyles = makeStyles({
  controls: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    position: "absolute",
    bottom: 40,
    right: 0,
    margin: 4,
  },
  button: {
    padding: 6,
    minWidth: 1,
    background: "#141414",
    "&:hover": {
      background: "#404040",
    },
  },
  scaleContainer: {
    position: "absolute",
    bottom: 6,
    right: 0,
    margin: 4,
    height: 32,
    width: 130,
    background: "white",
    borderRadius: 4,
    border: "2px solid #404040",
  },
  scale: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "2px 8px",
  },
  domain: {
    margin: 0,
    color: "#fff",
    textShadow: "0 0 4px black",
    fontSize: "calc(1em - 0.5pt)",
  },
})

const MapControls = ({ colors, domain, scaleDisplay, handleClose }) => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const { zoom, coordinates } = useSelector(selectMapPosition)

  function handleZoomIn() {
    if (zoom >= 4) return
    dispatch(setMapPosition({ coordinates, zoom: zoom * 2 }))
  }

  function handleZoomOut() {
    if (zoom <= 1) return
    dispatch(setMapPosition({ coordinates, zoom: zoom / 2 }))
  }

  function handleZoomReset() {
    dispatch(setMapPosition(undefined))
    dispatch(selectCountry(undefined))
    handleClose()
  }

  return (
    <>
      <div className={classes.controls}>
        <Button
          variant={"outlined"}
          color={"default"}
          size="small"
          className={classes.button}
          onClick={handleZoomReset}
          style={{ marginBottom: 4 }}
        >
          <RefreshIcon />
        </Button>
        <Button
          variant={"outlined"}
          color={"default"}
          size="small"
          className={classes.button}
          onClick={handleZoomIn}
        >
          <AddIcon />
        </Button>
        <Button
          variant={"outlined"}
          color={"default"}
          size="small"
          className={classes.button}
          style={{ marginBottom: 4 }}
          onClick={handleZoomOut}
        >
          <RemoveIcon />
        </Button>
      </div>
      <div
        className={classes.scaleContainer}
        style={{
          backgroundImage: `linear-gradient(90deg, ${colors})`,
        }}
      >
        <div className={classes.scale}>
          <p className={classes.domain}>{scaleDisplay ? scaleDisplay[0] : domain[0]}</p>
          <p className={classes.domain}>{scaleDisplay ? scaleDisplay[1] : domain[1]}</p>
        </div>
      </div>
    </>
  )
}

export default MapControls
