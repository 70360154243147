import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import FullscreenSVG from "../../assets/fullscreen.svg"
import Icon from "../Icon"

const useStyles = makeStyles(theme => ({
  icon: {
    fill: "#808080",
    cursor: "pointer",
    width: 20,
    height: 20,
    transition: theme.transitions.create(["fill"], {
      duration: theme.transitions.duration.leavingScreen,
    }),
    "&:hover": {
      fill: "#FFFFFF",
      transition: theme.transitions.create(["fill"], {
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
}))

const FullscreenIcon = props => {
  const classes = useStyles()
  return <Icon src={FullscreenSVG} className={classes.icon} width={20} square {...props} />
}

export default FullscreenIcon
