import React, { useRef, useState } from "react"
import { Menu, MenuItem } from "@material-ui/core"
import ArrowRight from "@material-ui/icons/ArrowRight"
import { makeStyles } from "@material-ui/core/styles"
import cx from "classnames"

const useStyles = makeStyles({
  arrow: {
    marginLeft: "auto",
    height: 24,
  },
  menuActive: {
    background: "rgba(255, 255, 255, 0.08)",
  },
  menuItemRoot: {
    paddingRight: 0,
  },
  subMenuPaper: {
    border: "solid #7F7F7F 1px",
    borderRadius: 5,
    "&::-webkit-scrollbar": {
      width: 5,
      paddingLeft: 5,
      marginRight: 5,
    },
    "&::-webkit-scrollbar-thumb": {
      maxHeight: 10,
      borderRadius: 2,
      backgroundColor: "#d7d7d7",
    },
  },
  nestedContainer: {
    pointerEvents: "auto",
    maxHeight: 450,
  },
})

const NestedMenuItem = ({
  label,
  children,
  isParentOpen,
  rightIcon = <ArrowRight />,
  ...props
}) => {
  const classes = useStyles()
  const menuItemRef = useRef(null)
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)

  const open = isSubMenuOpen && isParentOpen

  const onClick = () => {
    setIsSubMenuOpen(true)
  }

  const itemClasses = cx({
    [classes.menuActive]: isSubMenuOpen,
  })

  return (
    <>
      <MenuItem
        ref={menuItemRef}
        classes={{
          root: classes.menuItemRoot,
        }}
        className={itemClasses}
        {...props}
        onClick={onClick}
      >
        {label}
        <span className={classes.arrow}>{rightIcon}</span>
      </MenuItem>
      <Menu
        anchorEl={menuItemRef.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={open}
        autoFocus={false}
        disableAutoFocus
        disableEnforceFocus
        onClose={() => {
          setIsSubMenuOpen(false)
        }}
        classes={{
          paper: classes.subMenuPaper,
        }}
        MenuListProps={{
          onMouseLeave: () => {
            setIsSubMenuOpen(false)
          },
        }}
      >
        <div className={classes.nestedContainer}>{children}</div>
      </Menu>
    </>
  )
}

export default NestedMenuItem
