import { createAction, createReducer } from "@reduxjs/toolkit"
import moment from "moment"
import { selectSelectedCountry } from "./userInterface.duck"
import { selectIndexData, selectIsLoading, selectLastUpdated } from "./indexes.duck"
import loadingStates from "common/state/loadingStates"
import {
  selectCurrentCases,
  selectCurrentDeaths,
  selectCurrentRecovered,
  selectHistoricCasesLastUpdate,
  selectHistoricTotals,
  selectIsLoadingHistoricCases,
  selectTotals,
} from "./countriesData.duck"

const initialState = {
  title: undefined,
  description: undefined,
  type: undefined,
  data: {},
  show: false,
}

export const setModal = createAction("SET_MODAL")
export const hideModal = createAction("HIDE_MODAL")
export const updateData = createAction("UPDATE_DATA")

// Line Chart Modal
export const setSecondaryIndex = createAction("SET_SECONDARY_INDEX")
export const setScale = createAction("SET_SCALE")

const reducer = createReducer(initialState, {
  [setModal]: (state, action) => {
    state.title = action.payload.title
    state.description = action.payload.description
    state.type = action.payload.type
    state.data = action.payload.data
    state.show = true
  },
  [updateData]: (state, action) => {
    state.data = {
      ...state.data,
      ...action.payload,
    }
  },
  [setSecondaryIndex]: (state, action) => {
    state.data.secondaryIndex = action.payload
  },
  [setScale]: (state, action) => {
    state.data.scale = action.payload
  },
  [hideModal]: () => {
    return initialState
  },
})

export const selectModal = state => state.modal
export const selectShowModal = state => state.modal.show
export const selectModalTitle = state => state.modal.title
export const selectModalDescription = state => state.modal.description
export const selectModalData = state => state.modal.data
export const selectModalType = state => state.modal.type

const selectHourlyDataLoading = state => {
  const modal = selectModalData(state)

  if (modal === undefined) {
    return true
  }

  const { primaryIndex } = modal

  if (["total_cases", "total_deaths", "total_recovered"].includes(primaryIndex)) {
    return false
  }
  return selectIsLoading(state)
}

const selectDailyDataLoading = state => {
  const country = selectSelectedCountry(state)
  const modal = selectModalData(state)
  const data = selectIndexData(state)

  if (modal === undefined) {
    return []
  }

  const { primaryIndex } = modal

  if (["total_cases", "total_deaths", "total_recovered"].includes(primaryIndex)) {
    return selectIsLoadingHistoricCases(state)
  }

  const countryKey = country !== undefined ? "country" : "global"
  return data[countryKey].dailyData[primaryIndex].loading === loadingStates.INIT
}

export const selectChartLoading = state => {
  const { horizon } = selectModalData(state)

  if (["1D", "1W"].includes(horizon)) {
    return selectHourlyDataLoading(state)
  }

  return selectDailyDataLoading(state) || selectHourlyDataLoading(state)
}

export const selectChartLastUpdated = state => {
  const country = selectSelectedCountry(state)
  const modal = selectModalData(state)
  const data = selectIndexData(state)

  if (modal === undefined) {
    return []
  }

  const { primaryIndex, horizon } = modal

  if (["total_cases", "total_deaths", "total_recovered"].includes(primaryIndex)) {
    if (["1D", "1W"].includes(horizon)) {
      return undefined
    }
    return selectHistoricCasesLastUpdate(state)
  }

  if (["1D", "1W"].includes(horizon)) {
    return selectLastUpdated(state)
  }

  const countryKey = country !== undefined ? "country" : "global"
  return data[countryKey].dailyData[primaryIndex].lastUpdated
}

const selectChartHourlyData = state => {
  const country = selectSelectedCountry(state)
  const modal = selectModalData(state)
  const data = selectIndexData(state)

  if (modal === undefined) {
    return []
  }

  const { primaryIndex } = modal

  if (["total_cases", "total_deaths", "total_recovered"].includes(primaryIndex)) {
    return []
  }

  const countryKey = country !== undefined ? "country" : "global"
  return data[countryKey].hourlyData.data[primaryIndex]
}

const selectChartDailyData = state => {
  const country = selectSelectedCountry(state)
  const modal = selectModalData(state)
  const data = selectIndexData(state)

  if (modal === undefined) {
    return []
  }

  const { primaryIndex } = modal

  if (["total_cases", "total_deaths", "total_recovered"].includes(primaryIndex)) {
    const totals = selectHistoricTotals(state)
    return totals.map(x => ({ ts: x.date, value: x[primaryIndex] }))
  }

  const countryKey = country !== undefined ? "country" : "global"
  return data[countryKey].dailyData[primaryIndex].data
}

export const selectLatestChartValue = state => {
  const ts = moment.utc().format("MMM DD, HH:00")
  if (selectChartLoading(state)) {
    return {
      ts,
      value: undefined,
    }
  }

  const { primaryIndex } = selectModalData(state)

  switch (primaryIndex) {
    case "total_cases":
      return {
        ts,
        value: selectCurrentCases(state),
      }
    case "total_deaths":
      return {
        ts,
        value: selectCurrentDeaths(state),
      }
    case "total_recovered":
      return {
        ts,
        value: selectCurrentRecovered(state),
      }
    default:
      const data = selectChartHourlyData(state)
      return data[data.length - 1]
  }
}

export const selectChartChange = state => {
  if (selectChartLoading(state)) {
    return 0
  }

  const { primaryIndex } = selectModalData(state)

  switch (primaryIndex) {
    case "total_cases":
      return selectTotals(state).casesChange
    case "total_deaths":
      return selectTotals(state).deathsChange
    case "total_recovered":
      return selectTotals(state).recoveredChange
    default:
      const data = selectChartHourlyData(state)
      return data[data.length - 1].value - data[data.length - 25].value
  }
}

export const selectChartData = state => {
  if (selectChartLoading(state)) {
    return []
  }

  const { horizon } = selectModalData(state)

  if (["1D", "1W"].includes(horizon)) {
    return selectChartHourlyData(state)
  }

  const dailyData = selectChartDailyData(state)
  const latestValue = selectLatestChartValue(state)
  return [...dailyData.slice(0, dailyData.length - 1), latestValue]
}

export default reducer
