import { createSelector } from "@reduxjs/toolkit"
import createFetchReducer from "common/state/createFetch"
import { selectMediaExposureTab } from "./userInterface.duck"
import { selectCountriesByMediaExp, selectIsLoadingCountryData } from "./countriesData.duck"
import APIClient from "../APIClient"

const slice = createFetchReducer(
  {
    name: "SECTOR_DATA",
    url: () => "/sectors.json",
    path: "sectorsData",
  },
  APIClient,
)

export const getSectorsData = slice.actions.fetch

const { reducer } = slice

const selectSelectedCountry = state => state.ui.selectedCountry

export const selectCurrentSector = createSelector(
  [
    slice.selectors.data,
    selectMediaExposureTab,
    selectCountriesByMediaExp,
    selectIsLoadingCountryData,
  ],
  (data, index, countryData, countryLoading) => {
    if (index === 0) {
      return {
        label: "Country",
        loading: countryLoading,
        data: countryData,
      }
    }
    return data[index - 1]
  },
)

export const selectSectors = createSelector(
  [selectCurrentSector, selectSelectedCountry],
  (data, selectedCountry) => {
    return {
      label: data.name,
      description: data.description,
      data: data.entities
        ? data.entities
            .filter(
              ({ country_code: countryCode }) =>
                (selectedCountry ? countryCode === selectedCountry : true) || !countryCode,
            )
            .map(({ name: entityName, media }) => ({
              columns: {
                entity: entityName,
                media: media.c || 0,
                mediaChange: media.c && media.p ? media.c - media.p : 0,
              },
            }))
        : [],
      ...data,
    }
  },
)

export const selectSectorHeaders = createSelector([slice.selectors.data], sectorData => {
  return [
    {
      label: "Country",
    },
    ...sectorData.map(({ name, description }) => ({
      label: name,
      description,
    })),
  ]
})

export default reducer
