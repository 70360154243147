import React from "react"
// Components
import FilterMenu from "./FilterMenu"
// Packages
import PropTypes from "prop-types"
import { useTheme } from "@material-ui/core/styles"
import { TableCell } from "@material-ui/core"
import { useSticky } from "react-table-sticky"
import { useTable, useBlockLayout, useColumnOrder, useSortBy } from "react-table"
// Styles
import TableStyles from "./ReactTable.styles"
import TableSortLabel from "@material-ui/core/TableSortLabel"
// Redux
import { useDispatch } from "react-redux"
import { selectCountry, setMapPosition } from "../../redux/userInterface.duck"
// Utils
import numericDisplay from "../../utils/displayPercentage"

// Create a default prop getter
const defaultPropGetter = () => ({})

const Table = ({
  columns,
  data,
  height,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 40,
      width: 80,
      maxWidth: 400,
    }),
    [],
  )

  const handleRowClick = data => {
    dispatch(selectCountry(data.original.isocode))
    dispatch(setMapPosition(undefined))
  }

  const { allColumns, getTableBodyProps, getTableProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: [
          {
            id: "cases",
            desc: true,
          },
        ],
        hiddenColumns: ["casesChange", "recoveredChange", "deathsChange"],
      },
    },
    useBlockLayout,
    useSticky,
    useColumnOrder,
    useSortBy,
  )

  const tableHeader = (
    <div className="header">
      {headerGroups.map(headerGroup => (
        <div {...headerGroup.getHeaderGroupProps()} className="row header-group tr">
          {headerGroup.headers.map((column, index) => (
            <TableCell
              {...column.getHeaderProps([
                {
                  className: column.className,
                  style: column.style,
                },
                getColumnProps(column),
                getHeaderProps(column),
                column.getSortByToggleProps(),
              ])}
              className="cell header th"
            >
              <div>
                {column.showFilterMenu ? (
                  <FilterMenu allColumns={allColumns} />
                ) : column.sort !== false ? (
                  <TableSortLabel
                    active={column.isSorted}
                    direction={column.isSortedDesc ? "desc" : "asc"}
                    children={column.render("Header")}
                    style={{
                      color: column.isSorted ? "white" : "inherit",
                      display: column.sortIconAfter ? "flex" : "",
                      flexDirection: column.sortIconAfter ? "row" : "row-reverse",
                    }}
                    classes={{
                      icon: column.isSorted ? "activeSortIcon" : "inactiveSortIcon",
                      root: "sortLabel",
                    }}
                  />
                ) : (
                  column.render("Header")
                )}
              </div>
            </TableCell>
          ))}
        </div>
      ))}
    </div>
  )

  const tableBody = (
    <div {...getTableBodyProps()} className="body">
      {rows.map((row, index) => {
        prepareRow(row)
        const { isSelectedCountry } = row.original
        return (
          <div
            {...row.getRowProps([
              {
                style: {
                  ...row.style,
                  backgroundColor: isSelectedCountry ? "#3c3c3c" : "",
                },
              },
              getRowProps(row),
            ])}
            className="tr"
            onClick={() => handleRowClick(row)}
          >
            {row.cells.map((cell, cellIndex) => {
              const calcColor = (colorChange, value) => {
                if (colorChange && value < 0) {
                  return "#CF2D00"
                }
                if ((colorChange && value > 0) || (cellIndex === 1 && isSelectedCountry)) {
                  return theme.palette.secondary.main
                }
                return "#F7F7F7"
              }
              return (
                <div
                  {...cell.getCellProps([
                    {
                      className: cell.column.className,
                      style: {
                        ...cell.column.style,
                        color: calcColor(cell.column.colorChange, cell.value),
                      },
                    },
                    getColumnProps(cell.column),
                    getCellProps(cell),
                  ])}
                  className="td"
                >
                  {cellIndex === 0
                    ? index + 1
                    : cell.value === null
                    ? "-"
                    : numericDisplay(
                        cell.value,
                        cell.column.percentage,
                        cell.column.sign,
                        cell.column.decimalPlaces,
                      )}
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )

  return (
    <TableStyles>
      <div
        {...getTableProps()}
        className="table sticky"
        style={{
          width: "100%",
          height: height < 400 ? height : "calc(85vh - 80px)",
          marginTop: height < 400 ? 0 : 10,
        }}
      >
        {tableHeader}
        {tableBody}
      </div>
    </TableStyles>
  )
}

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  getCellProps: PropTypes.func,
  getColumnProps: PropTypes.func,
  getHeaderProps: PropTypes.func,
  getRowProps: PropTypes.func,
}

export default Table
