import { acceptCookies, seenIntro, dismissElection } from "../userInterface.duck"

const localStorageMiddleware = store => next => action => {
  next(action)

  if (action.type === acceptCookies.toString()) {
    localStorage.setItem("cookiesAccepted", "true")
  }

  if (action.type === dismissElection.toString()) {
    localStorage.setItem("electionDismissed", "true")
  }

  if (action.type === seenIntro.toString()) {
    localStorage.setItem("seenIntro", "true")
  }
}

export default localStorageMiddleware
