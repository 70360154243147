import React from "react"
import { Typography } from "@material-ui/core"
import { TwitterShareButton } from "react-share"
import Icon from "../Icon"
import TwitterLogo from "../../assets/logos/twitter.svg"
import { PAGE_TITLE } from "../../constants"

const TwitterShare = ({ hideText, style, ...props }) => {
  return (
    <TwitterShareButton
      url="https://coronavirus.ravenpack.com/?utm_campaign=covid-dashboard&utm_medium=referral&utm_source=twitter&utm_content=share&utm_term="
      title={PAGE_TITLE}
      style={{ display: "flex", ...style }}
      {...props}
    >
      <Icon src={TwitterLogo} />
      {!hideText && <Typography style={{ marginLeft: 10 }}>Share on Twitter</Typography>}
    </TwitterShareButton>
  )
}

export default TwitterShare
