import React from "react"
import { useDispatch, useSelector } from "react-redux"
import useInterval from "../../hooks/useInterval"
import { getStories, selectIsLoadingStories, selectStoriesByOrder } from "../../redux/stories.duck"
import NewsFeed from "./NewsFeed"
import WidgetContainer from "../WidgetContainer"
import { selectIsMobile } from "../../redux/userInterface.duck"

const NewsFeedWidget = () => {
  const dispatch = useDispatch()
  useInterval(
    () => {
      dispatch(getStories())
    },
    60 * 1000,
    true,
  )

  const isMobile = useSelector(selectIsMobile)
  const stories = useSelector(selectStoriesByOrder)
  const isLoading = useSelector(selectIsLoadingStories)

  return (
    <WidgetContainer
      title="News Flashes"
      description="The latest news about COVID-19 across 19,000 sources of content."
    >
      <NewsFeed
        stories={stories}
        loading={isLoading}
        height={isMobile ? 350 : 300}
        emptyMessage={country => `No recent headlines available for ${country}`}
      />
    </WidgetContainer>
  )
}

export default NewsFeedWidget
