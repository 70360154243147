import React from "react"
import PropTypes from "proptypes"
import { Typography } from "@material-ui/core"
import { FacebookShareButton } from "react-share"
import FacebookLogo from "../../assets/logos/facebook.svg"
import { PAGE_TITLE } from "../../constants"
import Icon from "../Icon"

const FacebookShare = ({ hideText, style, ...props }) => {
  return (
    <FacebookShareButton
      url="https://coronavirus.ravenpack.com/?utm_campaign=covid-dashboard&utm_medium=referral&utm_source=facebook&utm_content=share&utm_term="
      quote={PAGE_TITLE}
      style={{ display: "flex", ...style }}
      {...props}
    >
      <Icon src={FacebookLogo} />
      {!hideText && <Typography style={{ marginLeft: 10 }}>Share on Facebook</Typography>}
    </FacebookShareButton>
  )
}

FacebookShare.propTypes = {
  hideText: PropTypes.bool,
}

FacebookShare.defaultProps = {
  hideText: false,
}

export default FacebookShare
