import WordSuggester from "common/utils/WordSuggester"

class CountrySuggester extends WordSuggester {
  updateWordList(countryData) {
    this.wordList = countryData.map(x => x.country.toLowerCase())
    this.countryData = countryData
  }

  check(word) {
    const result = super.check(word)
    return this.countryData.filter(x => x.country.toLowerCase() === result)[0]
  }
}

export default new CountrySuggester()
