import { createMuiTheme } from "@material-ui/core/styles"

const theme = createMuiTheme({
  palette: {
    type: "light",
    common: { black: "#000", white: "#fff" },
    background: { paper: "#FFFFFF", default: "#E1E1E1", tooltip: "#FFFFFF", outline: "##CCCCCC" },

    primary: {
      light: "#FAC937",
      main: "#EBB71E",
      dark: "#DBAA18",
      darker: "#CB9D16",
      contrastText: "#fff",
    },
    secondary: {
      light: "rgba(66, 189, 158, 1)",
      main: "rgba(3, 166, 125, 1)",
      dark: "rgba(0, 123, 93, 1)",
      contrastText: "#fff",
    },
    text: {
      primary: "#404040",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
  },
  typography: {
    fontFamily: ["Lato", "sans-serif"].join(","),
  },
})

export default theme
