import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectIsLoadingCountryData, selectTotals } from "../redux/countriesData.duck"
import CasesDisplay from "./CasesDisplay"
import WidgetContainer from "./WidgetContainer"
import { setModal } from "../redux/modal.duck"
import { pathToRegexp } from "path-to-regexp"

const WIDGET_TITLE = "COVID-19 Cases"
const WIDGET_DESCRIPTION =
  "COVID-19 Cases includes the total number of confirmed cases, the total number of deaths, and the total number of recovered patients worldwide."
const WIDGET_LABEL = "Changes from the previous day"

const CasesWidget = () => {
  const {
    total_cases: totalCases,
    total_deaths: totalDeaths,
    total_recovered: totalRecovered,
    casesChange,
    deathsChange,
    recoveredChange,
  } = useSelector(selectTotals)

  const isLoading = useSelector(selectIsLoadingCountryData)

  const dispatch = useDispatch()
  const showModal = () => {
    const { pathname: path, search } = window.location
    const regexp = pathToRegexp("/:country(.*)")
    const result = regexp.exec(path)

    const country = result !== null ? result[1] : undefined
    const urlCountry = country || "worldwide"

    window.history.replaceState({}, null, `/${urlCountry}/cases${search}`)

    dispatch(
      setModal({
        title: WIDGET_TITLE,
        description: WIDGET_DESCRIPTION,
        type: "line",
        data: {
          value: totalCases,
          change: casesChange,
          primaryIndex: "total_cases",
          horizon: "ALL",
          scale: "linear",
        },
      }),
    )
  }

  return (
    <WidgetContainer
      title={WIDGET_TITLE}
      description={WIDGET_DESCRIPTION}
      label={WIDGET_LABEL}
      openModal={showModal}
    >
      <CasesDisplay
        loading={isLoading}
        value={totalCases}
        change={casesChange}
        tooltip="Total number of confirmed cases globally with COVID-19."
        subTitle="Total Cases"
      />
      <CasesDisplay
        loading={isLoading}
        value={totalRecovered}
        change={recoveredChange}
        tooltip="Total number of patients recovered globally from COVID-19."
        subTitle="Total Recovered"
        color="#03A67D"
      />
      <CasesDisplay
        loading={isLoading}
        value={totalDeaths}
        change={deathsChange}
        tooltip="Total number of confirmed deaths globally from COVID-19."
        subTitle="Total Deaths"
        color="#CF2D00"
      />
    </WidgetContainer>
  )
}

export default CasesWidget
